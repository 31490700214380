import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
import {
	Alert,
	Button,
	Col,
	Collapse,
	DatePicker,
	Input,
	Modal,
	Popover,
	Row,
	Select,
	Spin,
	message,
} from 'antd';
import dayjs from 'dayjs';
import { EditorState } from 'draft-js';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import escapeRegExp from 'lodash/escapeRegExp';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import RichTextEditor from 'react-rte';
import ReactSelect from 'react-select';
import { configMode } from 'src/_shared/api/';
import { getTotalUnclaimedAccounts } from 'src/_shared/api/graphql/custom/message-center/';
import {
	GetSubCompany,
	querySubCompanyByCompanyIdIndex,
} from 'src/_shared/api/graphql/custom/sub-company/';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import PlacesAutocomplete from 'src/_shared/components/location/PlacesAutocomplete.jsx';
import { Spinner, WebIcon } from 'src/_shared/index.js';
import spiderman from '../_shared/assets/spiderman.png';
import {
	downloadFromS3Signed,
	getAddressFromLocation,
	lambda,
	logout,
	ml,
	parse,
	searchOpenSearchNetwork,
	uploadImageToS3FromTinyMCE,
} from '../_shared/services/utils.js';
import { COLORS } from '../_shared/styles/colors';
import { messageCenterKeys } from './messagecenterKeys.js';
import {
	LabelClass,
	LabelStyles,
	PopoverStyle,
	changeDirection,
	iconContainer,
	scheduleEmailFilterContainer,
	tableContainer,
} from './messagecenterStyles.js';

const { Option } = Select;
const { Panel } = Collapse;

// Functions for ReactSelect
function NullComponent() {
	return null;
}

function IconComponent() {
	return (
		<div className={iconContainer}>
			<WebIcon color={COLORS.darkGray} size={14} name="sort-down" />
		</div>
	);
}

class MessageCenterComponent extends Component {
	constructor(props) {
		super(props);
		const location = get(props, 'currentUser.location')
			? JSON.parse(get(props, 'currentUser.location'))
			: {};
		const address = getAddressFromLocation(location);

		this.state = {
			address,
			jobsInputValue: '',
			isVerified: false,
			isVerifying: false,
			messageRaw: RichTextEditor.createEmptyValue(),
			editorState: EditorState.createEmpty(),
			theme: parse(get(props, 'currentUser.company.theme', '{}')),
			campaigns: get(props, 'campaigns') ? get(props, 'campaigns') : [],
			allUsers: [],
			filteredDepartments: [],
			filteredUserGroups: [],
			selectedJobs: [],
			filteredBrands: [],
			allJobs: [],
			filteredUsers: [],
			usersCount: 0,
			subject: '',
			message: '',
			pushmessage: '',
			lengthError: false,
			loaded: false,
			jobId: '',
			jobTitle: '',
			jobLocation: '',
			loaded: false,
			loadedJobs: false,
			jobDefaultValue: 'None',
			buttonState: '',
			isTestEmailDone: '',
			isEmailAllUsers: '',
			isSubjectEmpty: false,
			isMessageEmpty: false,
			filteredSubCompanies: [],
			ml_SendAMessage: '',
			ml_SelectEmployees: '',
			ml_WhoWill: '',
			ml_InGroup: '',
			ml_AndInDept: '',
			ml_All: '',
			ml_None: '',
			ml_EmpSelected: '',
			ml_Job: '',
			ml_Optional: '',
			ml_DoYouWant: '',
			ml_MessageCenter_SelectJob: '',
			ml_Message: '',
			ml_TheMessage: '',
			ml_Subject: '',
			ml_SendMe: '',
			ml_MessageCenter_Email: '',
			ml_Employees: '',
			ml_Location: 'Location',
			ml_withIn: 'within',
			ml_of: 'of',
			ml_Location_Note:
				'Note: Location filter will only send messages to users with a set location.',
			ml_miles: 'Miles',
			ml_city: 'City',
			ml_state: 'State',
			filteredDistance: '0',
			ml_AllLocations: 'All Locations',
			isEmployee: true,
			isCandidate: false,
			dateRangeFilterValue: [dayjs().startOf('month'), null],
			filteredCandidateData: [],
			filteredCount: 0,
			totalReferralsData: [],
			totalReferralsCount: 0,
			countLoaded: true,
			isEmailAllCandidates: '',
			ml_MessageCenter_SelectBrand: '',
			subCompanies: [],
			subCompany: {},
			sendPush: false,
			isJobsLoading: true,
			allEmp: false,
			visible: false,
			allUnclaimedAccounts: [],
			unclaimedCount: 0,
			allEmpUnclaimed: false,
			company: get(props, 'currentUser.company'),
			searchQuery: '',
			location,
			totalPages: 0,
			selectedCampaign: [],
			campaignJobs: [],
			schMsgValue: 1,
		};
	}

	async componentDidMount() {
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		this.getSubCompaniesByCompany();
		this.getAllUnclaimedAccounts();

		if (this.props.allMultiLingualData.length > 0) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				this.props.currentUser.languageCode === null
					? 'US'
					: this.props.currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) =>
					item.languageCode === languageCode && item.module === 'Message Center'
			);
			const sendAMessage = filteredData.find(
				(item) => item.key === messageCenterKeys.SendAMessage
			);
			const selectEmployee = filteredData.find(
				(item) => item.key === messageCenterKeys.SelectEmployees
			);
			const whoWill = filteredData.find(
				(item) => item.key === messageCenterKeys.WhoWill
			);
			const inGroup = filteredData.find(
				(item) => item.key === messageCenterKeys.InGroup
			);
			const andInDept = filteredData.find(
				(item) => item.key === messageCenterKeys.AndInDept
			);
			const all = filteredData.find(
				(item) => item.key === messageCenterKeys.All
			);
			const none = filteredData.find(
				(item) => item.key === messageCenterKeys.None
			);
			const empSelected = filteredData.find(
				(item) => item.key === messageCenterKeys.EmpSelected
			);
			const job = filteredData.find(
				(item) => item.key === messageCenterKeys.Job
			);
			const optional = filteredData.find(
				(item) => item.key === messageCenterKeys.Optional
			);
			const doYouWant = filteredData.find(
				(item) => item.key === messageCenterKeys.DoYouWant
			);
			const selectJob = filteredData.find(
				(item) => item.key === messageCenterKeys.SelectJob
			);
			const message = filteredData.find(
				(item) => item.key === messageCenterKeys.Message
			);
			const theMessage = filteredData.find(
				(item) => item.key === messageCenterKeys.TheMessage
			);
			const subject = filteredData.find(
				(item) => item.key === messageCenterKeys.Subject
			);
			const sendMe = filteredData.find(
				(item) => item.key === messageCenterKeys.SendMe
			);
			const email = filteredData.find(
				(item) => item.key === messageCenterKeys.Email
			);
			const employee = filteredData.find(
				(item) => item.key === messageCenterKeys.Employees
			);
			const SelectBrand = filteredData.find(
				(item) => item.key === messageCenterKeys.SelectBrand
			);
			this.setState({
				ml_SendAMessage: get(sendAMessage, 'text', 'Send A Message'),
				ml_SelectEmployees: get(selectEmployee, 'text', 'Select Employees'),
				ml_WhoWill: get(whoWill, 'text', 'Who will this message go to?'),
				ml_InGroup: get(inGroup, 'text', 'In Group'),
				ml_AndInDept: get(andInDept, 'text', 'In Department'),
				ml_All: get(all, 'text', 'All'),
				ml_None: get(none, 'text', 'None'),
				ml_EmpSelected: get(empSelected, 'text', 'Employees Selected'),
				ml_Job: get(job, 'text', 'Job'),
				ml_Optional: get(optional, 'text', '(optional)'),
				ml_DoYouWant: get(
					doYouWant,
					'text',
					'Select jobs to be included in the email. Job title and bonus amount will be displayed.'
				),
				ml_MessageCenter_SelectJob: get(selectJob, 'text', 'Select Job(s)'),
				ml_Message: get(message, 'text', 'Message'),
				ml_TheMessage: get(
					theMessage,
					'text',
					'The message will include the default company header and logo.'
				),
				ml_Subject: get(subject, 'text', 'Subject'),
				ml_SendMe: get(sendMe, 'text', 'Send Me A Test Email'),
				ml_MessageCenter_Email: get(email, 'text', 'Email'),
				ml_Employees: get(employee, 'text', 'Employees'),
				ml_MessageCenter_SelectBrand: get(SelectBrand, 'text', 'Logo'),
			});
		} else {
			this.setState({
				ml_SendAMessage: 'Send A Message',
				ml_SelectEmployees: 'Select Employees',
				ml_WhoWill: 'Who will this message go to?',
				ml_InGroup: 'In Group',
				ml_AndInDept: 'In Department',
				ml_All: 'All',
				ml_None: 'None',
				ml_EmpSelected: 'Employees Selected',
				ml_Job: 'Job',
				ml_Optional: '(optional)',
				ml_DoYouWant:
					'Select jobs to be included in the email. Job title and bonus amount will be displayed.',
				ml_MessageCenter_SelectJob: 'Select Job(s)',
				ml_Message: 'Message',
				ml_TheMessage:
					'The message will include the default company header and logo.',
				ml_Subject: 'Subject',
				ml_SendMe: 'Send Me A Test Email',
				ml_MessageCenter_Email: 'Email',
				ml_Employees: 'Employees',
				ml_MessageCenter_SelectBrand: 'Logo',
			});
		}

		await this.setQueryToStateJobs(get(this.state, 'searchQuery', ''), 0);

		this.doSubmit('cache');
	}

	async componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.currentUser.languageCode !==
				this.props.currentUser.languageCode &&
			this.props.allMultiLingualData.length > 0
		) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				this.props.currentUser.languageCode === null
					? 'US'
					: this.props.currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const sendAMessage = filteredData.find(
				(item) => item.key === messageCenterKeys.SendAMessage
			);
			const selectEmployee = filteredData.find(
				(item) => item.key === messageCenterKeys.SelectEmployees
			);
			const whoWill = filteredData.find(
				(item) => item.key === messageCenterKeys.WhoWill
			);
			const inGroup = filteredData.find(
				(item) => item.key === messageCenterKeys.InGroup
			);
			const andInDept = filteredData.find(
				(item) => item.key === messageCenterKeys.AndInDept
			);
			const all = filteredData.find(
				(item) => item.key === messageCenterKeys.All
			);
			const none = filteredData.find(
				(item) => item.key === messageCenterKeys.None
			);
			const empSelected = filteredData.find(
				(item) => item.key === messageCenterKeys.EmpSelected
			);
			const job = filteredData.find(
				(item) => item.key === messageCenterKeys.Job
			);
			const optional = filteredData.find(
				(item) => item.key === messageCenterKeys.Optional
			);
			const doYouWant = filteredData.find(
				(item) => item.key === messageCenterKeys.DoYouWant
			);
			const selectJob = filteredData.find(
				(item) => item.key === messageCenterKeys.SelectJob
			);
			const message = filteredData.find(
				(item) => item.key === messageCenterKeys.Message
			);
			const theMessage = filteredData.find(
				(item) => item.key === messageCenterKeys.TheMessage
			);
			const subject = filteredData.find(
				(item) => item.key === messageCenterKeys.Subject
			);
			const sendMe = filteredData.find(
				(item) => item.key === messageCenterKeys.SendMe
			);
			const email = filteredData.find(
				(item) => item.key === messageCenterKeys.Email
			);
			const employee = filteredData.find(
				(item) => item.key === messageCenterKeys.Employees
			);
			const SelectBrand = filteredData.find(
				(item) => item.key === messageCenterKeys.SelectBrand
			);
			this.setState({
				ml_SendAMessage: get(sendAMessage, 'text', 'Send A Message'),
				ml_SelectEmployees: get(selectEmployee, 'text', 'Select Employees'),
				ml_WhoWill: get(whoWill, 'text', 'Who will this message go to?'),
				ml_InGroup: get(inGroup, 'text', 'In Group'),
				ml_AndInDept: get(andInDept, 'text', 'In Department'),
				ml_All: get(all, 'text', 'All'),
				ml_None: get(none, 'text', 'None'),
				ml_EmpSelected: get(empSelected, 'text', 'Employees Selected'),
				ml_Job: get(job, 'text', 'Job'),
				ml_Optional: get(optional, 'text', '(optional)'),
				ml_DoYouWant: get(
					doYouWant,
					'text',
					'Select jobs to be included in the email. Job title and bonus amount will be displayed.'
				),
				ml_MessageCenter_SelectJob: get(selectJob, 'text', 'Select Job(s)'),
				ml_Message: get(message, 'text', 'Message'),
				ml_TheMessage: get(
					theMessage,
					'text',
					'The message will include the default company header and logo.'
				),
				ml_Subject: get(subject, 'text', 'Subject'),
				ml_SendMe: get(sendMe, 'text', 'Send Me A Test Email'),
				ml_MessageCenter_Email: get(email, 'text', 'Email'),
				ml_Employees: get(employee, 'text', 'Employees'),
				ml_MessageCenter_SelectBrand: get(SelectBrand, 'text', 'Logo'),
			});
		}

		if (
			prevState.totalPages != this.state.totalPages &&
			this.state.totalPages != 1
		) {
			let totalUsersResult = [];
			const result = await this.searchAllUsers();
			for (const item of result) {
				totalUsersResult = [...totalUsersResult, ...item.data];
			}

			this.setState({
				allUsers: totalUsersResult,
			});
		}

		if (prevState.jobsInputValue != this.state.jobsInputValue) {
			await this.setQueryToStateJobs(get(this.state, 'jobsInputValue', ''), 0);
		}

		if (
			prevState.selectedCampaign.length != this.state.selectedCampaign.length
		) {
			const selectedCampLists = [...this.state.selectedCampaign];
			let jobs = [];
			for (const campItem of selectedCampLists) {
				for (let i = 0; i < this.state.campaigns.length; i++) {
					if (this.state.campaigns[i].id == campItem.value) {
						jobs = [...jobs, ...JSON.parse(this.state.campaigns[i].jobIds)];
						break;
					}
				}
			}

			for (const [index, item] of jobs.entries()) {
				jobs[index].value = item.id;
				jobs[index].label = item.title;
				delete jobs[index].id;
				delete jobs[index].title;
			}

			this.setState({ campaignJobs: jobs });
		}
	}

	onChangeRTE = (value) => {
		this.setState({
			messageRaw: value,
			message: value.toString('html'),
		});
		if (this.state.message === '<p><br></p>') {
			this.setState({ message: null });
		}
	};

	onMsgSchedule = (e) => {
		this.setState({
			schMsgValue: e.target.value,
		});
		console.log(e);
	};

	async getAllUnclaimedAccounts(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const companyId = get(currentUser, 'companyId', null);
			if (companyId !== null) {
				const { data } = await client.query({
					query: gql(getTotalUnclaimedAccounts),
					variables: {
						companyId,
					},
					fetchPolicy: policy,
				});
				let result = get(data, 'getTotalUnclaimedAccounts.finalResult');
				result = parse(result);
				const count = result.TotalUserCount;
				this.setState({
					allUnclaimedAccounts: result.totalUsr,
					unclaimedCount: count,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	getCSVData = () => {
		const tableData = get(this.state, 'filteredCandidateData', []);
		const newData = [];
		if (tableData.length > 0) {
			for (const item of tableData) {
				const newItem = {
					firstName: get(item, ['contact', 'firstName'], ''),
					lastName: get(item, ['contact', 'lastName'], ''),
					candidateEmail: get(item, ['contact', 'emailAddress'], ''),
				};
				newData.push(newItem);
			}
		}

		return newData;
	};

	getJobsInfosByIds = () => {
		return new Promise(async (resolve) => {
			const { allJobs, selectedJobs, campaignJobs } = this.state;

			const jobData = [];
			for (const job of selectedJobs) {
				const jobarr = allJobs
					.filter((item) => item.id === job.value)
					.map((item) => {
						let locationJson = {};
						if (
							item.job.hasOwnProperty('location') &&
							item.job.location !== null
						) {
							locationJson = parse(item.job.location);
						}

						const location =
							item.hasOwnProperty('location') && item.job.location !== null
								? locationJson.isRemote
									? 'Remote'
									: locationJson.city !== null && locationJson.state !== null
										? locationJson.city + ', ' + locationJson.state
										: ''
								: '';

						const iniisa = {
							jobId: item.id,
							jobTitle: item.title,
							jobLocation: location,
						};
						jobData.push(iniisa);
						return iniisa;
					});
			}

			for (const item of campaignJobs) {
				const jobObject = {
					jobId: item.value,
					jobTitle: item.label,
					jobLocation: '',
				};
				jobData.push(jobObject);
			}

			resolve(jobData);
		});
	};

	getSubCompaniesByCompany = async (
		policy = 'network-only',
		nextToken = null,
		subCompanies = []
	) => {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(querySubCompanyByCompanyIdIndex),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const SubCompanyByCompanies = [
				...data.querySubCompanyByCompanyIdIndex.items,
			];
			const token = get(
				data,
				'querySubCompanyByCompanyIdIndex.nextToken',
				null
			);
			subCompanies = [...subCompanies, ...SubCompanyByCompanies];
			this.setState({
				subCompanies,
				loadedJobs: false,
			});

			if (token) {
				this.getSubCompaniesByCompany(policy, token, subCompanies);
			} else {
				this.setState({ loadedJobs: true });
			}
		} catch (error) {
			console.log(error);
		}
	};

	getSubCompanyDataById = async (
		id = '3342aa7c-18e1-4b58-8cd8-57e295dfb282',
		policy = 'network-only'
	) => {
		const { client } = this.props;
		try {
			const { data } = await client.query({
				query: gql(GetSubCompany),
				variables: {
					id,
				},
				fetchPolicy: policy,
			});
			const result = get(data, 'getSubCompany', null);
			this.setState({
				subCompany: result,
			});
		} catch (error) {
			console.log(error);
		}
	};

	getUserCSVData = () => {
		const tableData = get(this.state, 'filteredUsers', []);
		const newData = [];
		if (tableData.length > 0) {
			for (const item of tableData) {
				const newItem = {
					firstName: get(item, 'firstName', ''),
					lastName: get(item, 'lastName', ''),
					UserEmail: get(item, 'emailAddress', ''),
					status: get(item, 'active', false),
				};
				newData.push(newItem);
			}
		}

		return newData;
	};

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setFilteredData = () => {
		const { filteredDepartments } = this.state;
		let updated = '';
		if (filteredDepartments.length > 0) {
			updated = [...filteredDepartments];
		}
	};

	setQueryToStateJobs = async (searchQuery = '', timeout = 500) => {
		const { company, filteredStatus = 'open' } = this.state;
		this.setState({ searchQuery });
		clearTimeout(this.timer);
		this.setState({ loading: true });
		this.timer = setTimeout(async () => {
			const parameters = {
				query: searchQuery,
				size: 300,
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					companies: get(company, 'id'),
					status: filteredStatus,
				},
			};

			const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
			const newJobs = get(response, 'data', []);
			const currentJobs = get(this.state, 'allJobs', []);
			for (const ajob of newJobs) {
				const findExisting = currentJobs.find((f) => f.id == ajob.id);
				if (!findExisting) {
					currentJobs.push(ajob);
				}
			}

			if (get(response, 'query') === get(this.state, 'searchQuery')) {
				this.setState({
					loaded: true,
					allJobs: currentJobs,
					isJobsLoading: false,
				});
			}
		}, timeout);
	};

	DownloadCSV = async (csvPath) => {
		const href = await downloadFromS3Signed(csvPath.key, csvPath.bucket);
		return (
			<p className="text-right">
				<a target="_blank" href={href} rel="noreferrer">
					Download Email CSV
				</a>
			</p>
		);
	};

	UserCsvheaders = [
		{ label: 'First Name', key: 'firstName' },
		{ label: 'Last Name', key: 'lastName' },
		{ label: 'User Email', key: 'UserEmail' },
		{ label: 'Status', key: 'status' },
	];

	// This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
	calcCrow(lat1, lon1, lat2, lon2) {
		const R = 6371; // Km
		const dLat = this.toRad(lat2 - lat1);
		const dLon = this.toRad(lon2 - lon1);
		var lat1 = this.toRad(lat1);
		var lat2 = this.toRad(lat2);

		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;
		return d;
	}

	checkLocation = (locationFilter, user) => {
		const { filteredDistance } = locationFilter;
		const { lat, lon } = this.state.location;
		let userLocation = null;
		let userLat = null;
		let userLon = null;

		if (user !== null) {
			userLocation = user.location ? parse(get(user, 'location', null)) : null;
			userLat = get(userLocation, 'lat', null);
			userLon = get(userLocation, 'lon', null);
		}

		if (lat && lon && userLat && userLon && Number(filteredDistance)) {
			const candidateDis = this.calcCrow(lat, lon, userLat, userLon);
			if (candidateDis <= Number(filteredDistance)) {
				return true;
			}

			return false;
		}

		if (Number(filteredDistance) == 0) {
			return true;
		}

		return false;
	};

	createCampaign = async () => {
		const errors = [];
		this.props.form.validateFields(async (error, values) => {
			if (values.scheduleName && values.scheduleName.length > 60) {
				const subjectLengthError = new Error(
					'Schedule Name cannot be more than 60 characters'
				);
				errors.push(subjectLengthError);
			}
		});
	};

	decodeHtml(html) {
		const txt = document.createElement('textarea');
		txt.innerHTML = html;
		return txt.value;
	}

	disabledDate(current) {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	}

	doSubmit = async (sendMode) => {
		const { currentUser } = this.props;
		const {
			filteredDepartments,
			filteredSubCompanies,
			filteredUserGroups,
			dateRangeFilterValue,
		} = this.state;
		const { location, filteredDistance } = this.state;

		let lat = '';
		let lng = '';

		const locationSend = {};
		if (location && filteredDistance != 0) {
			const parsedDistance =
				typeof filteredDistance === 'string'
					? Number.parseInt(filteredDistance)
					: filteredDistance;
			lat = get(location, 'lat');
			lng = get(location, 'lon');

			locationSend.lat = lat;
			locationSend.lng = lng;
			locationSend.distance = parsedDistance;
			locationSend.unit = 'mi';
		}

		const jobdata = await this.getJobsInfosByIds();

		const selectedBrand =
			this.state.filteredBrands.length > 0
				? this.state.filteredBrands[0]
				: null;

		let sendObject = null;

		if (sendMode == 'send') {
			if (!this.state.downloadLink) {
				return null;
			}

			sendObject = new Object({
				Key: this.state.downloadLink.key,
				Bucket: this.state.downloadLink.bucket,
			});
		}

		let dateRangeObject = {};
		if (dateRangeFilterValue[1]) {
			dateRangeObject = new Object({
				startDate: dateRangeFilterValue[0],
				endDate: dateRangeFilterValue[1],
			});
		}

		let allEmployees = false;
		if (this.state.allEmp && !this.state.isCandidate) allEmployees = true;

		let allUnclaimed = false;
		if (this.state.allEmpUnclaimed && !this.state.isCandidate && !allEmployees)
			allUnclaimed = true;

		const formattedMessage = this.formatMessage(this.state.message);

		const endpoint = `message-center-email-prod-app`;
		const users = await lambda({
			endpoint,
			variables: {
				subject: this.state.subject,
				message: formattedMessage,
				senderFirstName: currentUser.firstName,
				senderLastName: currentUser.lastName,
				brandColor: currentUser.company.brandColor,
				brandLogo: selectedBrand
					? selectedBrand.logo
						? selectedBrand.logo
						: currentUser.company.logo
					: currentUser.company.logo,
				company: currentUser.company.name,
				jobId: this.state.jobId,
				jobTitle: escape(this.state.jobTitle),
				jobLocation: escape(this.state.jobLocation),
				jobs: jobdata,
				whiteLabel: currentUser.company.whiteLabel,
				s3Bucket: sendObject ? sendObject : null,
				senderEmailAddress: get(currentUser, 'company.senderEmailAddress'),
				host: get(currentUser, 'company.host'),
				sendMode,
				companyId: currentUser.companyId,
				senderId: currentUser.id,
				isCandidate: this.state.isCandidate,
				filters: {
					allEmployees,
					allUnclaimedEmployees: allUnclaimed,
					department: filteredDepartments,
					location: locationSend,
					userGroup: filteredUserGroups,
					subCompany: filteredSubCompanies,
					referredBetween: dateRangeObject,
				},
			},
		});
		return users;
	};

	emailList = () => {
		const { currentUser } = this.props;
		let endpoint = '';
		const { whiteLabel } = currentUser.company;
		if (configMode === 'DEV') {
			endpoint = 'message-center-email-csv-dev-sendEmail';
			lambda({
				endpoint,
				variables: {
					whiteLabel,
					companyId: currentUser.companyId,
					subCompanyId: this.state.subCompanyFilter,
					departmentId: this.state.departmentFilter,
					userGroupId: this.state.userGroupFilter,
					emailAddress: currentUser.emailAddress,
					location: this.state.location,
					filteredDistance: this.state.filteredDistance,
				},
			});
		} else {
			endpoint = 'message-center-email-csv-prod-sendEmail';
			lambda({
				endpoint,
				variables: {
					whiteLabel,
					companyId: currentUser.companyId,
					subCompanyId: this.state.subCompanyFilter,
					departmentId: this.state.departmentFilter,
					userGroupId: this.state.userGroupFilter,
					emailAddress: currentUser.emailAddress,
				},
			});
		}

		message.success('Request is submitted successfully.');
	};

	filteredCandidateByLocation = (locationFilter, job) => {
		const { filteredDistance } = locationFilter;
		const location = get(locationFilter, 'location');
		let lat = '';
		let lon = '';
		if (location) {
			lat = get(location, 'lat');
			lon = get(location, 'lon');
		}

		if (lat === '' && lon === '') return true;
		let jobLat = null;
		let jobLon = null;
		if (job !== null) {
			jobLat = get(job, 'lat', null);
			jobLon = get(job, 'lon', null);
		}

		if (lat && lon && jobLat && jobLon && Number(filteredDistance)) {
			const candidateDis = this.calcCrow(lat, lon, jobLat, jobLon);
			if (candidateDis <= Number(filteredDistance)) {
				return true;
			}

			return false;
		}

		if (Number(filteredDistance) == 0) {
			return true;
		}

		return false;
	};

	formatMessage = (text) => {
		const anchorTagRegex = /<a.*?>/g;
		let formattedMessage = text;
		if (!text.includes('font-family')) {
			formattedMessage = `<div style="font-family: 'Open Sans', 'Helvetica Nueue', Helvetica, Arial, sans-serif;">${text}</div>`;
		}

		formattedMessage = formattedMessage.replaceAll(anchorTagRegex, (match) => {
			if (!match.includes('style')) {
				return `${match.slice(
					0,
					-1
				)} style="color: #018DD3; text-decoration: none;">`;
			}

			return match;
		});

		return formattedMessage;
	};

	handleCandidates = async () => {
		this.props.form.resetFields();
		this.setState({
			isEmployee: false,
			isCandidate: true,
			filteredDepartments: [],
			isVerified: false,
			downloadLink: null,
		});
	};

	handleSelectLocation(location) {
		this.setState(location);
		this.setState({ location }, () => {
			this.handleFilterLocation();
		});
	}

	handleChangeLocation(address) {
		this.setState({ address });
	}

	handleContinue = async () => {
		const { currentUser } = this.props;
		const { sendPush, departmentFilter, subCompanyFilter, userGroupFilter } =
			this.state;
		const errors = [];
		this.props.form.validateFields(async (error, values) => {
			const jobdata = await this.getJobsInfosByIds();
			const selectedBrand =
				this.state.filteredBrands.length > 0
					? this.state.filteredBrands.length[0]
					: null;
			const messageTemplate = {
				companyId: currentUser.companyId,
				senderId: currentUser.id,
				subject: values.subject,
				message: this.state.message,
				senderFirstName: currentUser.firstName,
				senderLastName: currentUser.lastName,
				brandColor: currentUser.company.brandColor,
				brandLogo: selectedBrand
					? selectedBrand.logo
						? selectedBrand.logo
						: currentUser.company.logo
					: currentUser.company.logo,
				company: currentUser.company.name,
				jobId: this.state.jobId,
				jobTitle: escape(this.state.jobTitle),
				jobLocation: escape(this.state.jobLocation),
				jobs: jobdata,
				whiteLabel: currentUser.company.whiteLabel,
				sendPush,
				pushMsg: values.pushMsg,
				senderEmailAddress: get(currentUser, 'company.senderEmailAddress'),
				host: get(currentUser, 'company.host'),
				departmentId: departmentFilter,
				subCompanyId: subCompanyFilter,
				userGroupId: userGroupFilter,
			};
			const { whiteLabel } = currentUser.company;
			if (configMode === 'DEV') {
				messageTemplate.mode = 'dev';
				messageTemplate.whiteLabel = whiteLabel;
			} else {
				messageTemplate.mode = 'prod';
				messageTemplate.whiteLabel = whiteLabel;
			}

			if (errors.length === 0) {
				const endpoint = 'message-center-all-email-trigger';
				lambda({ endpoint, variables: { messageTemplate } });
				setTimeout(() => {
					this.sendEmailAllUsers();
				}, 2000);
			}
		});
		this.props.form.resetFields();
		this.setState({
			visible: false,
		});
	};

	handleContinueSending = async () => {
		this.props.form.validateFields(async (error, values) => {
			const errors = [];

			if (values.subject && values.subject.length > 60) {
				const subjectLengthError = new Error(
					'Subject cannot be more than 60 characters'
				);
				errors.push(subjectLengthError);
			}

			if (
				values.subject === '' ||
				values.subject === null ||
				values.subject === undefined
			) {
				errors.push('Please enter a Subject');
				this.setState({ isSubjectEmpty: true, subject: '' });
			} else {
				this.setState({ isSubjectEmpty: false, subject: values.subject });
			}

			if (
				this.state.message === '' ||
				this.state.message === undefined ||
				this.state.message === null
			) {
				errors.push('Please enter a Message');
				this.setState({ isMessageEmpty: true });
			} else {
				this.setState({ isMessageEmpty: false });
			}

			if (errors.length === 0) {
				try {
					await this.doSubmit('send');
				} catch (error) {
					console.log('error sending test', error);
				}

				this.sendEmailAllUsers();
			}
		});

		this.setState({
			visibleSubmit: false,
			isVerified: true,
		});
	};

	handleContinueVerifying = async () => {
		const users = await this.doSubmit('preflight');

		const updateObject = {
			isVerified: true,
			visibleVerify: false,
		};

		if (this.state.isCandidate) {
			updateObject.filteredCount = users.total;
		} else {
			updateObject.usersCount = users.total;
		}

		updateObject.downloadLink = users.total == '0' ? null : users.s3Bucket;

		this.setState(updateObject);
		const csvLink = await this.DownloadCSV(updateObject.downloadLink);
		this.setState({ csvLink });
	};

	handleDateRangeFilter(value) {
		this.setState(
			{
				dateRangeFilterValue: value,
				isVerified: false,
				downloadLink: null,
			},
			this.setFilteredData
		);
	}

	handleEditorChange = (content) => {
		let newcontent = this.decodeHtml(content);
		if (newcontent.includes('img')) {
			newcontent = newcontent.replaceAll(/\bwidth="(\d+)"/g, 'width="100%"');
		}

		if (content.includes('img')) {
			content = content.replaceAll(
				/\bwidth="(\d+)"/g,
				'style = "max-width: 100%;"'
			);
		}

		this.setState({
			message: content,
			pushmessage: newcontent,
		});
	};

	handleEmployees = () => {
		this.props.form.resetFields();
		this.setState({
			isCandidate: false,
			isEmployee: true,
			filteredDepartments: [],
			isVerified: false,
			downloadLink: null,
		});
		const { location, filteredDistance } = this.state;
		this.updateFilterUsers([], [], [], { location, filteredDistance });
	};

	handleFilterBrands = async (brand) => {
		const { subCompanies } = this.state;

		const selectedRecord = subCompanies.filter((x) => x.id == brand);
		this.setState({
			filteredBrands: selectedRecord,
		});
	};

	handleFilterDepartment = (department) => {
		const { filteredDepartments } = this.state;
		if (filteredDepartments.includes(department)) return;
		const updated = [...filteredDepartments, department];
		this.setState({
			filteredDepartments: updated,
			departmentFilter: department,
			isVerified: false,
			downloadLink: null,
		});
	};

	handleFilterDistance = async (value) => {
		if (value === '0') {
			this.setState({
				city: '',
				state: '',
				lat: '',
				lon: '',
				address: '',
			});
		}

		this.setState({ filteredDistance: value }, () => {
			this.handleFilterLocation();
		});
	};

	handleFilterLocation = () => {
		this.setState({ isVerified: false, downloadLink: null });
	};

	handleFilterSubCompany = (subCompany) => {
		const { filteredSubCompanies } = this.state;
		if (filteredSubCompanies.includes(subCompany)) return;

		const updated = [...filteredSubCompanies, subCompany];
		this.setState({
			filteredSubCompanies: updated,
			subCompanyFilter: subCompany,
			isVerified: false,
			downloadLInk: null,
		});
	};

	handleFilterUserGroup = (userGroup) => {
		const { filteredUserGroups } = this.state;
		if (filteredUserGroups.includes(userGroup)) return;
		const updated = [...filteredUserGroups, userGroup];
		this.setState({
			filteredUserGroups: updated,
			userGroupFilter: userGroup,
			isVerified: false,
			downloadLink: null,
		});
	};

	handleNevermind = () => {
		this.setState({
			visible: false,
			allEmp: false,
			allEmpUnclaimed: false,
		});
	};

	handleNevermindSending = () => {
		this.setState({
			visibleSubmit: false,
		});
	};

	handleNevermindVerifying = () => {
		this.setState({
			visibleVerify: false,
		});
	};

	handleRemoveBrandFilter = (department) => {
		const { filteredBrands } = this.state;
		const index = filteredBrands.indexOf(department);
		const filterDepartments = (item) => item !== filteredBrands[index];
		const jobs =
			filteredBrands.length === 1
				? []
				: filteredBrands.filter(filterDepartments);
		this.setState({
			filteredBrands: jobs,
		});
	};

	handleRemoveFilter = (department) => {
		const { filteredDepartments } = this.state;
		const index = filteredDepartments.indexOf(department);
		const filterDepartments = (item) => item !== filteredDepartments[index];
		const departments =
			filteredDepartments.length === 1
				? []
				: filteredDepartments.filter(filterDepartments);
		this.setState({
			filteredDepartments: departments,
			departmentFilter: null,
			isVerified: false,
			downloadLink: null,
		});
	};

	handleRemoveFilterDistance = async () => {
		this.setState({ filteredDistance: '0' }, () => {
			this.handleFilterLocation();
		});
	};

	handleRemoveSubCompanyFilter = (subCompany) => {
		const { filteredSubCompanies } = this.state;
		const index = filteredSubCompanies.indexOf(subCompany);
		const filterSubCompanies = (item) => item !== filteredSubCompanies[index];
		const subCompanies =
			filteredSubCompanies.length === 1
				? []
				: filteredSubCompanies.filter(filterSubCompanies);

		this.setState({
			filteredSubCompanies: subCompanies,
			subCompanyFilter: null,
			isVerified: false,
			downloadLink: null,
		});
	};

	handleRemoveUserGroupFilter = (userGroup) => {
		const { filteredUserGroups } = this.state;
		const index = filteredUserGroups.indexOf(userGroup);
		const filterUserGroups = (item) => item !== filteredUserGroups[index];
		const userGroups =
			filteredUserGroups.length === 1
				? []
				: filteredUserGroups.filter(filterUserGroups);

		this.setState({
			filteredUserGroups: userGroups,
			userGroupFilter: null,
			isVerified: false,
			donwloadLink: null,
		});
	};

	handleSendAll = (e) => {
		if (e.target.checked) {
			this.setState({
				allEmp: true,
			});
		} else {
			this.setState({
				allEmp: false,
			});
		}

		this.setState({ isVerified: false, downloadLink: null });
	};

	handleSendAllUnclaimed = (e) => {
		if (e.target.checked) {
			this.setState({
				allEmpUnclaimed: true,
			});
		} else {
			this.setState({
				allEmpUnclaimed: false,
			});
		}

		this.setState({ isVerified: false, downloadLink: null });
	};

	handleSendPushChange = (e) => {
		if (e.target.checked) {
			this.setState({
				sendPush: true,
			});
		} else {
			this.setState({
				sendPush: false,
			});
		}
	};

	handleSubmitEmployees = async () => {
		const { isVerified } = this.state;
		const errors = [];

		this.props.form.validateFields(async (error, values) => {
			if (isVerified) {
				if (values.subject && values.subject.length > 60) {
					const errors = [];
					const subjectLengthError = new Error(
						'Subject cannot be more than 60 characters'
					);
					errors.push(subjectLengthError);
				}

				if (
					values.subject === '' ||
					values.subject === null ||
					values.subject === undefined
				) {
					errors.push('Please enter a Subject');
					this.setState({ isSubjectEmpty: true });
				} else {
					this.setState({ isSubjectEmpty: false });
				}

				if (
					this.state.message === '' ||
					this.state.message === undefined ||
					this.state.message === null
				) {
					errors.push('Please enter a Message');
					this.setState({ isMessageEmpty: true });
				} else {
					this.setState({ isMessageEmpty: false });
				}

				if (errors.length === 0) this.setState({ visibleSubmit: true });
			} else {
				this.setState({ visibleVerify: true });
			}
		});
	};

	handleTestEmail = async () => {
		this.props.form.validateFields(async (error, values) => {
			const errors = [];

			if (values.subject && values.subject.length > 60) {
				const subjectLengthError = new Error(
					'Subject cannot be more than 60 characters'
				);
				errors.push(subjectLengthError);
			}

			if (
				values.subject === '' ||
				values.subject === null ||
				values.subject === undefined
			) {
				errors.push('Please enter a Subject');
				this.setState({ isSubjectEmpty: true, subject: '' });
			} else {
				this.setState({ isSubjectEmpty: false, subject: values.subject });
			}

			if (
				this.state.message === '' ||
				this.state.message === undefined ||
				this.state.message === null
			) {
				errors.push('Please enter a Message');
				this.setState({ isMessageEmpty: true });
			} else {
				this.setState({ isMessageEmpty: false });
			}

			if (errors.length === 0) {
				try {
					await this.doSubmit('me');
				} catch (error) {
					console.log('error sending test', error);
				}

				this.sendTestEmail();
			}
		});
	};

	headers = [
		{ label: 'First Name', key: 'firstName' },
		{ label: 'Last Name', key: 'lastName' },
		{ label: 'Candidate Email', key: 'candidateEmail' },
	];

	newTinyMCE = () => {
		return (
			<div
				style={{
					height: 250,
					overflow: 'scroll',
					fontFamily: 'inherit',
					width: '100%',
				}}
			>
				<Editor
					apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
					init={{
						height: 250,
						menubar: false,
						branding: false,
						plugins: ['image code media link'],
						toolbar:
							'undo redo | styleselect | fontselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  | \
             media link image ',
						default_link_target: '_blank',
						images_upload_handler: this.uploadImageCallBack,
						automatic_uploads: true,
						file_picker_types: 'image',
						max_width: '100%',
						content_style:
							"@import url('https://fonts.googleapis.com/css?family=Open+Sans:regular,italic,bold,bolditalic'); body { font-family: 'Open Sans', sans-serif; } img { max-width: 100%; } a {color: #018DD3; text-decoration: none !important;}",
						font_formats:
							'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica nueue,helvetica; Impact=impact,chicago; Open Sans=open sans,helvetica nueue,helvetica,arial,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;',
						relative_urls: false,
						convert_urls: false,
						remove_script_host: false,
					}}
					onEditorChange={this.handleEditorChange}
				/>
			</div>
		);
	};

	async searchAllUsers() {
		const promises = [];
		const endLoopPoint =
			this.state.totalPages > 100 ? 100 : this.state.totalPages;
		for (let i = 1; i <= endLoopPoint; i++) {
			const parameters = {
				query: '',
				size: 300,
				current: i,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					companies: get(this.state.company, 'id'),
					departments: this.state.filteredDepartments,
					roles: this.state.filteredRoles,
					active: this.state.filteredStatus,
				},
			};
			promises.push(searchOpenSearchNetwork(parameters, 'erin-users'));
		}

		let resArr = [];
		await Promise.all(promises)
			.then((res) => {
				resArr = [...res];
			})
			.catch((error) => {
				console.log('error', error);
			});
		return resArr;
	}

	sendEmailAllUsers = () => {
		const { isEmployee, isCandidate } = this.state;
		if (isEmployee && !isCandidate) {
			this.setState({
				isEmailAllUsers: true,
				isEmailAllCandidates: false,
			});
		} else {
			this.setState({
				isEmailAllUsers: false,
				isEmailAllCandidates: true,
			});
		}

		setTimeout(() => {
			this.setState({
				isEmailAllUsers: false,
				isEmailAllCandidates: false,
			});
		}, 4000);
	};

	sendTestEmail = () => {
		this.setState({
			isTestEmailDone: true,
		});
		setTimeout(() => {
			this.setState({
				isTestEmailDone: false,
			});
		}, 4000);
	};

	// Converts numeric degrees to radians
	toRad(Value) {
		return (Value * Math.PI) / 180;
	}

	async updateFilterUsers(
		SelectedUserGroups,
		SelectedSubCompanies,
		SelectedDepartments,
		locationFilter = null
	) {
		const filtered = get(this.state, 'allUsers', []).filter((element) => {
			const userGroupCheck =
				SelectedUserGroups.length > 0
					? SelectedUserGroups.includes(element.userGroupId)
					: true;
			const subCompanyCheck =
				SelectedSubCompanies.length > 0
					? SelectedSubCompanies.includes(element.subCompanyId)
					: true;
			const locationCheck = locationFilter
				? this.checkLocation(locationFilter, element)
				: true;
			const DeptCheck =
				SelectedDepartments.length > 0
					? SelectedDepartments.includes(element.departmentId)
					: true;
			return userGroupCheck && subCompanyCheck && locationCheck && DeptCheck;
		});
		this.setState({
			filteredUsers: filtered,
			usersCount: filtered.length,
		});
	}

	uploadImageCallBack = async (blobInfo, success, failure, progress) => {
		progress(1);
		const image = blobInfo.base64();
		const file = blobInfo.blob();
		const companyId = this.props?.companyId;
		const uploadFile = await uploadImageToS3FromTinyMCE(
			image,
			file,
			`message-center/${companyId}/${Date.now()}-${file.name}`
		);
		const link = await downloadFromS3Signed(uploadFile.key, uploadFile.bucket);
		progress(100);
		success(link);
	};

	renderMessageScheduleEmails = () => {
		return (
			<section className={tableContainer} style={{ marginBottom: 20 }}>
				<Collapse bordered={false} style={{ background: 'transparent' }}>
					<Panel
						key="123"
						header={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flex: 1,
								}}
							>
								<span className={LabelClass}>Scheduler 1</span>
								<span style={{ color: '#8d99a3', fontSize: 15 }}>Complete</span>
							</div>
						}
					>
						<Row>
							<Col lg={20}>
								<Row className={scheduleEmailFilterContainer}>
									<Col
										style={{ display: 'flex', alignItems: 'center' }}
										className={changeDirection}
									>
										<div className={LabelStyles}>Logo:</div>
										<div className="mx-2" style={{ marginTop: '6px' }}>
											<span style={{ fontSize: 16 }}>Main Logo (Default)</span>
										</div>
									</Col>
								</Row>
								<Row className={scheduleEmailFilterContainer}>
									<Col
										style={{ display: 'flex', alignItems: 'center' }}
										className={changeDirection}
									>
										<div className={LabelStyles}>Subject:</div>
										<div className="mx-2" style={{ marginTop: '6px' }}>
											<span style={{ fontSize: 16 }}>Email Subject</span>
										</div>
									</Col>
								</Row>
								<Row className={scheduleEmailFilterContainer}>
									<Col
										style={{ display: 'flex', alignItems: 'center' }}
										className={changeDirection}
									>
										<div className={LabelStyles}>Massage:</div>
										<div className="mx-2" style={{ marginTop: '6px' }}>
											<span style={{ fontSize: 16 }}>Email Message</span>
										</div>
									</Col>
								</Row>
								<Row className={scheduleEmailFilterContainer}>
									<Col
										style={{ display: 'flex', alignItems: 'center' }}
										className={changeDirection}
									>
										<div className={LabelStyles}>Push Notification:</div>
										<div className="mx-2" style={{ marginTop: '6px' }}>
											<span style={{ fontSize: 16 }}>FALSE</span>
										</div>
									</Col>
								</Row>
							</Col>
							<Col lg={4} style={{ textAlign: 'right' }} />
						</Row>
					</Panel>
				</Collapse>
			</section>
		);
	};

	render() {
		const { RangePicker } = DatePicker;
		const {
			filteredDistance,
			ml_SendAMessage,
			ml_SelectEmployees,
			ml_WhoWill,
			ml_InGroup,
			ml_AndInDept,
			ml_Optional,
			ml_Message,
			ml_TheMessage,
			ml_Subject,
			ml_SendMe,
			ml_MessageCenter_Email,
			ml_Employees,
			ml_Location,
			ml_withIn,
			ml_of,
			ml_miles,
			ml_AllLocations,
			isEmployee,
			isCandidate,
			ml_MessageCenter_SelectBrand,
			jobsInputValue,
			allJobs,
			subCompanies,
			isJobsLoading,
			jobsLoadingFailed,
			sendPush,
			allEmp,
			allEmpUnclaimed,
			isVerified,
			downloadLink,
		} = this.state;
		const FormItem = Form.Item;
		const { getFieldDecorator } = this.props.form;
		const { currentUser, departments, userGroups } = this.props;

		if (!get(currentUser, 'company')) return <Spinner />;

		let defaultValue = '0';
		const localDistance = window.localStorage.getItem('setWithInDistance');
		if (localDistance !== null) defaultValue = localDistance;
		const handleSubjectLengthValidator = (rule, value, callback) => {
			const errors = [];

			if (value && value.length > 60) {
				const error = new Error('Subject cannot be more than 60 characters');
				errors.push(error);
			}

			const content = get(errors[0], 'message', null);

			if (errors.length > 0) {
				callback(
					<Popover
						open
						placement="topLeft"
						overlayStyle={{ width: 175 }}
						overlayClassName={PopoverStyle}
						style={{
							position: 'absolute',
							fontWeight: 1000,
							padding: 0,
							margin: 0,
						}}
						content={content}
					>
						<div
							style={{
								position: 'relative',
								top: -50,
								left: 85,
								display: 'inline-block',
							}}
						/>
					</Popover>
				);
			}

			callback();
		};

		const sortedUserGroupsAdvanceFilter = _.sortBy(userGroups, ['name']);
		const sortedSubCompaniesAdvanceFilter = _.sortBy(this.props.subCompanies, [
			'name',
		]);
		const userGroupOptionsAdvanceFilter = sortedUserGroupsAdvanceFilter.map(
			(userGroup) => {
				return (
					<Option key={userGroup.id} value={userGroup.id}>
						{userGroup.name}
					</Option>
				);
			}
		);
		const subCompanyOptionsAdvanceFilter = sortedSubCompaniesAdvanceFilter.map(
			(subCompany) => {
				return (
					<Option key={subCompany.id} value={subCompany.id}>
						{subCompany.name}
					</Option>
				);
			}
		);

		const sortedDepartments = _.sortBy(departments, ['name']);
		const options = sortedDepartments.map((department) => {
			return (
				<Option key={department.id} value={department.id}>
					{department.name}
				</Option>
			);
		});

		// This jobs code creates and limits job options for performance reasons
		const sortedJobs = _.sortBy(allJobs, function (job) {
			return job.title;
		});
		const reactSelectOptions = sortedJobs.map((job) => {
			return {
				value: job.id,
				label: `${job.title} (req: ${
					job.externalJobId ? job.externalJobId : 'N/A'
				})`,
			};
		});

		const sortedCampaigns = _.sortBy(this.state.campaigns, function (campaign) {
			return campaign.name;
		});
		const reactCampaignOptions = sortedCampaigns.map((camp) => {
			return {
				value: camp.id,
				label: `${camp.name}`,
			};
		});

		const MAX_DISPLAYED_OPTIONS = 100;

		const filteredOptions = () => {
			if (!jobsInputValue) {
				return reactSelectOptions;
			}

			const matchByStart = [];
			const matchByInclusion = [];

			const regByInclusion = new RegExp(escapeRegExp(jobsInputValue), 'i');
			const regByStart = new RegExp(`^${escapeRegExp(jobsInputValue)}`, 'i');

			for (const option of reactSelectOptions) {
				if (regByInclusion.test(option.label)) {
					if (regByStart.test(option.label)) {
						matchByStart.push(option);
					} else {
						matchByInclusion.push(option);
					}
				}
			}

			return [...matchByStart, ...matchByInclusion];
		};

		const slicedJobOptions = filteredOptions().slice(0, MAX_DISPLAYED_OPTIONS);

		const brandsOptions = [];
		brandsOptions.push(
			<Option key="1" value="1">
				Main Logo (Default)
			</Option>
		);
		let sortedSubCompanies = [];
		if (subCompanies.length > 0) {
			sortedSubCompanies = _.sortBy(subCompanies, ['name']);
		}

		sortedSubCompanies.map((brand) => {
			brandsOptions.push(
				<Option key={brand.id} value={brand.id}>
					{brand.name}
				</Option>
			);
		});
		const isEmp = get(this.state, 'isEmployee', false);
		const isCan = get(this.state, 'isCandidate', false);
		const isVersion = get(this.state, 'isVerified', false);

		const { countLoaded, filteredCount, usersCount } = this.state;
		let isSendButtonDisabled = false;
		if (isEmp && !isCan) {
			if (usersCount === 0 && isVersion) {
				isSendButtonDisabled = true;
			}
		} else if (isCan && !isEmp && filteredCount === 0 && isVersion) {
			isSendButtonDisabled = true;
		}

		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">{ml_SendAMessage}</h2>
				</div>
				<section className="send-msg-card" style={{ maxWidth: 900 }}>
					<div className="msg-tab-btnGrp">
						<Button
							className={`msg-tab-btn ${isEmp ? 'active' : ''}`}
							onClick={this.handleEmployees}
						>
							To Employees
						</Button>
						<Button
							className={`msg-tab-btn ${isCan ? 'active' : ''}`}
							onClick={this.handleCandidates}
						>
							To Candidates
						</Button>
					</div>
					{countLoaded}
					<div className="sm-left">
						<h4 className="sm-heading">
							{isEmp && !isCan ? ml_SelectEmployees : 'Select Candidates'}
						</h4>
						<h5 className="sm-subheading">{ml_WhoWill}</h5>

						{isEmp && !isCan && (
							<>
								<div
									className={`custom-form-group ${
										allEmp || allEmpUnclaimed ? 'disabled-form-grp' : ''
									}`}
								>
									<label>{ml_AndInDept}:</label>
									<div className="custom-form-green-select">
										<Select
											filterOption={(input, option) => {
												return option.props.children
													.toLowerCase()
													.includes(input.toLowerCase());
											}}
											className="custom-input"
											dropdownMatchSelectWidth={false}
											mode="multiple"
											placeholder="All"
											onSelect={(department) =>
												this.handleFilterDepartment(department)
											}
											onDeselect={(department) =>
												this.handleRemoveFilter(department)
											}
										>
											{options}
										</Select>
									</div>
								</div>
								<div
									className={`custom-form-group ${
										allEmp || allEmpUnclaimed ? 'disabled-form-grp' : ''
									}`}
								>
									<label>
										{ml_Location} <span>{ml_Optional}</span>:
									</label>
									<div className="input-grp">
										<Select
											className="custom-input"
											defaultValue={defaultValue}
											placeholder="Location"
											onSelect={(distance) =>
												this.handleFilterDistance(distance)
											}
											onDeselect={(distance) =>
												this.handleRemoveFilterDistance(distance)
											}
										>
											<Select.Option key={0} value="0">
												{ml_AllLocations}
											</Select.Option>
											<Select.Option key={1} value="25">
												{ml_withIn} 25 {ml_miles}
											</Select.Option>
											<Select.Option key={2} value="50">
												{ml_withIn} 50 {ml_miles}
											</Select.Option>
											<Select.Option key={3} value="100">
												{ml_withIn} 100 {ml_miles}
											</Select.Option>
											<Select.Option key={4} value="200">
												{ml_withIn} 200 {ml_miles}
											</Select.Option>
										</Select>
										<span>{ml_of}</span>
										<PlacesAutocomplete
											address={this.state.address}
											setAddress={this.handleChangeLocation.bind(this)}
											onSelect={this.handleSelectLocation.bind(this)}
										/>
									</div>
									<span />
								</div>
								<Form>
									<Collapse bordered={false} className="sm-form-collapse">
										<Panel
											key="1"
											className="sm-collapse-panel"
											header={
												<>
													Advanced Filters
													<i className="icon-arrow-down sm-collapse-icon" />
												</>
											}
										>
											<div
												className={`custom-form-group ${
													allEmp || allEmpUnclaimed ? 'disabled-form-grp' : ''
												}`}
											>
												<label>{ml_InGroup}:</label>
												<div className="custom-form-green-select">
													<Select
														filterOption={(input, option) => {
															return option.props.children
																.toLowerCase()
																.includes(input.toLowerCase());
														}}
														className="custom-input"
														mode="multiple"
														placeholder="All"
														onSelect={(userGroup) =>
															this.handleFilterUserGroup(userGroup)
														}
														onDeselect={(userGroup) =>
															this.handleRemoveUserGroupFilter(userGroup)
														}
													>
														{userGroupOptionsAdvanceFilter}
													</Select>
												</div>
											</div>
											<div
												className={`custom-form-group ${
													allEmp || allEmpUnclaimed ? 'disabled-form-grp' : ''
												}`}
											>
												<label>In Subcompany:</label>
												<div className="custom-form-green-select">
													<Select
														filterOption={(input, option) => {
															return option.props.children
																.toLowerCase()
																.includes(input.toLowerCase());
														}}
														className="custom-input"
														mode="multiple"
														placeholder="All"
														onSelect={(userGroup) =>
															this.handleFilterSubCompany(userGroup)
														}
														onDeselect={(userGroup) =>
															this.handleRemoveSubCompanyFilter(userGroup)
														}
													>
														{subCompanyOptionsAdvanceFilter}
													</Select>
												</div>
											</div>

											<div
												className={`custom-form-group mt-4 ${
													allEmpUnclaimed ? 'disabled-form-grp' : ''
												}`}
											>
												<label>All Employees:</label>
												<div className="custom-form-group custom-checkbox left-check mb-0">
													<input
														type="checkbox"
														id="allEmployees"
														checked={this.state.allEmp}
														onChange={this.handleSendAll}
													/>
													<label
														className="bs-form-label"
														htmlFor="allEmployees"
													>
														Message All Company Employees (Requires Active
														Employee Feed)
													</label>
												</div>
											</div>

											<div
												className={`custom-form-group mt-4 ${
													allEmp ? 'disabled-form-grp' : ''
												}`}
											>
												<label>All Employees:</label>
												<div className="custom-form-group custom-checkbox left-check mb-0">
													<input
														type="checkbox"
														id="allEmployees1"
														checked={this.state.allEmpUnclaimed}
														onChange={this.handleSendAllUnclaimed}
													/>
													<label
														className="bs-form-label"
														htmlFor="allEmployees1"
													>
														Message All Company Employees that haven't claimed
														an Account
													</label>
												</div>
											</div>
										</Panel>
									</Collapse>
								</Form>
							</>
						)}

						{!isEmp && isCan && (
							<>
								<div className="custom-form-group">
									<label>In Department:</label>
									<div className="custom-form-green-select">
										<Select
											className="custom-input"
											mode="multiple"
											placeholder="All"
											onSelect={(department) =>
												this.handleFilterDepartment(department)
											}
											onDeselect={(department) =>
												this.handleRemoveFilter(department)
											}
										>
											{options}
										</Select>
									</div>
								</div>
								<div className="custom-form-group">
									<label>Referred between:</label>
									<RangePicker
										className="ant-picker-100w"
										separator={<span>~</span>}
										format="MM-DD-YYYY"
										startdateText="Start date"
										enddateText="End date"
										maxDate={dayjs()}
										disabledDate={this.disabledDate}
										onChange={(value) => this.handleDateRangeFilter(value)}
									/>
								</div>
								<div className="custom-form-group">
									<label>
										{ml_Location}
										<span>{ml_Optional}</span>:
									</label>
									<div className="input-grp">
										<Select
											className="custom-input"
											defaultValue={defaultValue}
											placeholder="Location"
											onSelect={(distance) =>
												this.handleFilterDistance(distance)
											}
											onDeselect={(distance) =>
												this.handleRemoveFilterDistance(distance)
											}
										>
											<Select.Option key={0} value="0">
												{ml_AllLocations}
											</Select.Option>
											<Select.Option key={1} value="25">
												{ml_withIn} 25 {ml_miles}
											</Select.Option>
											<Select.Option key={1} value="50">
												{ml_withIn} 50 {ml_miles}
											</Select.Option>
											<Select.Option key={2} value="100">
												{ml_withIn} 100 {ml_miles}
											</Select.Option>
											<Select.Option key={3} value="200">
												{ml_withIn} 200 {ml_miles}
											</Select.Option>
										</Select>
										<span>{ml_of}</span>
										<PlacesAutocomplete
											address={this.state.address}
											setAddress={this.handleChangeLocation.bind(this)}
											onSelect={this.handleSelectLocation.bind(this)}
										/>
									</div>
								</div>
							</>
						)}
						<h4 className="sm-heading">Message</h4>
						<h5 className="sm-subheading">{ml_TheMessage}</h5>

						<FormItem>
							<div className="custom-form-group">
								<label>{ml_MessageCenter_SelectBrand}:</label>
								<Select
									showSearch
									filterOption={(input, option) => {
										return option.props.children
											.toLowerCase()
											.includes(input.toLowerCase());
									}}
									className="custom-input"
									placeholder="None"
									defaultValue="1"
									onSelect={(job) => this.handleFilterBrands(job)}
								>
									{brandsOptions}
								</Select>
							</div>
							<div className="custom-form-group">
								<label>{ml_Subject}:</label>
								<div className="input-error-wrap">
									{getFieldDecorator('subject', {
										rules: [{ validator: handleSubjectLengthValidator }],
									})(<Input className="custom-input" />)}
									{this.state.isSubjectEmpty && (
										<span className="input-error">
											{' '}
											Please enter a Subject{' '}
										</span>
									)}
								</div>
							</div>
						</FormItem>

						<div className="custom-form-group">
							<label>{ml_Message}:</label>
							<div className="send-msg-editior">
								{this.newTinyMCE()}
								{this.state.isMessageEmpty && (
									<span className="input-error">Please enter a Message</span>
								)}
							</div>
						</div>

						{isEmployee ? (
							<>
								{sendPush ? (
									<div className="custom-form-group">
										<label>Include Push Message:</label>
										{getFieldDecorator('pushMsg', {
											rules: [{ validator: handleSubjectLengthValidator }],
										})(<Input className="custom-input" />)}
									</div>
								) : null}
							</>
						) : null}

						<h4 className="sm-heading">
							Include Jobs <span>{ml_Optional}</span>
						</h4>

						<div className="custom-form-group">
							<label>Select Jobs:</label>
							<ReactSelect
								isMulti
								styles={{
									container: (provided) => ({
										...provided,
										width: '100%',
									}),
									control: (provided, state) => ({
										...provided,
										'& :hover': {
											borderColor: 'blue',
										},

										minHeight: '40px',
										cursor: 'pointer',
										border: state.isFocused && 'none',
										boxShadow: state.isFocused && '0 0 1px 1px #40a9ff',
									}),
									valueContainer: (provided) => ({
										...provided,
										paddingRight: '30px',
									}),
									option: (provided, state) => ({
										...provided,
										backgroundColor: state.isFocused && '#e6f7ff',
										color: 'rgba(0, 0, 0, 0.65)',
										fontSize: '14px',
									}),
									multiValue: (provided) => ({
										...provided,
										backgroundColor: '#bdf9bd',
										color: '#1d861d',
									}),
									multiValueLabel: (provided) => ({
										...provided,
										color: '#1d861d',
									}),
								}}
								// Used to filter jobs and set a limit on the number of jobs to render
								components={{
									IndicatorSeparator: NullComponent,
									DropdownIndicator: IconComponent,
									ClearIndicator: NullComponent,
								}}
								onChange={(selectedJobs) =>
									this.setState({
										selectedJobs: selectedJobs ? selectedJobs : [],
									})
								}
								// Disables default filtering
								filterOption={() => true}
								placeholder={
									isJobsLoading ? (
										<>
											<Spin indicator={<LoadingOutlined />} /> Loading...
										</>
									) : jobsLoadingFailed ? (
										'Failed to load jobs, refresh page and try again'
									) : (
										'Select'
									)
								}
								isDisabled={
									isJobsLoading ||
									jobsLoadingFailed ||
									this.state.selectedCampaign.length > 0
								}
								menuPlacement="auto"
								closeMenuOnSelect={false}
								options={slicedJobOptions}
								maxMenuHeight={150}
								value={this.state.selectedJobs}
								onInputChange={(jobsInputValue) =>
									this.setState({ jobsInputValue })
								}
							/>
						</div>

						<div className="my-2 mx-10">Or</div>
						<div className="custom-form-group">
							<label>Select Campaign:</label>
							<ReactSelect
								isMulti
								styles={{
									container: (provided) => ({
										...provided,
										width: '100%',
									}),
									control: (provided, state) => ({
										...provided,
										'& :hover': {
											borderColor: 'blue',
										},

										minHeight: '40px',
										cursor: 'pointer',
										border: state.isFocused && 'none',
										boxShadow: state.isFocused && '0 0 1px 1px #40a9ff',
									}),
									valueContainer: (provided) => ({
										...provided,
										paddingRight: '30px',
									}),
									option: (provided, state) => ({
										...provided,
										backgroundColor: state.isFocused && '#e6f7ff',
										color: 'rgba(0, 0, 0, 0.65)',
										fontSize: '14px',
									}),
									multiValue: (provided) => ({
										...provided,
										backgroundColor: '#bdf9bd',
										color: '#1d861d',
									}),
									multiValueLabel: (provided) => ({
										...provided,
										color: '#1d861d',
									}),
								}}
								// Used to filter jobs and set a limit on the number of jobs to render
								onChange={(selectedCampaign) =>
									this.setState({
										selectedCampaign: selectedCampaign ? selectedCampaign : [],
									})
								}
								// Disables default filtering
								components={{
									IndicatorSeparator: NullComponent,
									DropdownIndicator: IconComponent,
									ClearIndicator: NullComponent,
								}}
								filterOption={() => true}
								placeholder={
									isJobsLoading ? (
										<>
											<Spin indicator={<LoadingOutlined />} /> Loading...
										</>
									) : jobsLoadingFailed ? (
										'Failed to load campaign, refresh page and try again'
									) : (
										'Select'
									)
								}
								isDisabled={
									isJobsLoading ||
									jobsLoadingFailed ||
									this.state.selectedJobs.length > 0
								}
								menuPlacement="auto"
								closeMenuOnSelect={false}
								options={reactCampaignOptions}
								maxMenuHeight={150}
							/>
						</div>

						<div className="sm-card-bottom">
							{this.state.schMsgValue == 1 && (
								<div className="sm-btn-grp" style={{ marginBottom: 16 }}>
									<Button
										size="large"
										state={this.state.buttonState}
										onClick={this.handleTestEmail}
									>
										{ml_SendMe}
									</Button>
									<Button
										type="primary"
										size="large"
										disabled={isSendButtonDisabled}
										onClick={this.handleSubmitEmployees}
									>
										{isEmployee && !isCandidate && isVerified && (
											<>
												{ml_MessageCenter_Email}{' '}
												{this.state.usersCount.toLocaleString()} {ml_Employees}
											</>
										)}
										{(isEmployee || isCandidate) && !isVerified && (
											<>Verify Emails</>
										)}
										{!isEmployee && isCandidate && isVerified && (
											<>
												{ml_MessageCenter_Email} {this.state.filteredCount}
												{' Candidates'}
											</>
										)}
									</Button>
								</div>
							)}

							{this.state.schMsgValue == 1 && (
								<>
									{isVerified && downloadLink && downloadLink != '' && (
										<div key="downloadCSV">
											{downloadLink ? this.state.csvLink : null}
										</div>
									)}
								</>
							)}

							<div style={{ paddingTop: 10 }}>
								{this.state.isTestEmailDone ? (
									<Alert
										message="Test email sent succesfully!"
										type="success"
									/>
								) : null}
								<div style={{ paddingTop: 10 }}>
									{this.state.isEmailAllUsers && isEmp ? (
										<Alert
											message="Email sent succesfully to the selected users!"
											type="success"
										/>
									) : null}
									{this.state.isEmailAllCandidates && isCandidate ? (
										<Alert
											message="Email sent succesfully to the selected candidates!"
											type="success"
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</section>

				<Modal
					title="With Great Power Comes Great Responsibility"
					open={this.state.visible}
					footer={null}
					onCancel={this.handleNevermind}
				>
					<div className="text-center">
						<img
							alt="spiderman"
							src={spiderman}
							style={{ width: 128, margin: 20 }}
						/>
						<p>This feature will email everyone in your company.</p>
					</div>

					<div className="modal-footer-btn">
						<Button type="link" onClick={this.handleNevermind}>
							Nevermind, Uncheck This Box!
						</Button>
						<Button
							type="primary"
							size="large"
							className="btn-min-width"
							onClick={this.handleContinue}
						>
							{ml(
								`Continue`,
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
						</Button>
					</div>
				</Modal>

				<Modal
					title="Are you sure you want to proceed?"
					open={this.state.visibleSubmit}
					footer={null}
					onCancel={this.handleNevermindSending}
				>
					<p className="text-center" style={{ padding: '30px 0' }}>
						You will be emailing{' '}
						{this.state.isCandidate == true
							? this.state.filteredCount.toLocaleString()
							: this.state.usersCount.toLocaleString()}{' '}
						individuals.
					</p>
					<div className="modal-footer-btn">
						<Button type="link" onClick={this.handleNevermindSending}>
							Never mind. I want to change my filters.
						</Button>
						<Button
							type="primary"
							size="large"
							className="btn-min-width"
							onClick={this.handleContinueSending}
						>
							{ml(
								`Continue`,
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
						</Button>
					</div>
				</Modal>

				<Modal
					title="Are you sure you want to proceed?"
					open={this.state.visibleVerify}
					footer={null}
					onCancel={this.handleNevermindVerifying}
				>
					<p className="text-center" style={{ padding: '30px 0' }}>
						Continuing will return a count of users and a link to download the
						list of individuals that will receive your message.
					</p>
					<div className="modal-footer-btn">
						<Button type="link" onClick={this.handleNevermindVerifying}>
							Never mind. I want to change my filters.
						</Button>
						<Button
							type="primary"
							size="large"
							className="btn-min-width"
							onClick={this.handleContinueVerifying}
						>
							{ml(
								`Continue`,
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
						</Button>
					</div>
				</Modal>
			</main>
		);
	}
}

export default withApollo(Form.create()(MessageCenterComponent));
