import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Input, InputNumber, Popconfirm, message } from 'antd';
import get from 'lodash/get';
import dayjs from 'dayjs';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { configMode } from 'src/_shared/api/';
import { GetCompanyPointsData } from 'src/_shared/api/graphql/custom/company/';
import { decryptUsingAES256 } from 'src/_shared/api/settings';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import StripeModal from 'src/_shared/components/stripe/StripeModalComponent.jsx';
import { lambda, ml } from '../_shared/services/utils.js';

const stripePromise = loadStripe(
	decryptUsingAES256(
		process.env[`REACT_APP_${configMode}_STRIPEPUBLISHABLEKEY`]
	)
);
class PointsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(this.props, 'currentUser.company'),
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			enablePointsSystem: false,
			isStoreEnabled: false,
			apiKeysError: '',
			pointsSettings: '',
			success: false,
			stripeModalVisible: false,
			pointsLogVisible: false,
		};
	}

	async componentDidMount() {
		await this.getCompanyPointsData();
	}

	getCompanyPointsData = async () => {
		const { client, currentUser } = this.props;
		const { isStoreEnabled } = this.state;
		try {
			const companyId = get(currentUser, 'companyId', null);
			if (companyId !== null) {
				const { data } = await client.query({
					query: GetCompanyPointsData,
					variables: {
						id: companyId,
					},
					fetchPolicy: 'network-only',
				});
				let result = get(data, 'getCompany.pointsSettings', null);
				if (result !== null && result !== undefined) {
					result = JSON.parse(result);
					this.setState({
						pointsSettings: result,
						enablePointsSystem: get(result, 'enabled', false),
						isStoreEnabled: Boolean(result.isStoreEnabled),
					});
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	getGiftReport = () => {
		let endpoint = '';
		endpoint =
			configMode === 'DEV'
				? 'export-gifts-log-dev-sendEmail'
				: 'export-gifts-log-prod-sendEmail';
		const { companyId, cognitoId } = this.props.currentUser;
		message.success(`Export processing. You will receive an email when ready`);
		lambda({ endpoint, variables: { companyId, cognitoId } });
	};

	getMonday(d) {
		d = new Date(d);
		const day = d.getDay();
		const diff = d.getDate() - day + (day == 0 ? -6 : 1); // Adjust when day is sunday
		return new Date(d.setDate(diff));
	}

	getReport = () => {
		let endpoint = '';
		const host = window.location.hostname;
		endpoint =
			configMode === 'DEV'
				? 'export-points-log-dev-sendEmail'
				: 'export-points-log-prod-sendEmail';
		const { companyId, cognitoId } = this.props.currentUser;
		message.success(`Export processing. You will receive an email when ready`);
		lambda({ endpoint, variables: { companyId, cognitoId } });
	};

	getSunday(d) {
		d = new Date(d);
		let day = d.getDay();
		day = 7 - day;
		const diff = d.getDate() + day; // Adjust when day is sunday
		return new Date(d.setDate(diff));
	}

	download = (e) => {
		e.preventDefault();
		const a = document.body.appendChild(document.createElement('a'));
		let textToWrite = document.querySelector('#log').innerText;
		a.download = 'PointsLog.txt';
		textToWrite = textToWrite.replaceAll('\n', '%0D%0A');
		a.href = 'data:text/plain,' + textToWrite;
		a.click();
	};

	formatDate(date, dateFormat) {
		if (!date) return;
		const isoDate = new Date(date);
		const month = isoDate.getMonth() + 1;
		const day = isoDate.getDate();
		const year = isoDate.getFullYear();
		const hours = isoDate.getHours();
		const mins = ('0' + isoDate.getMinutes()).slice(-2);
		if (
			(date !== null && dateFormat === 'FR') ||
			dateFormat === 'DE' ||
			dateFormat === 'ES' ||
			dateFormat === 'RU' ||
			dateFormat === 'European'
		) {
			return `${day}/${month}/${year} ${hours}${':'}${mins}`;
		}

		return `${month}/${day}/${year} ${hours}${':'}${mins}`;
	}

	handleChange = (event_) => {
		if (event_.target.checked) {
			this.setState({
				enablePointsSystem: true,
			});
		} else {
			this.setState({
				enablePointsSystem: false,
			});
		}
	};

	handleLimit = () => {
		const { currentUser } = this.props;
		const host = window.location.hostname;
		let endpoint = '';
		endpoint =
			configMode === 'DEV'
				? 'reset-users-points-dev-app'
				: 'reset-users-points-prod-app';
		const body = {
			companyId: get(currentUser, 'companyId'),
			userId: get(currentUser, 'id'),
		};
		lambda({ endpoint, variables: body });
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		const { enablePointsSystem, isStoreEnabled, company } = this.state;
		const { onUpdateCompany, form } = this.props;


		const { errors, values } = await new Promise((resolve) => {
			form.validateFields((errors, values) => {
				resolve({ errors, values });
			});
		});

		// If antD errors, return
		if (errors) {
			return;
		}

		const account = get(values, 'account');
		const mobile = get(values, 'mobile');
		const social = get(values, 'social');
		const socialMediaMaxLimit = get(values, 'socialMediaMaxLimit');
		const generalReferral = get(values, 'generalReferral');
		const sent = get(values, 'sent');
		const accepted = get(values, 'accepted');
		const interviewing = get(values, 'interviewing');
		const hired = get(values, 'hired');
		const loginPoints = get(values, 'loginPoints');

		let pointsSettings = get(this.state, 'pointsSettings', {});
		pointsSettings = pointsSettings ? pointsSettings : {};
		if (pointsSettings) {
			pointsSettings.enabled = enablePointsSystem;
		}

		if (values.pointsRatio) {
			pointsSettings.pointsRatio = values.pointsRatio;
		}

		pointsSettings.isStoreEnabled = isStoreEnabled;
		if (account) {
			pointsSettings.createAccount = account;
		}

		if (mobile) {
			pointsSettings.useMobileApp = mobile;
		}

		if (social) {
			pointsSettings.socialMediaShare = social;
		}

		if (socialMediaMaxLimit) {
			pointsSettings.socialMediaMaxLimit = socialMediaMaxLimit;
		}

		if (generalReferral) {
			pointsSettings.generalReferral = generalReferral;
		}

		if (sent) {
			pointsSettings.referralSent = sent;
		}

		if (accepted) {
			pointsSettings.referralAccepted = accepted;
		}

		if (accepted) {
			pointsSettings.referralInterviewing = interviewing;
		}

		if (hired) {
			pointsSettings.referralHired = hired;
		}

		if (loginPoints) {
			pointsSettings.loginPoints = loginPoints;
		}

		if (values.maxRedeemable365) {
			pointsSettings.maxRedeemable365 = values.maxRedeemable365;
		}

		if (values.maxRedeemable30) {
			pointsSettings.maxRedeemable30 = values.maxRedeemable30;
		}

		this.setState(
			{
				pointsSettings,
			},
			() => {
				onUpdateCompany({
					input: {
						id: company.id,
						pointsSettings: JSON.stringify(this.state.pointsSettings),
					},
				});
			}
		);

		this.setState({
			success: true,
			apiKeysError: '',
		});
		setTimeout(() => {
			this.setState({
				success: false,
			});
		}, 3000);
		message.success('Your changes are submitted successfully.', 5);
	};

	handleViewLog = () => {
		this.setState({ pointsLogVisible: !this.state.pointsLogVisible });
	};

	sortByAlph = (a, b) => {
		if (!a) return -1;
		if (!b) return 1;
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	sortByDate(data) {
		const sortByDate = data.sort((a, b) =>
			this.sortByAlph(dayjs(b.date), dayjs(a.date))
		);
		return sortByDate;
	}

	toggleStripeModal = () => {
		this.setState({ stripeModalVisible: !this.state.stripeModalVisible });
	};

	renderLogs = () => {
		const { pointsLogData } = this.props;
		const sortedData = this.sortByDate(pointsLogData);

		let languageCode = get(this.props, 'currentUser.languageCode');
		const dateFormat = get(this.props, 'currentUser.dateFormat');
		languageCode ??= 'US';
		return (
			<div id="log">
				{sortedData.map((point, index) => (
					<p key={index}>
						{get(point, 'event') === 'reset' &&
							get(point, 'operation') === 'add' &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) + ' ' + get(point, 'note')}
						{get(point, 'event') === 'login' &&
							get(point, 'operation') === 'add' &&
							get(point, 'user.emailAddress') + ' ' + point.points !== null &&
							point.points !== undefined &&
							get(point, 'weeklyLastLogin') !== null &&
							get(point, 'weeklyLastLogin') !== undefined &&
							this.formatDate(point.date, dateFormat) +
								' ' +
								'last login in the current week is' +
								' ' +
								this.formatDate(
									get(point, 'weeklyLastLogin'),
									this.props.currentUser.dateFormat
								)}
						{get(point, 'event') === 'generalReferral' &&
							get(point, 'operation') === 'add' &&
							get(point, 'contact.emailAddress') !== null &&
							get(point, 'contact.emailAddress') !== undefined &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) +
								' ' +
								get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'earned' +
								' ' +
								point.points +
								' ' +
								'points for sending a general referral to ' +
								get(point, 'contact.firstName', '') +
								' ' +
								get(point, 'contact.lastName', '') +
								' (' +
								get(point, 'contact.emailAddress') +
								').'}
						{get(point, 'event') === 'giftCardPurchase' &&
							get(point, 'operation') === 'subtract' &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) +
								' ' +
								get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'purchased a gift card for ' +
								point.points +
								' points.'}
						{get(point, 'event') === 'referralSent' &&
							get(point, 'operation') === 'add' &&
							(get(point, 'contact.firstName') === null ||
								get(point, 'contact.firstName') === undefined) &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) +
								' ' +
								get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'earned' +
								' ' +
								point.points +
								' ' +
								'points for sending a referral.'}
						{get(point, 'event') === 'referralSent' &&
							get(point, 'operation') === 'add' &&
							get(point, 'contact.emailAddress') !== null &&
							get(point, 'contact.emailAddress') !== undefined &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) +
								' ' +
								get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'earned' +
								' ' +
								point.points +
								' ' +
								'points for sending a referral to ' +
								get(point, 'contact.firstName', '') +
								' ' +
								get(point, 'contact.lastName', '') +
								' (' +
								get(point, 'contact.emailAddress') +
								').'}
						{get(point, 'event') === 'referralSent' &&
							get(point, 'operation') === 'add' &&
							get(point, 'contact.phoneNumber') !== null &&
							get(point, 'contact.phoneNumber') !== undefined &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) +
								' ' +
								get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'earned' +
								' ' +
								point.points +
								' ' +
								'points for sending a referral to ' +
								get(point, 'contact.firstName', '') +
								' ' +
								get(point, 'contact.lastName', '') +
								' (' +
								get(point, 'contact.phoneNumber') +
								').'}
						{get(point, 'event') === 'referralAccepted' &&
							get(point, 'operation') === 'add' &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) +
								' ' +
								get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'earned' +
								' ' +
								point.points +
								' ' +
								'points for accepting a referral.'}
						{get(point, 'event') === 'referralInterviewing' &&
							get(point, 'operation') === 'add' &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) +
								' ' +
								get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'earned' +
								' ' +
								point.points +
								' ' +
								(this.state.company.referralStatus
									? `for referral ${this.state.company.referralStatus.toLowerCase()}.`
									: 'points for interviewing a referral.')}
						{get(point, 'event') === 'referralHired' &&
							get(point, 'operation') === 'add' &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) +
								' ' +
								get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'earned' +
								' ' +
								point.points +
								' ' +
								'points for hiring a referral.'}
						{get(point, 'event') === 'createAccount' &&
							get(point, 'operation') === 'add' &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) +
								' ' +
								get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'earned' +
								' ' +
								point.points +
								' ' +
								'points for creating a new account.'}
						{get(point, 'event') === 'socialMediaShare' &&
							get(point, 'operation') === 'add' &&
							point.points !== null &&
							point.points !== undefined &&
							this.formatDate(point.date) +
								' ' +
								get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'earned' +
								' ' +
								point.points +
								' ' +
								'points for social media share.'}
						{get(point, 'event') === 'byAdmin' &&
						get(point, 'operation') === 'add' &&
						point.points !== null &&
						point.points !== undefined &&
						this.formatDate(point.date) + ' ' + get(point, 'note')
							? get(point, 'adminEmail') +
								' ' +
								'added' +
								' ' +
								get(point, 'points') +
								' ' +
								'points for' +
								' ' +
								get(point, 'user.emailAddress') +
								': ' +
								get(point, 'note')
							: get(point, 'event') === 'byAdmin' &&
								  get(point, 'operation') === 'add'
								? get(point, 'adminEmail') +
									' ' +
									'added' +
									' ' +
									get(point, 'points') +
									' ' +
									'points for' +
									' ' +
									get(point, 'user.emailAddress')
								: null}
						{get(point, 'event') === 'byAdmin' &&
						get(point, 'operation') === 'subtract' &&
						point.points !== null &&
						point.points !== undefined &&
						this.formatDate(point.date) + ' ' + get(point, 'note')
							? get(point, 'adminEmail') +
								' ' +
								'subtracted' +
								' ' +
								get(point, 'points') +
								' ' +
								'points for' +
								' ' +
								get(point, 'user.emailAddress') +
								': ' +
								get(point, 'note')
							: get(point, 'event') === 'byAdmin' &&
								  get(point, 'operation') === 'subtract'
								? get(point, 'adminEmail') +
									' ' +
									'subtracted' +
									' ' +
									get(point, 'points') +
									' ' +
									'points for' +
									' ' +
									get(point, 'user.emailAddress')
								: null}
						{get(point, 'event') === 'useMobileApp' &&
						point.points !== null &&
						point.points !== undefined &&
						this.formatDate(point.date) + ' ' + get(point, 'operation') ===
							'add'
							? get(point, 'user.firstName') +
								' ' +
								get(point, 'user.lastName') +
								' (' +
								get(point, 'user.emailAddress') +
								')' +
								' ' +
								'earned' +
								' ' +
								point.points +
								' ' +
								'points for using mobile app.'
							: null}
					</p>
				))}
			</div>
		);
	};

	render() {
		const { theme, pointsSettings, enablePointsSystem, isStoreEnabled } =
			this.state;
		const {
			allMultiLingualData,
			currentUser,
			pointsLogData,
			onLoadMorePointsLogData,
			pointsLogNextToken,
		} = this.props;
		if (!get(currentUser, 'company')) return <Spinner />;
		const FormItem = Form.Item;
		const { TextArea } = Input;
		const { getFieldDecorator } = this.props.form;
		const createAccount = get(pointsSettings, 'createAccount', '');
		const useMobileApp = get(pointsSettings, 'useMobileApp', '');
		const socialMediaShare = get(pointsSettings, 'socialMediaShare', '');
		const socialMediaMaxLimit = get(pointsSettings, 'socialMediaMaxLimit', '');
		const generalReferral = get(pointsSettings, 'generalReferral', '');
		const referralSent = get(pointsSettings, 'referralSent', '');
		const referralAccepted = get(pointsSettings, 'referralAccepted', '');
		const referralInterviewing = get(
			pointsSettings,
			'referralInterviewing',
			''
		);
		const referralHired = get(pointsSettings, 'referralHired', '');
		const loginPoints = get(pointsSettings, 'loginPoints', '');
		const pointsRatio = get(pointsSettings, 'pointsRatio');
		const balance = get(currentUser, 'company.giftCardStoreBalance')
			? Number.parseFloat(
					get(currentUser, 'company.giftCardStoreBalance')
				).toFixed(2)
			: 0;
		const pendingBalance = get(
			currentUser,
			'company.giftCardStorePendingBalance'
		)
			? Number.parseFloat(
					get(currentUser, 'company.giftCardStorePendingBalance')
				).toFixed(2)
			: 0;
		const sortedData = this.sortByDate(pointsLogData);
		const pointsObject = {};

		const currentUserId = get(this.props.currentUser, 'id', null);
		for (const item of sortedData) {
			if (item.userId == currentUserId) {
				pointsObject[item.event] = pointsObject[item.event]
					? pointsObject[item.event] + item.points
					: item.points;
			}
		}

		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{ml('Points Management', currentUser, allMultiLingualData)}
					</h2>
					<ul className="info-action">
						<li>
							<Popconfirm
								title="Are you sure?"
								placement="left"
								okText="Yes"
								cancelText="No"
								onConfirm={() => this.handleLimit()}
							>
								<Button type="ghost" size="large">
									{ml(
										'Reset All Employee Points',
										currentUser,
										allMultiLingualData
									)}
								</Button>
							</Popconfirm>
						</li>
						<li>
							<Button
								type="primary"
								size="large"
								className="btn-min-width"
								onClick={this.handleSubmit}
							>
								{ml('Save', currentUser, allMultiLingualData, 'Bonuses')}
							</Button>
						</li>
					</ul>
				</div>
				<div className="">
					<div className="custom-form-group custom-checkbox left-check">
						<input
							type="checkbox"
							id="enablePoints"
							checked={enablePointsSystem}
							onChange={this.handleChange}
						/>
						<label
							htmlFor="enablePoints"
							style={{ fontWeight: 600, color: '#000' }}
						>
							{ml(
								'Enable Gamification & Points System',
								currentUser,
								allMultiLingualData
							)}
						</label>
					</div>
					{/* ABILITY FOR SUPERADMIN or dm or bestco TO ENABLE GIFT CARD STORE AND SET POINTS RATIO  */}

					{(get(this.props, 'currentUser.role') === 'superAdmin' ||
						this.props.currentUser.companyId ===
							'69575265-e654-4b0c-a2fd-26acc2b6f057' ||
						this.props.currentUser.companyId ===
							'fe9c029b-f7be-4408-8056-7eb6906eb74a') &&
						enablePointsSystem && (
							<div className="custom-form-group custom-checkbox left-check">
								<input
									type="checkbox"
									id="enableStore"
									checked={this.state.isStoreEnabled}
									onChange={() =>
										this.setState({
											isStoreEnabled: !this.state.isStoreEnabled,
										})
									}
								/>
								<label
									htmlFor="enableStore"
									style={{ fontWeight: 600, color: '#000' }}
								>
									Enable Points Gift Card Store
								</label>
							</div>
						)}
				</div>

				<div className="page-title">
					<h2 className="page-heading">
						{ml('Points Settings', currentUser, allMultiLingualData)}
					</h2>
				</div>
				<div className="bonus-points-column">
					<div className="bonus-points">
						{this.props.currentUser.company.partnerHostId == null && (
							<>
								<h4 className="bp-heading">Referral Points</h4>
								<div className="bp-divider" />
								<div className="bp-body">
									<div className="custom-form-group">
										<label>
											{ml('Use Mobile App', currentUser, allMultiLingualData)}:
											<InfoCircleOutlined title="One-time reward" />
										</label>
										{getFieldDecorator('mobile', {
											initialValue: useMobileApp,
											rules: [],
										})(<Input className="custom-input" />)}
										<span>
											{ml('points', currentUser, allMultiLingualData)}
										</span>
									</div>
									<div className="custom-form-group">
										<label>
											{ml(
												'Social Media Share',
												currentUser,
												allMultiLingualData
											)}
											:
										</label>
										{getFieldDecorator('social', {
											initialValue: socialMediaShare,
											rules: [],
										})(<Input className="custom-input" />)}
										<span>
											{ml('points', currentUser, allMultiLingualData)}
										</span>
									</div>

									<div className="custom-form-group">
										<label>
											{ml('General Referral', currentUser, allMultiLingualData)}
											:
										</label>
										{getFieldDecorator('generalReferral', {
											initialValue: generalReferral,
											rules: [],
										})(<Input className="custom-input" />)}
										<span>
											{ml('points', currentUser, allMultiLingualData)}
										</span>
									</div>
									<div className="custom-form-group">
										<label>
											{ml('Referral Sent', currentUser, allMultiLingualData)}:
										</label>
										{getFieldDecorator('sent', {
											initialValue: referralSent,
											rules: [],
										})(<Input className="custom-input" />)}
										<span>
											{ml('points', currentUser, allMultiLingualData)}
										</span>
									</div>
									<div className="custom-form-group">
										<label>
											{ml(
												'Referral Accepted',
												currentUser,
												allMultiLingualData
											)}
											:
										</label>
										{getFieldDecorator('accepted', {
											initialValue: referralAccepted,
											rules: [],
										})(<Input className="custom-input" />)}
										<span>
											{ml('points', currentUser, allMultiLingualData)}
										</span>
									</div>
									<div className="custom-form-group">
										<label>
											{ml(
												`Referral ${
													this.state.company.referralStatus ?? 'Applied'
												}`,
												currentUser,
												allMultiLingualData
											)}
											:
										</label>
										{getFieldDecorator('interviewing', {
											initialValue: referralInterviewing,
											rules: [],
										})(<Input className="custom-input" />)}
										<span>
											{ml('points', currentUser, allMultiLingualData)}
										</span>
									</div>
									<div className="custom-form-group">
										<label>
											{ml('Referral Hired', currentUser, allMultiLingualData)}:
										</label>
										{getFieldDecorator('hired', {
											initialValue: referralHired,
											rules: [],
										})(<Input className="custom-input" />)}
										<span>
											{ml('points', currentUser, allMultiLingualData)}
										</span>
									</div>
									<div className="custom-form-group">
										<label>
											{ml('Weekly Streak', currentUser, allMultiLingualData)}:
											<InfoCircleOutlined title="Set the base points for weekly logins. Each week employees will earn this value x the number of consecutive weeks they have logged in." />
										</label>
										{getFieldDecorator('loginPoints', {
											initialValue: loginPoints,
											rules: [],
										})(<Input className="custom-input" />)}
										<span>
											{ml('points', currentUser, allMultiLingualData)}
										</span>
									</div>

									{this.state.success ? (
										<Alert
											style={{ marginTop: 10 }}
											type="success"
											message={ml(
												'Your changes are submitted successfully.',
												currentUser,
												allMultiLingualData
											)}
										/>
									) : null}
								</div>
							</>
						)}
					</div>
				</div>

				<div className="bonus-points-column">
					<div className="bonus-points">
						<div className="bp-body">
							{this.props.currentUser.company.partnerHostId == null && (
								<div className="custom-form-group">
									<label>
										{ml(
											'Max Weekly Share Points',
											currentUser,
											allMultiLingualData
										)}
										:
										<InfoCircleOutlined title="Limit points from shares per employee weekly (e.g., points x allotted shares)" />
									</label>
									{getFieldDecorator('socialMediaMaxLimit', {
										initialValue: socialMediaMaxLimit,
										rules: [],
									})(<Input className="custom-input" />)}
									<span>{ml('Shares', currentUser, allMultiLingualData)}</span>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="page-title">
					<h2 className="page-heading">
						{ml('Points Store', currentUser, allMultiLingualData)}
					</h2>
				</div>
				<div className="points-card">
					<div className="row">
						<div className="col-md-6">
							<p className="company-balance">
								{ml(`Company Balance`, currentUser, allMultiLingualData)}:
								<span className="text-green"> ${balance}</span>
							</p>
							{pendingBalance > 0 && (
								<p className="company-balance">
									{ml(`Pending Balance`, currentUser, allMultiLingualData)}:
									<span className="text-green"> ${pendingBalance}</span>
								</p>
							)}
							<div style={{ marginBottom: '12px' }}>
								<Elements stripe={stripePromise}>
									<StripeModal
										{...this.props}
										visible={this.state.stripeModalVisible}
										updateCurrentUser={this.props.updateCurrentUser}
										onCancel={this.toggleStripeModal}
									/>
								</Elements>
								<Button
									type="link"
									className="add-btn btn-md"
									onClick={this.toggleStripeModal}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									Add Funds
								</Button>
							</div>
							<div className="point-to-currency">
								<p>
									Points-to-currency ratio:
									<span>e.g. 100 points: 1.00 USD</span>
								</p>
								<FormItem
									className="custom-form-group"
									style={{ width: '100px', margin: 0 }}
								>
									{getFieldDecorator('pointsRatio', {
										initialValue: pointsRatio,
										rules: [
											{
												required: isStoreEnabled,
												message: 'Required',
											},
										],
									})(
										<InputNumber
											className="custom-input"
											style={{ overflow: 'hidden' }}
										/>
									)}
								</FormItem>
								<span>
									&nbsp;&nbsp;:&nbsp;&nbsp;{`$1.00 ${currentUser.currency}`}
								</span>
							</div>
						</div>
						<div className="col-md-6">
							<div className="custom-form-group points-inline">
								<label className="custom-label">
									Max Employee Gift Card Redemption - 30 Day Period:
								</label>
								<span>$</span>
								{getFieldDecorator('maxRedeemable30', {
									initialValue: pointsSettings?.maxRedeemable30 || null,
									rules: [
										{
											required: isStoreEnabled,
											message: 'Required',
										},
									],
								})(
									<InputNumber
										placeholder="e.g. 5"
										className="custom-input"
										style={{ overflow: 'hidden', width: '100px' }}
									/>
								)}
							</div>
							<div className="custom-form-group points-inline">
								<label className="custom-label">
									Max Employee Gift Card Redemption - 365 Day Period:
								</label>
								<span>$</span>
								{getFieldDecorator('maxRedeemable365', {
									initialValue: pointsSettings?.maxRedeemable365 || null,
									rules: [
										{
											required: isStoreEnabled,
											message: 'Required',
										},
									],
								})(
									<InputNumber
										placeholder="e.g. 25"
										className="custom-input"
										style={{ overflow: 'hidden', width: '100px' }}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="point-links">
						<Button type="link" onClick={this.handleViewLog}>
							View Points Log
						</Button>
						<Button type="link" onClick={this.download}>
							{ml('Download Points Log', currentUser, allMultiLingualData)}
						</Button>
						<Button type="link" onClick={this.getReport}>
							Export points data to .CSV
						</Button>
						<Button type="link" onClick={this.getGiftReport}>
							Export gift card data to .CSV
						</Button>
					</div>
					<div
						className="row"
						style={{
							display: `${this.state.pointsLogVisible ? 'block' : 'none'}`,
						}}
					>
						<div className="col">
							<div className="log-box">{this.renderLogs()}</div>
						</div>
					</div>
				</div>
			</main>
		);
	}
}
export default withApollo(Form.create()(PointsComponent));
