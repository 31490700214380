import { lazy, useState, useEffect, useRef, Suspense } from 'react';
import { css } from 'emotion';
import ReactDOM from 'react-dom';
import { DeleteOutlined } from '@ant-design/icons';
import {
	Alert,
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	Modal,
	Row,
	Select,
} from 'antd';
import LanguageSelect from 'src/_shared/components/language/LanguageSelectComponent.jsx';
import PhoneInput from 'react-phone-input-2';
import uuid from 'uuid/v4';
import { COLORS, WebIcon } from 'src/_shared';
import { ReactComponent as ExpandArrowsIcon } from 'src/new-referral-landing-page/expand-arrows.svg';
import { StringParserAlt } from 'src/_shared/services';
import { filterPhoneNumber, lambda } from 'src/_shared/services/utils';
import _, { upperFirst } from 'lodash';
import FormElements from 'src/form-builder/FormElements.jsx';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent';
import ErinLogo from '../_shared/assets/erinwhite.png';
import defaultStyles from './newReferralLandingStyles.js';
import { TranslationProvider, useTranslation } from './TranslationContext.js';

const DevModeButton = lazy(() => import('./DevModeButton.jsx'));

const mergeStyles = (defaultStyles, customStyles) => {
	const mergedStyles = { ...defaultStyles };
	for (const key in customStyles) {
		if (customStyles.hasOwnProperty(key)) {
			mergedStyles[key] = css({
				...defaultStyles[key]?.styles,
				...customStyles[key],
			});
		}
	}

	return mergedStyles;
};

const ErrorPage = ({ paramsData = {}, urlError, error, styles }) => {
	let paramsCount = 0;
	let paramsString = '';
	if (Object.keys(paramsData).length === 0) {
		for (const [key, value] of Object.entries(paramsData)) {
			paramsCount++;
			paramsString += `Parameter ${paramsCount}, ${key}: ${value} `;
		}
	}

	return (
		<div className={'error-wrapper ' + styles.ErrorWrapper}>
			<div className={'error-container text-center ' + styles.ErrorContainer}>
				{urlError ? (
					<h1 className={styles.ErrorHeading}>
						Woops! The referral link may be expired or incorrect.
					</h1>
				) : (
					<h1 className={styles.ErrorHeading}>
						Woops! Something went wrong fetching data!
					</h1>
				)}
				<p className={styles.ErrorReport}>{error.message}</p>
				<p className={styles.ErrorText} style={{ fontSize: '1rem' }}>
					Verify the address bar matches the link you received or try reaching
					out to the person who shared it. Please contact support if the issue
					persists.
				</p>
			</div>
		</div>
	);
};

const DataFetcher = ({
	pageType,
	paramsData,
	urlError,
	er18n,
	setLanguage,
	children,
}) => {
	const [error, setError] = useState(null);
	const [styles, setStyles] = useState(defaultStyles);
	const [multiLingualData, setMultiLingualData] = useState([]);
	const [pageData, setPageData] = useState(null);
	const [referralQuestions, setReferralQuestions] = useState([]);
	const [languageCode, setLanguageCode] = useState(paramsData.languageCode);
	const [languageUpdated, setLanguageUpdated] = useState(false);
	const [filename, setFilename] = useState('');
	const [uniqueId, setUniqueId] = useState(uuid());
	const [awaitingResult, setAwaitingResult] = useState(false);
	const [submittedInfo, setSubmittedInfo] = useState({});
	const [redirectLink, setRedirectLink] = useState(null);
	const [referralResult, setReferralResult] = useState(false);
	const [filetype, setFiletype] = useState(null);
	const [originalFile, setOriginalFile] = useState(null);
	const [encodedFile, setEncodedFile] = useState(null);
	const [countryCode, setCountryCode] = useState('us');
	const [currentErrors, setCurrentErrors] = useState([]);
	const uploadInputRef = useRef(null);
	const enableCandidateBonus = false;
	const isResumeOptional = true;
	const isResumeRequired = true;

	// START FORM BUILDER
	const _getItemValue = (item, ref) => {
		let $item = {
			element: item.element,
			value: '',
			text: '',
		};
		switch (item.element) {
			case 'Rating': {
				$item.value = ref.inputField.current.state.rating;
				break;
			}

			case 'Tags': {
				$item.value = ref.inputField.current.state.value;
				break;
			}

			case 'DatePicker': {
				$item.value = ref.state.value;
				break;
			}

			case 'Camera': {
				$item.value = ref.state.img
					? ref.state.img.replace('data:image/png;base64,', '')
					: '';
				break;
			}

			default: {
				if (ref && ref.inputField) {
					$item = ReactDOM.findDOMNode(ref.inputField.current);
					if (typeof $item.value === 'string') {
						$item.value = $item.value.trim();
						if (item.element === 'Dropdown') {
							$item.text = $item.selectedOptions[0].innerText;
						}
					}
				}
			}
		}

		return $item;
	};

	const getSimpleElement = (item) => {
		const Element = FormElements[item.element];
		return <Element key={`form_${item.id}`} mutable data={item} />;
	};

	const renderInput = (item) => (
		<Form.Item
			key={item.id}
			name={item.field_name}
			label={item.label}
			rules={[{ required: true, message: 'This field is required' }]}
			className={styles.TextInput}
		>
			<Input style={{ height: '40px', borderRadius: '8px' }} />
		</Form.Item>
	);

	const renderTextArea = (item) => (
		<Form.Item
			key={item.id}
			name={item.field_name}
			label={item.label}
			rules={[{ required: true, message: 'This field is required' }]}
			className={styles.TextArea}
		>
			<Input.TextArea style={{ borderRadius: '8px' }} />
		</Form.Item>
	);

	const renderDropdown = (item) => (
		<Form.Item
			key={item.id}
			name={item.field_name}
			label={item.label}
			rules={[{ required: true, message: 'This field is required' }]}
		>
			<Select className={styles.DropDownSelect}>
				{item.options.map((option) => (
					<Select.Option key={option.key} value={option.value}>
						{option.text}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);

	// END FORM BUILDER

	const data_items = referralQuestions;
	const customItems = data_items.map((item) => {
		if (!item) return null;
		switch (item.element) {
			case 'TextInput': {
				return renderInput(item);
			}

			case 'TextArea': {
				return renderTextArea(item);
			}

			case 'Dropdown': {
				return renderDropdown(item);
			}

			default: {
				return getSimpleElement(item);
			}
		}
	});

	const onFileInputChange = (e) => {
		const file = e.target.files[0];
		const errors = [];
		if (file) {
			const isDocOrDocsOrPdf =
				file.type === 'application/msword' ||
				file.type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				file.type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				errors.push('You can only upload doc, docx, pdf files!');
			}

			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must be smaller than 2MB!');
			}

			const reader = new FileReader();
			reader.onloadend = () => {
				setEncodedFile(reader.result);
			};

			reader.readAsDataURL(file);
		}

		setFilename(file.name);
		setFiletype(file.type);
		setOriginalFile(file);
		setCurrentErrors([...errors]);
	};

	const handleUpdateStatus = (formData) => {
		// to do, call api for accept or decline
		setSubmittedInfo(formData);
		setAwaitingResult(true);
	};

	const onDelete = () => {
		setFilename(null);
		setFiletype(null);
		setOriginalFile([]);
		if (uploadInputRef.current) {
			uploadInputRef.current.value = null;
		}

		setCurrentErrors([]);
	};

	const AcceptDeclineButtons = ({ acceptText, declineText }) => {
		const isError = currentErrors?.length > 0;

		return (
			<div>
				{isError && (
					<div
						className={styles.ErrorAlert}
						style={{ margin: '6 auto', width: '55%' }}
					>
						<Alert message={currentErrors[0]} type="error" />
					</div>
				)}
				<div className={styles.ButtonSpacing}>
					<Button className={styles.ApplyButton} size="large" htmlType="submit">
						<div
							style={{
								display: 'flex',
								margin: 'auto',
								textAlign: 'center',
								fontSize: '16px',
								fontWeight: 600,
							}}
						>
							<div style={{ paddingTop: 8 }}>
								<WebIcon color="white" name="checkmark" size={30} />
							</div>
							<div style={{ paddingTop: 6 }}>{acceptText}</div>
						</div>
					</Button>

					{ declineText && (
						<div>
							<Button
								className={styles.DeclineButton}
								size="large"
								style={{ marginTop: 10 }}
								onClick={() =>
									handleUpdateStatus({ referralResponse: 'declined' })
								}
							>
								<div
									style={{
										display: 'flex',
										margin: 'auto',
										textAlign: 'center',
										fontSize: '16px',
										fontWeight: 600,
									}}
								>
									<div className={styles.CancelIconContainer}>
										<WebIcon name="plus-symbol" size={18} />
									</div>
									<div>{declineText}</div>
								</div>
							</Button>
						</div>
					)}
				</div>
			</div>
		);
	};

	const ApplyExternalButton = ({ viewApplyText, publicLink }) => {
		if (!publicLink) {
			return null;
		}

		return (
			<Button
				className={styles.ApplyButton}
				size="large"
				href={publicLink}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div
					style={{
						display: 'flex',
						margin: 'auto',
						textAlign: 'center',
						alignItems: 'center',
						lineHeight: '38px',
						paddingTop: 6,
						verticalAlign: 'middle',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							textAlign: 'center',
							verticalAlign: 'middle',
						}}
					>
						<ExpandArrowsIcon
							style={{ height: 20, width: 20, marginRight: 6, fill: 'white' }}
						/>
						<p style={{ marginBottom: 0, fontWeight: 600, color: 'white' }}>
							{viewApplyText}
						</p>
					</div>
				</div>
			</Button>
		);
	};

	const ActionButtons = ({
		viewApplyText,
		byEmailText,
		publicLink,
		recruiterEmail,
		hideRecruiterInfo,
		jobUrl,
	}) => {
		if (!jobUrl && hideRecruiterInfo) {
			return null;
		}

		return (
			<div className={styles.ButtonSpacing}>
				<ApplyExternalButton
					viewApplyText={viewApplyText}
					publicLink={publicLink}
				/>
				{!hideRecruiterInfo && recruiterEmail && (
					<a
						className={styles.ByEmailText}
						href={`mailto:${recruiterEmail}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<span
							style={{
								margin: 'auto',
								textAlign: 'center',
								lineHeight: '38px',
								paddingTop: 6,
								fontSize: 16,
							}}
						>
							{byEmailText}
						</span>
					</a>
				)}
			</div>
		);
	};

	const RenderReferralStatus = ({
		statusOfReferral,
		acceptedText,
		declinedText,
	}) => {
		if (!statusOfReferral || statusOfReferral === 'referred') {
			return null;
		}

		return (
			<div className={styles.ReferralStatusWrap}>
				<Row
					className={styles.ReferralStatusRow}
					style={{ justifyContent: 'center' }}
				>
					<h2
						className={styles.ReferralStatusText}
						style={{ color: COLORS.green }}
					>
						{statusOfReferral === 'accepted' ? acceptedText : declinedText}
					</h2>
				</Row>
			</div>
		);
	};

	const handleCountryChange = (country) => {
		setCountryCode((country.countryCode || 'US').toLowerCase());
	};

	const onFinish = async (values) => {
		const { firstName, emailAddress, phoneNumber, lastName } = values;
		try {
			const collectCustomItems = referralQuestions.map((item) => {
				if (item.element === 'Label' || item.element === 'LineBreak') return;

				const fieldValue = values[item.field_name];
				let additionalData = {
					name: item.field_name,
					question: item.label,
					value: fieldValue,
				};

				if (item.element === 'Dropdown') {
					const selectedOption = item.options.find(
						(option) => option.value === fieldValue
					);
					additionalData = {
						...additionalData,
						text: selectedOption ? selectedOption.text : '',
					};
				} else {
					additionalData = {
						...additionalData,
						text: '',
					};
				}

				return additionalData;
			}).filter(Boolean);

			if (pageData.isResumeRequired && !filename) {
				const resumeError = 'Missing required form field: Resume';
				setCurrentErrors([resumeError]);
				return;
			}

			const formData = {
				firstName,
				lastName,
				emailAddress,
				phoneNumber,
				questionsData: collectCustomItems,
				resumeData: encodedFile,
				filename,
				referralResponse: 'accepted',
				submittingReferral: true,
			};
			handleUpdateStatus(formData);
		} catch (error) {
			console.log(error);
			setAwaitingResult(false);
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	useEffect(() => {
		let isMounted = true;
		const fetchDataAsync = async () => {
			try {
				if (!pageData && isMounted) {
					const response = await lambda(
						{
							endpoint: 'AcceptancePageAPI',
							variables: {
								...paramsData,
							},
						},
						'apiKey'
					);
					if (response?.data) {
						const initialPageData = { ...response.data };
						setPageData(initialPageData);
						if (initialPageData?.customStyles) {
							const mergedStyles = mergeStyles(
								defaultStyles,
								initialPageData.customStyles
							);
							setStyles(mergedStyles);
						}

						setReferralQuestions(
							_.sortBy(response.data.referralQuestions, ['sortOrder'])
						);
					} else {
						setError('Error Fetching Page Data');
					}
				} else if (pageData && isMounted) {
					if (languageUpdated) {
						const response = await lambda(
							{
								endpoint: 'AcceptancePageAPI',
								variables: {
									pageType,
									...paramsData,
									languageCode,
									translateOnly: true,
								},
							},
							'apiKey'
						);
						if (response?.data) {
							const newPageData = { ...response.data };

							newPageData.jobTitle &&= decodeURIComponent(newPageData.jobTitle);

							newPageData.jobOverview &&= decodeURIComponent(
								newPageData.jobOverview
							);

							setPageData({
								...pageData,
								...newPageData,
							});
							setLanguageUpdated(false);
						} else {
							console.log('Error retrieving translation data');
						}
					} else if (awaitingResult && isMounted) {
						const response = await lambda(
							{
								endpoint: 'AcceptancePageAPI',
								variables: {
									pageType,
									...paramsData,
									languageCode,
									...submittedInfo,
								},
							},
							'apiKey'
						);
						if (response?.data) {
							setAwaitingResult(false);
							if (response.data.submissionResult === 'success') {
								setReferralResult(true);
							} else {
								setCurrentErrors([response.data.errorMessage]);
							}

							setPageData({
								...pageData,
								...response?.data,
							});
							if (response?.data?.redirectLink) {
								setRedirectLink(response.data.redirectLink);
							}
						} else {
							console.log('Error submitting referral');
							setAwaitingResult(false);
							setReferralResult(false);
						}
					} else if (redirectLink && isMounted) {
						window.open(redirectLink, '_blank');
					}
				}
			} catch (error_) {
				if (isMounted) {
					setError(error_);
				}
			}
		};

		fetchDataAsync();

		return () => {
			isMounted = false;
		};
	}, [languageUpdated, awaitingResult, redirectLink]);

	useEffect(() => {
		setLanguage(languageCode);

		return () => {
			setLanguageUpdated(true);
		};
	}, [languageCode]);

	if (urlError || error)
		return (
			<div>
				<ErrorPage
					paramsData={paramsData}
					urlError={urlError}
					error={error}
					styles={styles}
				/>
			</div>
		);
	if (!pageData || !multiLingualData) return <Spinner forceDefault />;
	const hideSalary = pageData.salary === '$null ';

	const endDate = pageData?.endDate || null;

	return (
		<TranslationProvider
			initialLanguage={pageData.languageCode}
			initialTranslations={pageData.multiLingualData}
		>
			{children({
				pageData,
				setPageData,
				urlError,
				customItems,
				onFinish,
				onFinishFailed,
				filename,
				setFilename,
				uniqueId,
				onFileInputChange,
				onDelete,
				handleUpdateStatus,
				AcceptDeclineButtons,
				ApplyExternalButton,
				ActionButtons,
				RenderReferralStatus,
				handleCountryChange,
				uploadInputRef,
				awaitingResult,
				setAwaitingResult,
				setFiletype,
				setEncodedFile,
				setOriginalFile,
				enableCandidateBonus,
				isResumeOptional,
				isResumeRequired,
				setCurrentErrors,
				countryCode,
				setCountryCode,
				styles,
				hideSalary,
				endDate,
				setLanguageCode,
				languageCode,
				renderTranslation: er18n,
			})}
		</TranslationProvider>
	);
};

const NewReferralLandingPageComponent = ({
	pageType,
	devMode = false,
	props,
}) => {
	const { match } = props;
	let langFromPath = match?.params?.langCode || 'US';
	if (langFromPath.toLowerCase() === 'en') {
		langFromPath = 'US';
	}

	const [urlError, setUrlError] = useState(false);
	const [paramsData, setParamsData] = useState({
		pageType,
		languageCode: langFromPath,
		...match.params,
	});
	const [currentErrors, setCurrentErrors] = useState([]);
	const { er18n, setLanguage } = useTranslation();

	if (urlError) {
		console.log('URL ERROR');
		return <Spinner forceDefault />;
	}

	return (
		<Suspense fallback={<Spinner forceDefault />}>
			<DataFetcher
				urlError={urlError}
				pageType={pageType}
				paramsData={paramsData}
				setLanguage={setLanguage}
			>
				{({
					pageData,
					setPageData,
					urlError,
					customItems,
					onFinish,
					onFinishFailed,
					filename,
					setFilename,
					uniqueId,
					onFileInputChange,
					onDelete,
					AcceptDeclineButtons,
					ActionButtons,
					RenderReferralStatus,
					handleCountryChange,
					uploadInputRef,
					setFiletype,
					setEncodedFile,
					setOriginalFile,
					awaitingResult,
					styles,
					hideSalary,
					endDate,
					languageCode,
					setLanguageCode,
				}) => (
					<div className={styles.FullWrapper}>
						{urlError ? (
							<ErrorPage
								urlError={urlError}
								error={error}
								styles={styles}
								paramsData={paramsData}
							/>
						) : (
							<section
								className={'landing-background ' + styles.LandingBackground}
							>
								{devMode ? (
									<DevModeButton
										currentVariables={pageData}
										setCurrentVariables={setPageData}
									/>
								) : null}
								<div
									className={styles.HeaderBackground}
									style={{
										backgroundColor: pageData.whiteLabel
											? pageData.brandColor
											: COLORS.darkBlue,
										backgroundImage: pageData.whiteLabel
											? `linear-gradient(45deg, ${pageData.brandColor} 0%, ${pageData.brandColorShaded || pageData.brandColor} 99%, ${pageData.brandColorShaded || pageData.brandColor} 100%)`
											: `linear-gradient(45deg, ${COLORS.darkBlue} 0%, #202A3A 99%, #202A3A 100%)`,
									}}
								>
									<Modal
										centered
										title={er18n('Submitting Response...')}
										bodyStyle={pageData.modalBodyStyle}
										maskStyle={pageData.modalMaskStyle}
										style={pageData.modalStyle}
										open={awaitingResult}
										closable={false}
										header={null}
										footer={null}
										width={400}
									>
										<div
											className={styles.ModalContainer}
											style={{ height: 150, width: 300 }}
										>
											<Spinner forceDefault />
										</div>
									</Modal>
									<Row
										className={styles.HeadingRow}
										type="flex"
										justify="center"
									>
										<Col
											className={'heading-col ' + styles.LanguageSelectColumn}
											xs={24}
											style={{ zIndex: 30, alignItems: 'flex-end' }}
										>
											<div
												className={styles.LanguageSelectWrapper}
												style={{
													display: 'flex',
													justifyContent: 'flex-end',
													marginBottom: 10,
												}}
											>
												<LanguageSelect
													topRight
													referralLanguage
													languageCode={languageCode}
													onSelect={setLanguageCode}
												/>
												<br />
											</div>
										</Col>
										<Col
											className={'heading-col ' + styles.CompanyLogoColumn}
											xs={24}
											style={{
												marginTop: '-38px',
												width: '100%',
												alignItems: 'center',
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											{pageData.companyLogoSrc ? (
												<div className={styles.LogoContainer}>
													<img
														className={styles.CompanyLogo}
														src={pageData.companyLogoSrc}
														alt="company logo"
													/>
												</div>
											) : (
												<div className={styles.LogoContainer}>
													<img
														className={styles.CompanyLogo}
														src={ErinLogo}
														alt="Erin Logo"
													/>
												</div>
											)}

											<div
												className={styles.OverlapSpacer}
												style={{ height: '134px' }}
											/>
										</Col>
									</Row>
								</div>
								<div className={styles.OuterCardContainer}>
									<div className={styles.CardContainer}>
										<Card
											headStyle={{ height: 100 }}
											className={styles.CardStyles}
											bordered={false}
											style={
												pageData.whiteLabel
													? { borderRadius: 16 }
													: {
															borderTopLeftRadius: 16,
															borderTopRightRadius: 16,
															borderBottomLeftRadius: 0,
															borderBottomRightRadius: 0,
														}
											}
										>
											<div className={styles.Title} style={{ marginTop: 10 }}>
												{er18n("You've been referred for a position at")}{' '}
												{pageData.companyName || ''}
											</div>
											<div
												className={styles.ReferrerWrapper}
												style={{
													textAlign: 'center',
													width: '100%',
												}}
											>
												<Row
													type="flex"
													justify="center"
													className={styles.ReferrerRow}
												>
													<Col xs={23} m={24} className={styles.ReferrerColumn}>
														{pageData.referrer && (
															<p
																className={styles.ReferrerText}
																style={{ marginBottom: 0, fontSize: '16px' }}
															>
																{er18n('Referred By')}:{' '}
																<a
																	href={pageData.referrerEmail}
																	className={styles.ReferrerName}
																>
																	{pageData.referrer}
																</a>
															</p>
														)}
													</Col>
												</Row>
											</div>
											<Divider />
											<div className={styles.JobInfoContainer}>
												<div
													className={styles.JobInfoItems}
													style={{ display: 'inline-block', width: '100%' }}
												>
													{pageData.jobTitle ? (
														<h1 className={styles.JobTitleStyles}>
															{pageData.jobTitle}
														</h1>
													) : null}
													<div className={styles.DetailsTitle}>
														{pageData.department ? (
															<>
																<WebIcon name="folder" size={16} />
																<span className={styles.DepartmentText}>
																	{pageData.department}
																</span>
															</>
														) : null}
														{pageData.location ? (
															<>
																<WebIcon name="placeholder" size={16} />
																<span className={styles.LocationText}>
																	{pageData.location === 'Remote'
																		? er18n('Remote')
																		: pageData.location}
																</span>
															</>
														) : null}
													</div>
												</div>
											</div>
											<div className={styles.TopSpacing}>
												{pageData.enableCandidateBonus ? (
													<Row className={styles.RetentionBonusRow}>
														<span>
															<b>{er18n('Retention Bonus')}: </b>
														</span>
														{/* items */}
													</Row>
												) : null}
												{pageData.jobType ? (
													<Row className={styles.JobTypeRow}>
														<span>
															<b>{er18n('Job Type')}: </b>
														</span>
														<span>
															{pageData.jobType === 'fulltime'
																? er18n('Full-Time')
																: pageData.jobType === 'parttime'
																	? er18n('Part-Time')
																	: upperFirst(pageData.jobType)}
														</span>
													</Row>
												) : null}
												{pageData.salary &&
												!hideSalary &&
												!pageData.salary.includes('N/A') ? (
													<Row className={styles.SalaryRow}>
														<span>
															<b>{er18n('Salary Range')}: </b>
														</span>
														<span>{pageData.salary}</span>
													</Row>
												) : null}
												{endDate && (
													<Row className={styles.SalaryRow}>
														<span>
															<b>{er18n('End Date')}: </b>
														</span>
														<span>
															{new Intl.DateTimeFormat('US').format(
																new Date(endDate)
															)}
														</span>
													</Row>
												)}
											</div>

											<Row
												className={styles.JobOverviewWrapper}
												style={{
													marginBottom: '10px',
													marginTop: '10px',
													backgroundColor: '#ffffff',
												}}
											>
												<span className={styles.SectionTitle}>
													<b>{er18n('Job Responsibilities')}: </b>
												</span>
												<span className={styles.SummaryValue}>
													<StringParserAlt
														showMoreText={er18n('Show More')}
														showLessText={er18n('Show Less')}
														text={
															(pageData.translatedDescription ??
															pageData.jobOverview)
																? pageData.jobOverview.toString('html')
																: ''
														}
													/>
												</span>
											</Row>
											<RenderReferralStatus
												statusOfReferral={pageData?.status}
												declinedText={er18n('Thanks for letting us know!')}
												acceptedText={er18n('Referral Accepted')}
											/>
											{pageData.jobStatus === 'closed' ? (
												pageData.isGeneralReferral ||
												pageData.status === 'accepted' ? null : (
													<div
														className={
															'text-3xl pt-4 text-red-500 text-center mx-auto min-w-[280px] max-w-[50%] ' +
															styles.ClosedJobText
														}
													>
														{er18n(
															'Thank you for your interest, but this job is no longer accepting new applications.'
														)}
													</div>
												)
											) : (
												<Form
													initialValues={{
														firstName: pageData.contactFirstName || '',
														lastName: pageData.contactLastName || '',
														emailAddress:
															pageData.contactConfirmedEmail ||
															pageData?.contactEmailAddress ||
															'',
														phoneNumber: '',
													}}
													onFinish={onFinish}
													onFinishFailed={onFinishFailed}
												>
													{pageData.status === 'referred' && (
														<div className={styles.FormWrapper}>
															<div className={styles.ContainerDiv}>
																<p className={styles.NoteStyle}>
																	{er18n(
																		'To accept your referral and apply to the position, we need more information.'
																	)}
																</p>
															</div>
															<div className={styles.DetailContainer}>
																<Row
																	className={styles.FormItemRow}
																	type="flex"
																	justify="space-between"
																	gutter={12}
																>
																	<Col
																		className={
																			'form-item-col ' + styles.FormItemColumn
																		}
																		span={12}
																	>
																		<Form.Item
																			className={styles.FormItemStyles}
																			name="firstName"
																			rules={[
																				{
																					transform: (value) =>
																						value == undefined
																							? value
																							: value.trim(),
																				},
																				{
																					required: true,
																					message: er18n(
																						'Please enter your first name.'
																					),
																				},
																			]}
																		>
																			<Input
																				className={
																					'custom-input ' +
																					styles.RemoveBottomMargin
																				}
																				placeholder={er18n('First Name')}
																			/>
																		</Form.Item>
																	</Col>
																	<Col
																		className={
																			'form-item-col ' + styles.FormItemColumn
																		}
																		span={12}
																	>
																		<Form.Item
																			className={styles.FormItemStyles}
																			name="lastName"
																			rules={[
																				{
																					transform: (value) =>
																						value == undefined
																							? value
																							: value.trim(),
																				},
																				{
																					required: true,
																					message: er18n(
																						'Please enter your last name.'
																					),
																				},
																			]}
																		>
																			<Input
																				className={styles.RemoveBottomMargin}
																				placeholder={er18n('Last Name')}
																			/>
																		</Form.Item>
																	</Col>
																	<Col
																		className={
																			'form-item-col ' + styles.FormItemColumn
																		}
																		span={24}
																	>
																		<Form.Item
																			className={styles.FormItemStyles}
																			name="emailAddress"
																			validateTrigger={['onBlur']}
																			rules={[
																				{
																					type: 'email',
																					message: er18n(
																						'Enter a valid email.'
																					),
																				},
																				{
																					required: true,
																					message: er18n(
																						'Please enter an email address.'
																					),
																				},
																			]}
																		>
																			<Input
																				className={styles.RemoveBottomMargin}
																				placeholder={er18n('Email')}
																			/>
																		</Form.Item>
																	</Col>
																</Row>
																{pageData.isPhoneNumberEnabled && (
																	<Row className={styles.FormItemRow}>
																		<Col
																			className={
																				'form-item-col ' + styles.FormItemColumn
																			}
																			span={24}
																		>
																			<Form.Item
																				className={styles.FormItemStyles}
																				name="phoneNumber"
																				rules={[
																					{
																						validator(_rule, value) {
																							return new Promise(
																								(resolve, reject) => {
																									if (!value) {
																										return reject(
																											er18n(
																												'Please enter a phone number.'
																											)
																										);
																									}

																									if (
																										!/^\+?\(?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,7}$/im.test(
																											filterPhoneNumber(value)
																										)
																									) {
																										return reject(
																											er18n(
																												'Please enter a valid phone number.'
																											)
																										);
																									}

																									resolve();
																								}
																							);
																						},
																					},
																				]}
																			>
																				<PhoneInput
																					disableCountryGuess
																					inputStyle={{
																						border: '1px solid',
																						borderRadius: 8,
																						borderColor:
																							'rgba(217, 217, 217, 0.95)',
																						color: 'rgba(0, 0, 0, 0.65)',
																						font: '400 14px system-ui',
																						padding: '0px 0px 0px 50px',
																						height: 40,
																						width: '100%',
																					}}
																					dropdownStyle={{
																						lineHeight: 1,
																					}}
																					country={pageData.countryCode || 'US'}
																					masks={{ cn: '... .... ....' }}
																					onChange={(country) => {
																						handleCountryChange(country);
																					}}
																				/>
																			</Form.Item>
																		</Col>
																	</Row>
																)}

																<div
																	style={{
																		marginTop: '10px',
																		fontWeight: 'bolder',
																	}}
																	className={styles.ResumeWrapper}
																>
																	{pageData.isResumeOptional ? (
																		<div
																			className={
																				'App ' + styles.UploadContainer
																			}
																			style={{ marginBottom: '10px' }}
																		>
																			<span className={styles.FileWrap}>
																				<label htmlFor={'file-' + uniqueId}>
																					{er18n('Click here')}{' '}
																				</label>
																				<input
																					ref={uploadInputRef}
																					hidden
																					type="file"
																					accept=".doc,.docx,application/msword,.pdf,application/pdf"
																					id={'file-' + uniqueId}
																					name="file"
																					onClick={(e) => {
																						setFilename(null);
																						setFiletype(null);
																						setOriginalFile([]);
																						setEncodedFile([]);
																						uploadInputRef.current = null;
																						e.target.value = null;
																					}}
																					onChange={onFileInputChange}
																				/>
																			</span>{' '}
																			{er18n('to attach a resume')}
																			<span style={{ fontWeight: 'normal' }}>
																				{' (.doc, .docx, .pdf) '}
																			</span>
																			<span style={{ color: 'red' }}>
																				{pageData.isResumeRequired
																					? er18n('(required)')
																					: ''}
																			</span>
																			<span className="label-optional">
																				{pageData.isResumeRequired
																					? ''
																					: er18n('(optional)')}
																			</span>{' '}
																			{filename && (
																				<p>
																					{filename}
																					<DeleteOutlined
																						style={{
																							margin: '0px 0px 4px 10px',
																							color: '#ef3c3e',
																						}}
																						onClick={onDelete}
																					/>
																				</p>
																			)}
																			{currentErrors && currentErrors.length > 0
																				? currentErrors.map((currentError) => (
																						<p
																							key={`error-${uuid()}`}
																							className={
																								'text-danger text-center ' +
																								styles.CurrentError
																							}
																						>
																							{currentError}
																						</p>
																					))
																				: null}
																		</div>
																	) : null}
																</div>

																{customItems ? (
																	<div
																		style={{ marginTop: 10 }}
																		className={styles.FormBuilderItems}
																	>
																		{customItems}
																	</div>
																) : null}
															</div>

															<div
																style={{ height: 'auto', marginBottom: '30px' }}
																className={styles.PolicyWrapper}
															>
																<p className={styles.InfoStyle}>
																	{er18n(
																		'By submitting, you consent to ERIN storing, processing and sharing your name and contact information with'
																	)}{' '}
																	{er18n(
																		'hiring team. Your data will never be sold or redistributed by ERIN. Please read our'
																	)}{' '}
																	<a
																		className={styles.MenuColor}
																		href="https://erinapp.com/privacy-policy"
																		target="_blank"
																		rel="noreferrer"
																	>
																		{er18n('Privacy Policy')}
																	</a>{' '}
																	{er18n('for more information.')}
																</p>
															</div>
														</div>
													)}
													<div
														className={styles.FormButtonsWrapper}
														style={{ textAlign: 'center' }}
													>
														{pageData.status === 'referred' ? (
															<AcceptDeclineButtons
																acceptText={er18n('Accept & Apply')}
																declineText={pageType !== 'public'
																	? er18n('Decline Referral')
																	: null
															  }
																errorText={er18n(
																	'Please check all the required fields'
																)}
															/>
														) : pageData.status === 'declined' ||
														  pageData.status === 'notHired' ? (
															''
														) : pageData.status === 'accepted' ? (
															<ActionButtons
																viewApplyText={er18n('View Job and Apply')}
																byEmailText={er18n('Contact by Email')}
																recruiterEmail={pageData?.recruiterEmail || ''}
																hideRecruiterInfo={pageData?.hideRecruiterInfo}
																jobUrl={pageData?.jobUrl}
																publicLink={pageData?.publicLink}
															/>
														) : (
															''
														)}
													</div>
												</Form>
											)}
										</Card>
									</div>
								</div>
								{pageData.whiteLabel ? (
									<div
										className={styles.whiteLabelFooter}
										style={{ height: 0, backgroundColor: '#f5f5f5' }}
									/>
								) : (
									<div className={styles.FooterBackground}>
										<p
											className={styles.FooterText}
											style={{ color: '#e6e6e6' }}
										>
											{er18n('powered by')}
										</p>
										<a
											className={styles.FooterLink}
											href="https://erinapp.com/"
										>
											<img
												className={styles.FooterLogo}
												alt="logo"
												style={{ width: 200 }}
												src={ErinLogo}
											/>
										</a>
									</div>
								)}
							</section>
						)}
					</div>
				)}
			</DataFetcher>
		</Suspense>
	);
};

export default NewReferralLandingPageComponent;
