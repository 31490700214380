import { Col, Typography, Tooltip, Popconfirm, message } from 'antd';
import { Link } from 'react-router-dom';
import { CopyOutlined, RetweetOutlined } from '@ant-design/icons';
import OwnershipPeriodTooltip from 'src/_shared/components/referral-card/OwnershipPeriodTooltip';
import { ml } from 'src/_shared/services/utils';

const { Paragraph } = Typography;

const getReferralIcon = (referralType) => {
	const referralIconMap = {
		email: <i className="icon-envelope-outline" style={{ fontSize: 18 }} />,
		text: <i className="icon-message-outline" style={{ fontSize: 18 }} />,
		share: <i className="icon-share" style={{ fontSize: 18 }} />,
	};
	return referralIconMap[referralType] || null;
};

const ReferralContact = ({
	allMultiLingualData,
	contact,
	referralDate,
	referralType,
	ownershipSettings,
	handleResendReferral,
	status,
	referral,
	currentUser,
}) => {
	const hideCopyReferral = currentUser?.company?.hideCopyReferral || null;

	return (
		<Col className="referral-contact">
			<Paragraph className="referral-contact-paragraph">
				{referral?.type === 'gdprReferralCreated' && status === 'referred' ? (
					<strong>{ml('Pending Acceptance', currentUser, allMultiLingualData)}</strong>
				) : (
					<Link to={`/mycontacts/${contact?.id}`}>
						<strong>
							{contact?.firstName} {contact?.lastName}
						</strong>
					</Link>
				)}
			</Paragraph>

			<div className="referral-date-wrap">
				{getReferralIcon(referralType)}
				<Tooltip
					placement="top"
					title={`${ml(`Referred ${referralType ? 'by ' + referralType : ''}`, currentUser, allMultiLingualData)}: ${referralDate}`}
				>
					<span className="referral-date">{referralDate}</span>
				</Tooltip>
				<OwnershipPeriodTooltip ownershipSettings={ownershipSettings} currentUser={currentUser}/>

				{status === 'referred' && (
					<Popconfirm
						title={ml("Resend referral?", currentUser, allMultiLingualData)}
						onConfirm={handleResendReferral}
						okText={ml("Yes", currentUser, allMultiLingualData)}
						cancelText={ml("No", currentUser, allMultiLingualData)}
					>
						{referral?.type !== 'gdprReferralCreated' && (
							<Tooltip placement="top" title={ml("Resend referral", currentUser, allMultiLingualData)}>
								<RetweetOutlined className="resend-icon" />
							</Tooltip>
						)}
					</Popconfirm>
				)}
				{!hideCopyReferral && (
					<Tooltip placement="top" title={ml("Copy Referral Links", currentUser, allMultiLingualData)}>
						<CopyOutlined
							style={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.50)' }}
							className="copy-icon"
							onClick={() => {
								const { host } = window.location;
								const https = host === 'localhost:3000' ? '' : 'https://';
								const link = `${https}${host}/newreferral/${referral.id}`;
								navigator.clipboard.writeText(link);
								message.success(ml('Link Copied Successfully!', currentUser, allMultiLingualData));
							}}
						/>
					</Tooltip>
				)}
			</div>
		</Col>
	);
};

export default ReferralContact;
