import { List } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { GetJob } from 'src/_shared/api/graphql/custom/jobs/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import fileIcon from 'src/_shared/assets/erin_lightgray.png';
import { getSetErrorImageURL, ml } from 'src/_shared/services/utils.js';
import { Link } from 'react-router-dom';
import ReferralInfoCard from '../_shared/components/referral-detail/ReferralDetailCardComponent.jsx';
import ReferralCard from '../_shared/components/referral-card/referralCardContactsContainer.js';

class DetailsView extends Component {
	state = {
		referralData: this.props.referralData,
		employeeData: this.props.employeeData,
		otherReferralDetails: null,
		referrals: this.props.referrals,
		company: get(this.props, 'currentUser.company'),
		errorImageURL: '',
	};

	async componentDidMount() {
		const { client, referralData, history } = this.props;
		const otherReferrals = referralData ? referralData.referrals : [];
		if (typeof history.goBack === 'function') {
			this.setState({ goBack: history.goBack });
		}

		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}

		const referralJobDetails = [];
		if (otherReferrals && otherReferrals.length > 0) {
			for (const referral of otherReferrals) {
				try {
					client
						.query({
							query: GetJob,
							variables: {
								id: referral.jobId,
							},
						})
						.then(({ data }) => {
							let referStatus = null;
							const jobReferred = get(data, 'getJob.referrals', []).find(
								(e) => e.contactId === referralData.id
							);
							referStatus = get(jobReferred, 'status', 'referred');
							const jobReferral = {
								bonusStatus: referral.bonusStatus,
								referralDate: referral.referralDate,
								referralType: referral.referralType,
								hireDate: referral.hireDate,
								user: referral.user,
								customStatus: referral.customStatus,
								...data.getJob,
							};

							jobReferral.status = referStatus;
							referralJobDetails.push(jobReferral);
						})
						.then(() => {
							this.setState({
								otherReferralDetails: referralJobDetails,
							});
						})
						.catch((error) => console.log(error));
				} catch (error) {
					console.log(error);
				}
			}
		} else {
			this.setState({ otherReferralDetails: [] });
		}
	}

	goBack = () => {
		this.state.goBack();
	};

	render() {
		const {
			allMultiLingualData,
			currentCurrencyRate,
			currentCurrencySymbol,
			currentUser,
			cancelAddText,
			confirmNewPositionText,
			tieredBonusText,
			mobileText,
			referralCommentsText,
			resumeText,
			addGeneralReferralText,
			addJobHistoryText,
			showJobHistoryText,
			referralReadyText,
			descriptionText,
			companyText,
			downloadResumeText,
			editProfileText,
			organizationText,
			startDateText,
			endDatesText,
			myContactsText,
			positionText,
			statusText,
			referredByText,
			referredOnText,
			referralsText,
			bonusDetailsText,
			jobText,
			referredCandidateText,
			totalBonusText,
			hiredDateText,
			bonusPaymentsText,
			bonusNotesText,
			closeText,
			viewBonusDetailsText,
			referredText,
			acceptedText,
			interviewingText,
			hiredText,
			notHiredText,
			noResponseText,
			inActiveText,
			ownershipSettings,
		} = this.props;
		const { company, otherReferralDetails } = this.state;
		const whiteLabel = get(company, 'whiteLabel');
		if (!this.props.referralData) {
			return <Spinner company={company} />;
		}

		return (
			<main>
				<span className="custom-back-btn" onClick={this.goBack}>
					<i className="icon-arrow-left" />{ml('Back', currentUser, allMultiLingualData)}
				</span>
				<div className="referrrals-profile">
					<ReferralInfoCard
						key="referral"
						allMultiLingualData={allMultiLingualData}
						employee={this.state.employeeData}
						referral={this.state.referralData}
						edit={this.props.edit ? this.props.edit : false}
						currentUser={currentUser}
						cancelAddText={cancelAddText}
						confirmNewPositionText={confirmNewPositionText}
						tieredBonusText={tieredBonusText}
						mobileText={mobileText}
						referralCommentsText={referralCommentsText}
						resumeText={resumeText}
						addGeneralReferralText={addGeneralReferralText}
						addJobHistoryText={addJobHistoryText}
						showJobHistoryText={showJobHistoryText}
						referralReadyText={referralReadyText}
						descriptionText={descriptionText}
						companyText={companyText}
						downloadResumeText={downloadResumeText}
						editProfileText={editProfileText}
						organizationText={organizationText}
						startDateText={startDateText}
						endDatesText={endDatesText}
						myContactsText={myContactsText}
						positionText={positionText}
						onUpdate={this.props.onUpdate}
					/>
				</div>
				<h4 className="referrrals-details-heading">{ml('Referrals', currentUser, allMultiLingualData)}</h4>
				{otherReferralDetails?.length > 0 ? (
					<List
						className="rd-wrap"
						grid={{ gutter: 14, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 3 }}
						dataSource={this.state.otherReferralDetails}
						itemLayout="horizontal"
						renderItem={(referral) => (
							<List.Item>
								<ReferralCard
									key={Math.floor(Math.random() * Math.floor(1_203_869_142))}
									ownershipSettings={ownershipSettings}
									referral={referral}
									employee={this.state.employeeData}
									referralType={referral.referralType}
									currentCurrencyRate={currentCurrencyRate}
									currentCurrencySymbol={currentCurrencySymbol}
									referredByText={referredByText}
									referredOnText={referredOnText}
									statusText={statusText}
									bonusDetailsText={bonusDetailsText}
									jobText={jobText}
									referredCandidateText={referredCandidateText}
									totalBonusText={totalBonusText}
									hiredDateText={hiredDateText}
									startDateText={startDateText}
									bonusPaymentsText={bonusPaymentsText}
									bonusNotesText={bonusNotesText}
									closeText={closeText}
									viewBonusDetailsText={viewBonusDetailsText}
									allMultiLingualData={allMultiLingualData}
									currentUser={currentUser}
									referredText={referredText}
									acceptedText={acceptedText}
									interviewingText={interviewingText}
									hiredText={hiredText}
									notHiredText={notHiredText}
									noResponseText={noResponseText}
									inActiveText={inActiveText}
								/>
							</List.Item>
						)}
					/>
				) : (
					<div className="no-content">
						{whiteLabel ? (
							<img
								className="no-content-icon"
								src={this.state.errorImageURL}
								alt="error image"
							/>
						) : (
							<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
						)}
						<p className="no-content-text">
							{ml('No referrals were found for this contact.', currentUser, allMultiLingualData)}
						</p>
					</div>
				)}
			</main>
		);
	}
}

export default withApollo(DetailsView);
