import dompurify from 'dompurify';
import { Component } from 'react';
import { ml } from 'src/_shared/services/utils';

const sanitizer = dompurify.sanitize;

class ReferralQuestionsDetailsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getValue = (element) => {
		if (element.name.startsWith('dropdown')) {
			return element.text;
		}

		return element.value;
	};

	createCandidateUI() {
		const { candidateQuestionsData } = this.props;
		const data = JSON.parse(candidateQuestionsData);
		if (data !== null && data !== undefined) {
			return data.map((element, i) => (
				<div key={i} className="rpf-qa">
					<p>
						<label
							dangerouslySetInnerHTML={{
								__html: sanitizer(element.question),
							}}
							className="qa-label"
						/>

						<br />
						<span>{this.getValue(element)}</span>
					</p>
				</div>
			));
		}
	}

	createUI() {
		const { questionsData } = this.props;
		const data = JSON.parse(questionsData);
		if (data !== null && data !== undefined) {
			return data.map((element, i) => (
				<div key={i} className="rpf-qa">
					<p>
						<label>{'Ques' + (i + 1)}.</label>
						<span
							dangerouslySetInnerHTML={{
								__html: sanitizer(element.question),
							}}
						/>
					</p>
					<p>
						<label>Ans.</label>
						<span>{this.getValue(element)}</span>
					</p>
				</div>
			));
		}
	}

	htmlDecode = (input) => {
		let returnValue = input.replaceAll('&gt;', '>');
		returnValue = returnValue.replaceAll('&lt;', '<');
		returnValue = returnValue.replaceAll('&quot;', '"');
		returnValue = returnValue.replaceAll('&apos;', "'");
		returnValue = returnValue.replaceAll('&amp;', '&');
		return returnValue;
	};

	render() {
		const { allMultiLingualData, candidateQuestionsData, currentUser, questionsData, } = this.props;
		const questions = JSON.parse(questionsData);
		const candidateQuestions =
			candidateQuestionsData && candidateQuestionsData.length > 0
				? JSON.parse(candidateQuestionsData)
				: null;
		return (
			<>
				<div>
					{questions && questions.length > 0 ? (
						<>
							<h4 className="content-right-heading">{ml('Referral Questions', currentUser, allMultiLingualData)}</h4>
							<> {this.createUI()}</>
						</>
					) : null}
				</div>
				{candidateQuestions && candidateQuestions.length > 0 ? (
					<>
						<h4 className="content-right-heading">{ml('Candidate Questions', currentUser, allMultiLingualData)}</h4>
						<>{this.createCandidateUI()}</>
					</>
				) : null}
			</>
		);
	}
}

export default ReferralQuestionsDetailsModal;
