import { PhoneFilled, ApiOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, message } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { ReferralQuestionsDetailsModal, WebIcon } from '..';
import linkedIn from '../../assets/linkedin-logo.png';
import twitter from '../../assets/twitter.png';
import { OnDeckReferralModal } from '../on-deck/on-deck-modal';
import {
	downloadFromS3Signed,
	findWithAttr as findWithAttribute,
	formatPhoneNumber,
	ml,
} from '../../services/utils';
import { COLORS } from '../../styles/colors';
import OnDeckComments from './OndeckCommentsComponent.jsx';
import ReferralComments from './ReferralCommentsComponent.jsx';
import {
	BioContainer,
	BioContainerExt as BioContainerExtension,
	SocialIcon,
} from './referralDetailCardStyles.js';

class ReferralDetailsCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contactNotes: get(props.referral, 'adminNote', ''),
			editNotes: false,
			editedFirstName: '',
			editedLastName: '',
			editedEmail: '',
			editedAtsId: '',
			editedPhone: '',
			editedPosition: '',
			editedOrg: '',
			editedLocation: '',
			editedBio: '',
			editedLinkedin: '',
			editedFacebook: '',
			editedTwitter: '',
			editedStartDate: '',
			editedEndDate: '',
			editedDescription: '',
			edit: false,
			addPosition: false,
			editedJobs: [],
			theme: JSON.parse(props?.currentUser?.company?.theme || '{}'),
			panelactiveKey: '0',
			diversityHire: false,
			s3Url: '',
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.setState({
			editedAtsId: this.props.referral.ownershipAtsId,
			editedFirstName: this.props.referral.firstName,
			editedLastName: this.props.referral.lastName,
			editedEmail: this.props.referral.emailAddress,
			editedPhone: this.props.referral.phone,
			editedLocation: this.props.referral.location,
			editedBio:
				this.props.referral.fullContact === null
					? null
					: this.props.referral.fullContact.bio,
			editedLinkedin: this.props.referral.linkedin,
			editedFacebook: this.props.referral.facebook,
			editedTwitter: this.props.referral.Twitter,
			editedJobs: this.props.referral.employment,
			contactNotes: get(this.props.referral, 'adminNote', ''),
			diversityHire: get(this.props.referral, 'diversityHire', false),
		});
	}

	getDownloadFromS3Bucket = async (key, bucket) => {
		const url = await downloadFromS3Signed(key, bucket);
		document.querySelector('#download').href = url;
	};

	cancelEdit = () => {
		message.error('Edit Cancelled');
		this.setState({ edit: false });
	};

	confirmEdit = () => {
		try {
			const { onUpdate, referral, onUpdateReferral, onUpdateContact } =
				this.props;
			const {
				editedAtsId,
				editedFirstName,
				editedLastName,
				editedEmail,
				editedBio,
				editedLinkedin,
				editedFacebook,
				editedTwitter,
				editedJobs,
				editedPhone,
			} = this.state;
			if (editedEmail === '' || editedEmail === null) {
				message.error('Need email to save');
				this.setState({ editedEmail: this.props.referral.emailAddress });
				return null;
			}

			referral.firstName = editedFirstName;
			referral.lastName = editedLastName;
			referral.contact.firstName = editedFirstName;
			referral.contact.lastName = editedLastName;
			referral.emailAddress = editedEmail.toLowerCase();
			referral.bio = editedBio;
			referral.linkedin = editedLinkedin;
			referral.facebook = editedFacebook;
			referral.Twitter = editedTwitter;
			referral.employment = editedJobs;
			referral.phone = editedPhone;
			const currentEnrichment = referral.fullContact;

			if (editedBio) {
				currentEnrichment.bio = editedBio;
			}

			if (editedJobs.length > 0) {
				currentEnrichment.details.employment = editedJobs;
			}

			const currentSocials = referral.socialMediaAccounts;
			const newSocials = [];
			const newTwitter =
				currentSocials !== null &&
				findWithAttribute(currentSocials, 'network', 'twitter') >= 0
					? currentSocials[
							findWithAttribute(currentSocials, 'network', 'twitter')
						]
					: null;
			const newFacebook =
				currentSocials !== null &&
				findWithAttribute(currentSocials, 'network', 'facebook') >= 0
					? currentSocials[
							findWithAttribute(currentSocials, 'network', 'facebook')
						]
					: null;
			const newLinkedin =
				currentSocials !== null &&
				findWithAttribute(currentSocials, 'network', 'linkedin') >= 0
					? currentSocials[
							findWithAttribute(currentSocials, 'network', 'linkedin')
						]
					: null;

			if (newFacebook !== null) {
				newFacebook.profile = editedFacebook;
				newSocials.push(newFacebook);
			}

			if (newTwitter !== null) {
				newTwitter.profile = editedTwitter;
				newSocials.push(newTwitter);
			}

			if (newLinkedin !== null) {
				newLinkedin.profile = editedLinkedin;
				newSocials.push(newLinkedin);
			}

			const updatedEnrichment = JSON.stringify(currentEnrichment);
			const input = {
				atsId: editedAtsId,
				firstName: editedFirstName,
				lastName: editedLastName,
				emailAddress: editedEmail.toLowerCase(),
				confirmedEmail: editedEmail.toLowerCase(),
				id: referral.id,
				fullContactData: updatedEnrichment,
				socialMediaAccounts:
					newSocials.length > 0 ? JSON.stringify(newSocials) : null,
				phoneNumber: editedPhone,
				userId: referral.userId,
			};
			onUpdate(input)
				.then(() => {
					onUpdateReferral({
						input: {
							id: get(referral, 'referralId'),
							ownershipAtsId: editedAtsId,
							diversityHire: this.state.diversityHire,
						},
					});
				})
				.then(() => {
					if (editedAtsId?.length > 0) {
						onUpdateContact({
							id: get(referral, 'contact.id'),
							atsId: editedAtsId,
						});
					}
				});
			message.success('Referral Edited');
			this.setState({ edit: false });
		} catch (error) {
			console.error(error);
		}
	};

	editInfo = () => {
		this.setState({
			edit: true,
		});
	};

	handleCancel = () => {
		this.setState({ edit: false });
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	render() {
		const {
			addGeneralReferralText,
			allMultiLingualData,
			currentUser,
			referral,
		} = this.props;
		const referrals = get(referral, 'referrals', []);
		let isDeckView = false;
		const questionsData =
			referrals !== null && referrals[0]
				? get(referrals[0], 'questionsData', [])
				: referral
					? get(referral, 'questionsData', [])
					: null;
		const candidateQuestionsData =
			referrals === null
				? null
				: get(referrals[0], 'candidateQuestionsData', []);
		const interestedQuestionsData =
			referral === null ? null : referral?.interestedQuestionsData ?? [];
		const additionalQuestionsData =
			candidateQuestionsData?.length > 0
				? candidateQuestionsData
				: interestedQuestionsData;
		const {
			ownershipAtsId,
			avatar,
			emailAddress,
			position,
			fullContact,
			phone,
			organization,
			Twitter,
			linkedin,
			facebook,
			contactResume,
		} = referral;

		if (referral.contact?.onDeckStatus == 'onDeck' && referrals.length === 0) {
			isDeckView = true;
		}

		let bio = null;
		if (fullContact !== null && fullContact !== undefined) {
			bio = get(fullContact, 'bio');
		}

		const { theme } = this.state;
		const firstName = get(referral, 'contact.firstName', ' ');
		const lastName = get(referral, 'contact.lastName', ' ');

		// Edit component fields
		const classContainer =
			Twitter !== null || linkedin !== null || facebook !== null
				? BioContainer
				: BioContainerExtension;
		const editIcon = <Button onClick={this.editInfo}>Edit Referral</Button>;

		const okButton = (
			<div className="profile-right-btn-grp">
				<Popconfirm
					title={ml("Confirm Updates?", currentUser, allMultiLingualData)}
					placement="left"
					okText={ml("Yes", currentUser, allMultiLingualData)}
					cancelText={ml("No", currentUser, allMultiLingualData)}
					onConfirm={this.confirmEdit}
				>
					<Button type="primary" size="large">
						{ml("Save", currentUser, allMultiLingualData)}
					</Button>
				</Popconfirm>
				<Popconfirm
					title={ml("Discard Edits?", currentUser, allMultiLingualData)}
					placement="left"
					okText={ml("Yes", currentUser, allMultiLingualData)}
					cancelText={ml("No", currentUser, allMultiLingualData)}
					onConfirm={this.cancelEdit}
				>
					<Button ghost type="primary" size="large">
						{ml("Cancel", currentUser, allMultiLingualData)}
					</Button>
				</Popconfirm>
			</div>
		);

		const editFirstName = (
			<Input
				key="editFirstName"
				id="firstname"
				name="editedFirstName"
				placeholder={ml("First Name", currentUser, allMultiLingualData)}
				value={this.state.editedFirstName}
				className="custom-input"
				onChange={this.handleChange('editedFirstName')}
			/>
		);

		const editLastName = (
			<Input
				key="editLastName"
				id="lastname"
				name="editedLastName"
				placeholder={ml("Last Name", currentUser, allMultiLingualData)}
				value={this.state.editedLastName}
				className="custom-input"
				onChange={this.handleChange('editedLastName')}
			/>
		);

		const editEmail = (
			<Input
				id="email"
				name="editedEmail"
				placeholder={ml("Email", currentUser, allMultiLingualData)}
				value={this.state.editedEmail}
				className="custom-input"
				onChange={this.handleChange('editedEmail')}
			/>
		);

		const editAtsId = (
			<Input
				id="atsId"
				name="editedAtsId"
				placeholder={ml("Candidate ID", currentUser, allMultiLingualData)}
				value={this.state.editedAtsId}
				className="custom-input"
				onChange={this.handleChange('editedAtsId')}
			/>
		);

		const editPhone = (
			<Input
				name="editedPhone"
				placeholder={ml("Phone", currentUser, allMultiLingualData)}
				value={this.state.editedPhone}
				className="custom-input"
				onChange={this.handleChange('editedPhone')}
			/>
		);

		const editLinkedin = (
			<Input
				name="editedLinkedin"
				placeholder="Linkedin"
				value={this.state.editedLinkedin}
				style={{ width: 200 }}
				onChange={this.handleChange('editedLinkedin')}
			/>
		);

		const editFacebook = (
			<Input
				name="editedFacebook"
				placeholder="Facebook"
				value={this.state.editedFacebook}
				style={{ width: 200 }}
				onChange={this.handleChange('editedFacebook')}
			/>
		);

		const editTwitter = (
			<Input
				name="editedTwitter"
				placeholder="Twitter"
				value={this.state.editedTwitter}
				style={{ width: 200 }}
				onChange={this.handleChange('editedTwitter')}
			/>
		);

		const userRole = currentUser?.role || '';
		return (
			<>
				<div className="referrrals-profile-left">
					<div className="rpf-content">
						<div className="custom-image-wrap">
							{avatar === null || avatar === undefined ? (
								<>
									{get(firstName, '[0]', '').toUpperCase()}{' '}
									{get(lastName, '[0]', '').toUpperCase()}
								</>
							) : (
								<img
									className="custom-img"
									src={avatar}
									alt={`${get(firstName, '[0]', '').toUpperCase()} ${get(
										lastName,
										'[0]',
										''
									).toUpperCase()}`}
								/>
							)}
						</div>
						<div className="rpf-content-right">
							{this.state.edit === true ? (
								<div className="custom-form-group cf-frp-name">
									{editFirstName}
									{editLastName}
								</div>
							) : (
								<h2 className="content-right-name">
									{firstName} {lastName}
								</h2>
							)}
							{this.state.edit === true ? (
								<>
									<div className="custom-form-group">{editEmail}</div>
									<div className="custom-form-group">{editAtsId}</div>
								</>
							) : emailAddress === null ? null : (
								<p className="rpfc-right-text">
									<a
										className="content-right-link"
										href={`mailto:${emailAddress}`}
									>
										<i
											className="icon-envelope-outline"
											style={{ fontSize: 18 }}
										/>
										<span>{emailAddress}</span>
									</a>
								</p>
							)}
							{this.state.editedAtsId && this.state.edit !== true && (
								<p className="rpfc-right-text">
									<ApiOutlined
										style={{
											fontSize: 18,
											marginRight: 16,
											verticalAlign: 'text-top',
										}}
									/>
									<span>{ownershipAtsId}</span>
								</p>
							)}
							{this.state.edit === true ? (
								<div className="custom-form-group">{editPhone}</div>
							) : phone === null ? null : (
								<p className="rpfc-right-text">
									<a className="content-right-link" href={`tel:${phone}`}>
										<PhoneFilled />
										<span>{formatPhoneNumber(phone)}</span>
									</a>
								</p>
							)}

							<div className="rpfc-right-icons">
								{linkedin !== null && (
									<>
										<a href={linkedin}>
											<img
												alt="linkedIn"
												className={SocialIcon}
												src={linkedIn}
											/>
										</a>
										{this.state.edit === true && editLinkedin}
									</>
								)}
								{facebook !== null && (
									<>
										<a href={facebook}>
											<WebIcon
												name="facebook-logo"
												size={this.props.size || 18}
												color={COLORS.blue}
											/>
										</a>
										{this.state.edit === true && editFacebook}
									</>
								)}
								{Twitter !== null && (
									<>
										<a href={Twitter}>
											<img alt="twitter" className={SocialIcon} src={twitter} />
										</a>
										{this.state.edit === true && editTwitter}
									</>
								)}
							</div>

							{contactResume && contactResume.key !== null ? (
								<>
									<h4 className="content-right-heading">{ml('Resume', currentUser, allMultiLingualData)}</h4>
									<p>
										<a
											target="_blank"
											id="download"
											className="content-right-link"
											href="#"
											onClick={this.getDownloadFromS3Bucket(
												get(contactResume, 'key'),
												'erin-documents'
											)}
										>
											<i className="icon-application" />
											<span>{ml('Download Resume', currentUser, allMultiLingualData)}</span>
										</a>
									</p>
								</>
							) : null}

							{referrals.length > 0 ? (
								<>
									<ReferralComments
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										referrals={referrals}
									/>
									{this.state.edit && (
										<div className="custom-form-group custom-checkbox left-check my-2">
											<input
												id="diversityHire"
												type="checkbox"
												checked={this.state.diversityHire}
												onChange={() =>
													this.setState({
														diversityHire: !this.state.diversityHire,
													})
												}
											/>
											<label htmlFor=" diversityHire">{ml('Diversity Hire', currentUser, allMultiLingualData)}</label>
										</div>
									)}
									{questionsData?.length > 0 ||
									additionalQuestionsData?.length > 0 ? (
										<ReferralQuestionsDetailsModal
											allMultiLingualData={allMultiLingualData}
											currentUser={currentUser}
											questionsData={questionsData}
											candidateQuestionsData={additionalQuestionsData}
											theme={theme}
										/>
									) : null}
								</>
							) : null}

							{referrals.length === 0 && isDeckView === true ? (
								<div className={classContainer}>
									<OnDeckComments
										allMultiLingualData={allMultiLingualData}
										referrals={referral}
										currentUser={currentUser}
									/>
									{(questionsData !== null && questionsData.length > 0) ||
									(candidateQuestionsData !== null &&
										candidateQuestionsData.length > 0) ? (
										<ReferralQuestionsDetailsModal
											allMultiLingualData={allMultiLingualData}
											currentUser={currentUser}
											questionsData={questionsData}
											candidateQuestionsData={candidateQuestionsData}
											theme={theme}
										/>
									) : null}
								</div>
							) : null}

							{position === null ? null : (
								<div className="rpf-referral-info">
									<h4 className="content-right-heading">{ml('Smart Referral Info', currentUser, allMultiLingualData)}</h4>
									{position !== null && <p>{`${position} `}</p>}
									{organization !== null && <h5>@ {` ${organization}`}</h5>}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="referrrals-profile-right">
					{userRole === 'admin' && (
						<div className="w-100 mb-3">
							{this.state.edit ? okButton : editIcon}
						</div>
					)}
					{this.props.referral.emailAddress &&
					get(this.props, ['referral', 'onDeckStatus']) !== 'onDeck' ? (
						<OnDeckReferralModal
							button
							jobData={this.props.job}
							minWidth={180}
							contact={this.props.referral}
							referPage="referralDetails"
							addGeJobHneralReferralText={addGeneralReferralText}
						/>
					) : null}
				</div>
			</>
		);
	}
}
export default ReferralDetailsCard;
