import { GetObjectCommand, PutObjectCommand, S3 } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { Auth, Cache } from 'aws-amplify';
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import * as geolib from 'geolib';
import Cookies from 'js-cookie';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import get from 'lodash/get';
import { decryptUsingAES256 } from 'src/_shared/api/settings';
import { REFERRAL_STATUS } from 'src/_shared/constants/';
import { COLORS } from '../styles/colors.js';

dayjs.extend(isBetween);
dayjs.extend(utc);
const MLRequests = [];

export const snappy = async (request) => {
	const configMode = 'prod';

	const variables = {
		...request,
		configMode,
	};
	const response = await lambda({
		endpoint: `snappy-api`,
		variables,
	});

	return response;
};

export const authHeader = () => {
	const jwt = Cookies.get('jwt');
	return {
		Authorization: `Bearer ${jwt}`,
	};
};

export const normalizeDollar = (value) => {
	return value.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const compose = (...funcs) => {
	if (funcs.length === 0) {
		return (argument) => argument;
	}

	if (funcs.length === 1) {
		return funcs[0];
	}

	return funcs.reduce(
		(a, b) =>
			(...args) =>
				a(b(...args))
	);
};

export const hasWhiteSpace = (s) => {
	return s.includes(' ');
};

export const handleAuthType = (type, reload = true) => {
	const authType = localStorage.getItem('authType');
	if (authType !== type) {
		localStorage.setItem('authType', type);
		if (reload) window.location.reload();
	}
};

export const lowerCase = (value) => {
	try {
		return typeof value === 'number'
			? value.toString().toLowerCase()
			: value.toLowerCase();
	} catch {
		return '';
	}
};

export const initialCaps = (string_) => {
	try {
		return string_
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
			.join(' ');
	} catch {
		return '';
	}
};

export const pascalCase = (value) => {
	try {
		return value.replaceAll(/(\w)(\w*)/g, function (g0, g1, g2) {
			return g1.toUpperCase() + g2.toLowerCase();
		});
	} catch {
		return value;
	}
};

export const camelCase = (value) => {
	try {
		return value
			.replaceAll(/\s(.)/g, function (a) {
				return a.toUpperCase();
			})
			.replaceAll(/\s/g, '')
			.replace(/^(.)/, function (b) {
				return b.toLowerCase();
			});
	} catch {
		return '';
	}
};

export const upperCase = (value) => {
	try {
		return typeof value === 'number'
			? value.toString().toUpperCase()
			: value.toUpperCase();
	} catch {
		return '';
	}
};

export const strip = (value) => {
	try {
		return value.replace(' ', '');
	} catch {
		return '';
	}
};

export const string = (value) => {
	try {
		return value.toString();
	} catch {
		return '';
	}
};

export const parse = (value) => {
	try {
		return JSON.parse(value);
	} catch {
		return value;
	}
};

export const getAddressFromLocation = (location) => {
	const address = location?.address
		? location.address
		: [location?.city, location?.state, location?.country]
				.filter(Boolean)
				.join(', ');
	return address;
};

export const getEnvironment = () => {
	let environment = 'other';
	const host = window.location.hostname;
	if (
		host === 'localhost' ||
		host === 'referralstest.aus.com' ||
		host === 'qa.referafriend.seaworldentertainment.com' ||
		host === 'erinapp-alpha.netlify.app'
	) {
		environment = 'dev';
	}

	if (host === 'erinapp-load-test.netlify.app') {
		environment = 'load-test';
	}

	if (
		host === 'app.erinapp.com' ||
		host === 'erinapp-dev.netlify.app' ||
		host === 'testing.erinapp.com'
	) {
		environment = 'prod';
	}

	if (String(process.env.REACT_APP_FORCE_PROD).toLowerCase() == 'true')
		environment = 'prod';
	return environment;
};

// Used in employee page to filter results via the search component
export const searchInviteData = (properties = [], query = '', dataSet = []) => {
	return dataSet.filter((item) =>
		properties.some((property) =>
			lowerCase(get(item, [property], [])).includes(lowerCase(query.trim()))
		)
	);
};

export const searchNetwork = async (query, currentUser) => {
	const configMode = getEnvironment() === 'dev' ? 'DEV' : 'PROD';
	const elasticquery = new Object({
		request: query,
		type: 'job',
		currentUser,
		configMode,
	});
	return lambda({
		endpoint: 'elasticdatacalls-prod-api',
		variables: elasticquery,
	}).then((response) => response.results);
};

export const searchOpenSearchNetwork = async (request, type) => {
	let configMode = getEnvironment();
	if (configMode === 'other') {
		configMode = 'prod';
	}

	request = request?.request ? request.request : request;
	if (typeof request?.query === 'object') {
		request = request?.query?.request;
	}

	const variables = {
		request,
		type,
		currentUser: request?.currentUser?.id
			? request.currentUser.id
			: request?.currentUser,
		configMode,
	};
	const response = await lambda({
		endpoint: `open-search-api-${configMode}`,
		variables,
	});

	return response;
};

const searchGDPRData = (props) => {
	const query = get(props, 'query');
	const dataSet = get(props, 'gdprReferrals', []);
	const searchQuery = lowerCase(query.split(' ').join(''));
	let totalFilters = 0;
	for (const filter of Object.entries(get(props, 'filters', {}))) {
		if (typeof filter[1] === 'object' && filter[1].length > 0) totalFilters++;
	}

	const results = dataSet.filter((item) => {
		if ((!searchQuery || searchQuery.length === 0) && totalFilters === 0) {
			return item;
		}

		let matchesQuery = false;
		let matchesFilters = false;
		if (totalFilters > 0) {
			let filtersMatched = 0;
			for (const filter of Object.entries(get(props, 'filters', {}))) {
				if (typeof filter[1] === 'object' && filter[1].length > 0) {
					if (filter[0] === 'bonuses') {
						let isMatch = false;
						for (const bonus of filter[1]) {
							if (bonus === get(item, 'job.referralBonus.tieredBonusId'))
								isMatch = true;
						}

						if (isMatch) filtersMatched++;
					}

					if (filter[0] === 'departments') {
						let isMatch = false;
						for (const department of filter[1]) {
							if (get(department, 'id') === get(item, 'job.departmentId'))
								isMatch = true;
						}

						if (isMatch) filtersMatched++;
					}

					if (filter[0] === 'recruiters') {
						let isMatch = false;
						for (const recruiter of filter[1]) {
							if (recruiter === get(item, 'job.hiringManagerId'))
								isMatch = true;
						}

						if (isMatch) filtersMatched++;
					}

					if (filter[0] === 'statuses') {
						let isMatch = false;
						for (const status of filter[1]) {
							if (status === get(item, 'status')) isMatch = true;
						}

						if (isMatch) filtersMatched++;
					}

					if (filter[0] === 'subCompanies') {
						let isMatch = false;
						for (const subCompany of filter[1]) {
							if (subCompany === get(item, 'job.subCompanyId')) isMatch = true;
						}

						if (isMatch) filtersMatched++;
					}

					if (totalFilters === filtersMatched) matchesFilters = true;
				}
			}
		} else {
			matchesFilters = true;
		}

		if (searchQuery.length > 0) {
			if (lowerCase(get(item, 'job.title')).includes(lowerCase(query)))
				matchesQuery = true;
			if (
				lowerCase(
					get(item, 'user.firstName', '') + get(item, 'user.lastName', '')
				).includes(lowerCase(searchQuery))
			)
				matchesQuery = true;
			if (
				lowerCase(get(item, 'user.emailAddress')).includes(
					lowerCase(searchQuery)
				)
			)
				matchesQuery = true;
			if (lowerCase(get(item, 'user.employeeId')).includes(lowerCase(query)))
				matchesQuery = true;
		} else {
			matchesQuery = true;
		}

		if (matchesFilters && matchesQuery) return item;
	});
	return results;
};

export const searchReferralsNetwork = async (query, currentUser) => {
	const configMode = getEnvironment() === 'dev' ? 'DEV' : 'PROD';
	const elasticquery = new Object({
		request: query,
		type: 'referral',
		currentUser,
		configMode,
	});
	return lambda({
		endpoint: 'elasticdatacalls-prod-api',
		variables: elasticquery,
	}).then((response) => response.results);
};

export const searchReferralsDataNetwork = async (props) => {
	let stages = [];
	if (get(props, 'currentUser.company.stages')) {
		stages = parse(get(props, 'currentUser.company.stages', [])).map(
			(stage) => Object.values(stage)[0]
		);
	}

	let qry = get(props, 'query', '');
	const gdprReferrals = get(props, 'gdprReferrals');
	let gdpr;
	if (gdprReferrals) gdpr = searchGDPRData(props);
	if (get(props, 'query', '').includes('@')) qry = `"${get(props, 'query')}"`;

	const query = {
		query: qry,
		filters: {
			all: [
				{
					company_id: get(props, 'filters.companies'),
				},
			],
			any: [],
			none: [],
		},
		page: {
			size: 300,
		},
	};

	if (get(props, 'filters.referralType')) {
		query.filters.all.push({ referral_type: 'self' });
	}

	if (get(props, 'filters.bonuses', []).length > 0) {
		const filters = get(query, 'filters.all');
		const bonuses = [];
		for (const b of get(props, 'filters.bonuses', [])) bonuses.push(b);
		const newFilter = {
			tiered_bonus_id: bonuses,
		};
		filters.push(newFilter);
		query.filters.all = filters;
	}

	if (get(props, 'filters.customStatuses', []).length > 0) {
		const swiftypeAnyFilters = get(query, 'filters.any');
		const companyStageFilters = get(props, 'filters.customStatuses', ['']);
		query.filters.any = [
			...swiftypeAnyFilters,
			{
				all: [
					{ custom_status: companyStageFilters },
					{ status: ['interviewing'] },
				],
			},
		];
	}

	if (get(props, 'filters.departments', []).length > 0) {
		const filters = get(query, 'filters.all');
		const departments = [];
		for (const d of get(props, 'filters.departments', []))
			departments.push(d.id);
		const newFilter = {
			department_id: departments,
		};
		filters.push(newFilter);
		query.filters.all = filters;
	}

	if (get(props, 'filters.recruiters', []).length > 0) {
		const filters = get(query, 'filters.all');
		const recruiters = [];
		for (const r of get(props, 'filters.recruiters', [])) recruiters.push(r);
		const newFilter = {
			hiring_manager_id: recruiters,
		};
		filters.push(newFilter);
		query.filters.all = filters;
	}

	if (get(props, 'filters.statuses', []).length > 0) {
		const stageFilters = get(props, 'filters.statuses', []);
		const swiftypeAnyFilters = get(query, 'filters.any');
		const customStatus = get(props, 'filters.customStatuses', []);
		let noneFilter = [];

		if (customStatus.length > 0) {
			noneFilter = [{ custom_status: [''] }];
		}

		if (stageFilters.includes('interviewing')) {
			query.filters.any = [
				...swiftypeAnyFilters,
				{
					all: [{ status: stageFilters }, { none: noneFilter }],
				},
			];
		} else {
			query.filters.any = [
				...swiftypeAnyFilters,
				{
					all: [{ status: stageFilters }],
				},
			];
		}
	}

	if (get(props, 'filters.subCompanies', []).length > 0) {
		const filters = get(query, 'filters.all');
		const subCompanies = [];
		for (const sc of get(props, 'filters.subCompanies', []))
			subCompanies.push(sc);
		const newFilter = {
			sub_company_id: subCompanies,
		};
		filters.push(newFilter);
		query.filters.all = filters;
	}

	if (get(props, 'filters.userGroups', []).length > 0) {
		const filters = get(query, 'filters.all');
		const userGroups = [];
		for (const userGroup of get(props, 'filters.userGroups', []))
			userGroups.push(userGroup.id);
		const newFilter = {
			user_group_id: userGroups,
		};
		filters.push(newFilter);
		query.filters.all = filters;
	}

	const currentUserId = get(props, 'currentUser.id');
	const matchedReferrals = await searchReferralsNetwork(query, currentUserId);
	const allData = [];
	if (gdpr) for (const ref of gdpr) allData.push(ref);

	for (const ref of matchedReferrals) {
		const resume = parse(get(ref, 'contact_resume.raw', '{}'));
		const contactResume = get(resume, 'key') ? resume : null;
		const newReference = {
			companyId: get(ref, 'company_id.raw'),
			contact: {
				id: get(ref, 'contact_id.raw'),
				firstName: get(ref, 'contact_first_name.raw'),
				lastName: get(ref, 'contact_last_name.raw'),
				employeeId: get(ref, 'contact_employee_id.raw'),
				emailAddress: get(ref, 'contact_email_address.raw'),
				phoneNumber: get(ref, 'contact_phone_number.raw'),
			},
			contactId: get(ref, 'contact_id.raw'),
			contactResume,
			customStatus: get(ref, 'custom_status.raw'),
			diversityHire: get(ref, 'diversity_hire.raw') === 'true',
			hireDate: get(ref, 'date_hired.raw'),
			id: get(ref, 'id.raw'),
			job: {
				id: get(ref, 'job_id.raw'),
				title: get(ref, 'job_title.raw'),
				hiringManager: get(ref, 'hiring_manager_id.raw'),
				tieredBonusId: get(ref, 'tiered_bonus_id.raw'),
				tieredBonus: {
					id: get(ref, 'tiered_bonus_id.raw'),
					name: get(ref, 'tiered_bonus_name.raw'),
					tiers: get(ref, 'tiered_bonus_tiers.raw'),
				},
				subCompanyId: get(ref, 'sub_company_id'),
				externalJobId: get(ref, 'job_external_id.raw'),
			},
			bonus: {
				amount: get(ref, 'bonus_amount.raw'),
				tieredBonusId: get(ref, 'tiered_bonus_id.raw'),
			},
			jobId: get(ref, 'job_id.raw'),
			message: get(ref, 'message.raw', null),
			note: get(ref, 'note.raw'),
			referralDate: get(ref, 'date_referred.raw'),
			referralType: get(ref, 'referral_type.raw'),
			status: get(ref, 'status.raw'),
			user: {
				id: get(ref, 'user_id.raw'),
				firstName: get(ref, 'user_first_name.raw'),
				lastName: get(ref, 'user_last_name.raw'),
				employeeId: get(ref, 'contact_employee_id.raw'),
				userGroupId: get(ref, 'user_group_id.raw'),
			},
			userId: get(ref, 'user_id.raw'),
			referralSource: get(ref, 'referral_source.raw'),
		};

		if (get(ref, 'retro_tiered_bonus_id.raw')) {
			newReference.tieredBonusId = get(ref, 'retro_tiered_bonus_id.raw');
			const retroTieredBonus = {
				id: get(ref, 'retro_tiered_bonus_id.raw'),
				name: get(ref, 'retro_tiered_bonus_name.raw'),
				tiers: get(ref, 'retro_tiered_bonus_tiers.raw'),
			};
			newReference.tieredBonus = retroTieredBonus;
		}

		if (get(ref, 'campaign_id.raw')) {
			newReference.campaignId = get(ref, 'campaign_id.raw');
			newReference.campaign = {
				id: get(ref, 'campaign_id.raw'),
				archived: get(ref, 'campaign_archived.raw'),
				name: get(ref, 'campaign_name.raw'),
				startDate: get(ref, 'campaign_start_date.raw'),
				endDate: get(ref, 'campaign_end_date.raw'),
				tieredBonusId: get(ref, 'campaign_tiered_bonus_id.raw'),
				tieredBonus: {
					id: get(ref, 'campaign_tiered_bonus_id.raw'),
					name: get(ref, 'campaign_tiered_bonus_name.raw'),
					tiers: get(ref, 'campaign_tiered_bonus_tiers.raw'),
				},
			};
		}

		allData.push(newReference);
	}

	const data = allData;
	let totals = {};
	if (get(props, 'filterOn') && data.length < 1000) {
		totals.total = data.length;
		totals.accepted = data.filter(
			(r) => get(r, 'status') === 'accepted'
		).length;
		totals.interviewing = data.filter(
			(r) => get(r, 'status') === 'interviewing'
		).length;
		totals.hired = data.filter((r) => get(r, 'status') === 'hired').length;
		totals.declined = data.filter(
			(r) => get(r, 'status') === 'declined'
		).length;
		totals.noResponse = data.filter(
			(r) => get(r, 'status') === 'noresponse'
		).length;
		totals.inactive = data.filter(
			(r) => get(r, 'status') === 'inactive'
		).length;
		totals.notHired = data.filter(
			(r) => get(r, 'status') === 'notHired'
		).length;
	} else {
		totals = get(props, 'totals');
	}

	return { data, totals, query: get(props, 'query', '') };
};

export const filterByDistance = async (
	dataSet = [],
	filterDistance,
	lat,
	lng
) => {
	if (filterDistance < 1 || !lat || !lng) return dataSet;

	// Handle async filtering by creating map of true/false promise results
	const resolved = await Promise.all(
		dataSet.map(async (entry) => {
			const locationCoordinates = [
				...(typeof entry?.location === 'string'
					? entry.location.split(',')
					: [
							entry?.location?.coordinates?.lat,
							entry?.location?.coordinates?.lon,
						]),
			];
			let latitude =
				entry?.lat ??
				locationCoordinates[0] ??
				entry?.location?.lat ??
				entry?.job?.location?.lat;
			let longitude =
				entry?.lng ??
				locationCoordinates[1] ??
				entry?.location?.lng ??
				entry?.location?.lon ??
				entry?.job?.location?.lng;
			if (!latitude || !longitude) {
				const { lat, lng } = (await getCoordinates(entry?.job?.location)) ?? {
					lat: undefined,
					lng: undefined,
				};
				latitude = lat;
				longitude = lng;
			}

			if (latitude && longitude && lat && lng) {
				const isInRadius = geolib.isPointWithinRadius(
					{
						latitude: lat,
						longitude: lng,
					},
					{ latitude, longitude },
					filterDistance * 1609.344
				);
				return isInRadius;
			}

			return false;
		})
	);

	// Filter dataset by true/false map
	return dataSet.filter((job, i) => resolved[i]);
};

export const validEmail = (inputText) => {
	try {
		const mailformat =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
		return inputText.match(mailformat);
	} catch {
		return false;
	}
};

export const getCoordinates = async (location = '{}', auth = 'cognito') => {
	try {
		location = typeof location === 'string' ? parse(location) : location;
		const variables = {
			type: 'getCoordinates',
			...location,
		};
		const response = await lambda(
			{
				endpoint: 'location-services-api',
				variables,
			},
			auth
		);
		const coordinates = response?.lat
			? { lat: response?.lat, lng: response?.lon }
			: null;
		return coordinates;
	} catch (error) {
		console.log(error);
	}
};

export const placesAutocomplete = async (address, auth = 'cognito') => {
	try {
		const variables = {
			type: 'autocomplete',
			address,
		};
		const response = await lambda(
			{
				endpoint: 'location-services-api',
				variables,
			},
			auth
		);
		return response;
	} catch (error) {
		console.log(error);
	}
};

export const getLocation = async (props, auth = 'cognito') => {
	try {
		const variables = {
			type: props?.place ? 'getPlace' : 'getLocation',
			...props,
		};
		const response = await lambda(
			{
				endpoint: 'location-services-api',
				variables,
			},
			auth
		);
		return response;
	} catch (error) {
		console.log(error);
	}
};

export const findAddressComponent = (
	type,
	addrComponents,
	length_ = 'long_name'
) => {
	let result = null;
	for (const comp of addrComponents) {
		if (get(comp, 'types', []).find((t) => t === type)) result = comp;
	}

	return result ? result[length_] : result;
};

export const searchData = (properties, query = '', dataSet) => {
	const searchName = lowerCase(query.replace(/\s+/g, ''));
	return dataSet.filter(
		item =>
			!searchName ||
			properties.some(
				property =>
					(item[property] &&
						lowerCase(item[property].replace(/\s+/g, '')).includes(searchName)) ||
					(property === 'phoneNumber' && item[property] != null
						? item[property].replaceAll(/[^a-z\d]/gi, '').includes(searchName)
						: '')
			) ||
			(item.firstName &&
				item.lastName &&
				lowerCase((item.firstName + item.lastName).replace(/\s+/g, '')).includes(searchName))
	);
};

export const searchJobsData = (properties, dataSet) => {
	return dataSet.filter(
		(item) => item.title && lowerCase(item.title).includes(properties)
	);
};

export const searchReferralData = (properties, query = '', dataSet) => {
	const searchName = lowerCase(query.split(' ').join(''));
	const searchByTitle = lowerCase(query);
	return dataSet.filter(
		(item) =>
			!searchName ||
			(item.contact &&
				lowerCase(
					get(item, "contact['firstName']", '') +
						get(item, "contact['lastName']", '')
				).includes(searchName)) ||
			(item.job &&
				lowerCase(get(item, "job['title']", '')).includes(searchByTitle))
	);
};

export const searchBonusData = (query, dataSet, filters) => {
	const searchByName = lowerCase(query.split(' ').join(''));
	const searchByTitle = lowerCase(query);

	const isFilteredByDates = get(filters, 'dates', []).length > 0;
	const isFilteredByStatus = get(filters, 'statuses', []).length > 0;
	const isFilteredByUserGroups = get(filters, 'userGroups', []).length > 0;

	const bonuses = [...dataSet];
	const dateRangeFilters = get(filters, 'dates', []);
	const referralIdFilter = get(filters, 'referralId');
	const statusFilters = get(filters, 'statuses', []);
	const userGroupFilters = get(filters, 'userGroups', []);

	return bonuses.filter((currentBonus) => {
		const userName =
			get(currentBonus, 'user.firstName') + get(currentBonus, 'user.lastName');
		const contactName =
			get(currentBonus, 'contact.firstName') +
			get(currentBonus, 'contact.lastName');
		let result = null;
		const jobTitle = get(currentBonus, 'job.title');

		if (!searchByName || searchByName === '') result = currentBonus;
		if (lowerCase(userName).includes(searchByName)) result = currentBonus;
		if (lowerCase(contactName).includes(searchByName)) result = currentBonus;
		if (lowerCase(jobTitle).includes(searchByTitle)) result = currentBonus;
		if (
			referralIdFilter &&
			get(currentBonus, 'referralId') === referralIdFilter
		)
			result = currentBonus;

		if (result && isFilteredByDates) {
			let match = false;
			const start = dayjs(dateRangeFilters[0]).subtract(1, 'day');
			const end = dayjs(dateRangeFilters[1]).subtract(1, 'day');

			if (
				dayjs(get(currentBonus, 'earnedDate')).isBetween(
					start,
					end,
					'days',
					'[]'
				)
			) {
				match = true;
			}

			if (!match) result = null;
		}

		if (result && isFilteredByStatus) {
			let match = false;

			for (const currentStatus of statusFilters) {
				let bonusStatus = get(currentBonus, 'bonusStatus');

				if (bonusStatus === 'earned') bonusStatus = 'eligible';
				if (
					bonusStatus !== 'pending' &&
					bonusStatus !== 'paid' &&
					bonusStatus !== 'eligible' &&
					bonusStatus !== 'needsReview'
				) {
					bonusStatus = 'ineligible';
				}

				if (currentStatus === bonusStatus) match = true;
			}

			if (!match) result = null;
		}

		if (result && isFilteredByUserGroups) {
			let match = false;

			for (const currentUserGroup of userGroupFilters) {
				const selectedUserGroup = get(currentBonus, 'user.userGroup.name');

				if (selectedUserGroup === currentUserGroup) match = true;
			}

			if (!match) result = null;
		}

		if (result) return result;
	});
};

export const findWithAttr = (array, attribute, value) => {
	for (const [i, element] of array.entries()) {
		if (element[attribute] === value) {
			return i;
		}
	}

	return -1;
};

export const capitalizeFirstLetter = (string = '') => {
	try {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} catch {
		return string;
	}
};

export const calculateReferralBonus = (
	contactIncentiveBonus,
	referralBonusAmount,
	eligible,
	tieredBonus,
	tieredBonusType,
	userGroupId,
	hireDate
) => {
	const programActive = contactIncentiveBonus > 0;
	if (tieredBonus) {
		return getTieredBonusAmount(
			tieredBonus,
			tieredBonusType,
			userGroupId,
			hireDate
		);
	}

	if (
		!referralBonusAmount ||
		referralBonusAmount === 0 ||
		referralBonusAmount === null
	) {
		return 0;
	}

	if (!programActive) {
		return Number.parseInt(referralBonusAmount);
	}

	if (programActive && eligible) {
		return Number.parseInt(referralBonusAmount);
	}

	if (programActive && !eligible) {
		return Number.parseInt(
			Math.max(0, referralBonusAmount - contactIncentiveBonus)
		);
	}
};

export const campaignIsActive = (campaign) => {
	try {
		const today = dayjs();
		const startDate = get(campaign, 'startDate');
		const endDate = get(campaign, 'endDate');
		if (today.isBetween(startDate, endDate, 'day', '[]')) {
			return true;
		}

		return false;
	} catch {
		return false;
	}
};

export const calculateBonus = (job, user, options) => {
	const contactIncentiveBonus = get(job, 'company.contactIncentiveBonus', 0);
	const referralBonus = parse(get(job, 'referralBonus'));
	const referralBonusAmount = get(referralBonus, 'amount');
	const eligible = get(user, 'incentiveEligible', false);
	const programActive = contactIncentiveBonus > 0;
	const userGroupId = get(user, 'userGroupId');
	const recipient = get(options, 'recipient');
	const hireDate = get(options, 'hireDate');
	let tieredBonus = null;
	const campaignId = get(job, 'campaignId');
	const campaign = get(job, 'campaign');
	if (campaignId && !get(campaign, 'archived') && campaignIsActive(campaign)) {
		tieredBonus = parse(get(campaign, 'tieredBonus'));
	} else if (get(job, 'tieredBonus.id')) {
		tieredBonus = parse(get(job, 'tieredBonus'));
	}

	if (tieredBonus && recipient) {
		return getTieredBonusAmount(tieredBonus, recipient, userGroupId, hireDate);
	}

	if (tieredBonus) {
		return get(tieredBonus, 'name');
	}

	if (
		!referralBonusAmount ||
		referralBonusAmount === 0 ||
		referralBonusAmount === null
	) {
		return 0;
	}

	if (!programActive) {
		return Number.parseInt(referralBonusAmount);
	}

	if (programActive && eligible) {
		return Number.parseInt(referralBonusAmount);
	}

	if (programActive && !eligible) {
		return Number.parseInt(
			Math.max(0, referralBonusAmount - contactIncentiveBonus)
		);
	}
};

export const getBonusAmount = (job, user, options) => {
	const bonusValue = calculateBonus(job, user, options);
	let currencyCode = get(user, 'userGroup.currency');
	if (currencyCode === null) currencyCode = 'USD';
	const symbol = getSymbolFromCurrency(currencyCode);
	if (typeof bonusValue === 'string') {
		return bonusValue;
	}

	if (bonusValue > 0) {
		return `${symbol}${bonusValue}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	return 'None';
};

export const calculateReferralBonusAllReferrals = (
	contactIncentiveBonus,
	referralBonusAmount,
	eligible,
	tieredBonus,
	tieredBonusType,
	userGroupId,
	hireDate
) => {
	const programActive = contactIncentiveBonus > 0;

	if (tieredBonus) {
		return getTieredBonusAmountAllReferrals(
			tieredBonus,
			tieredBonusType,
			userGroupId,
			hireDate
		);
	}

	if (!referralBonusAmount || referralBonusAmount === 0) {
		('');
		return 0;
	}

	if (!programActive) {
		return Number.parseInt(referralBonusAmount);
	}

	if (programActive && eligible) {
		return Number.parseInt(referralBonusAmount);
	}

	if (programActive && !eligible) {
		return Number.parseInt(
			Math.max(0, referralBonusAmount - contactIncentiveBonus)
		);
	}
};

export const getTieredBonusAmount = (
	tieredBonus,
	tieredBonusType,
	userGroupId,
	hireDate
) => {
	try {
		return get(tieredBonus, 'tiers', []).reduce((total, tier) => {
			tier = typeof tier === 'string' ? parse(tier) : tier;
			const payOutDate = dayjs(hireDate).add(tier.payOutDays, 'day');
			const validDate = hireDate ? dayjs().isAfter(payOutDate) : true;
			if (
				(!tieredBonusType ||
					(tier.recipientType &&
						lowerCase(tier.recipientType) === lowerCase(tieredBonusType))) && // If hireDate is included only include eligible dates in total
				validDate &&
				get(tier, 'userGroup') === userGroupId
			)
				total += Number.parseInt(get(tier, 'amount'), 10);
			return total;
		}, 0);
	} catch (error) {
		console.log(error);
	}
};

export const getTieredBonusAmountAllReferrals = (
	tieredBonus,
	tieredBonusType,
	userGroupId,
	hireDate
) => {
	return tieredBonus.tiers.reduce((total, tier) => {
		tier = typeof tier === 'string' ? parse(tier) : tier;
		if (
			(!tieredBonusType ||
				(tier.recipientType &&
					lowerCase(tier.recipientType) === lowerCase(tieredBonusType))) && // If hireDate is included only include eligible dates in total
			get(tier, 'userGroup') === userGroupId
		)
			total += Number.parseInt(get(tier, 'amount'), 10);
		return total;
	}, 0);
};

export const calculateReferralBonusTotal = (
	referrals,
	eligible,
	incentiveBonus
) => {
	let total = 0;
	for (const referral of referrals) {
		const { job } = referral;
		const referralBonus = get(referral, 'job.referralBonus');
		const amount =
			typeof referralBonus === 'object'
				? get(referralBonus, 'amount')
				: typeof referralBonus === 'string'
					? parse(referralBonus).amount
					: 0;

		if (
			referral.status === 'hired' &&
			job &&
			amount &&
			referral.referralType !== 'self'
		) {
			const bonusAmount = calculateReferralBonus(
				incentiveBonus,
				amount,
				eligible,
				'',
				'',
				''
			);

			total += Number.parseInt(bonusAmount, 10);
		}
	}

	return total.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const calculateTotalBonuses = (bonuses, eligible) => {
	let total = 0;
	if (eligible) {
		for (const bonus of bonuses) {
			if (
				bonus.recipientType === 'employee' &&
				(bonus.bonusStatus === 'earned' || bonus.bonusStatus === 'paid')
			) {
				const amount = get(bonus, 'amountDue', 0);
				total += amount;
			}
		}
		// Values for myReferrals page
	} else {
		for (const bonus of bonuses) {
			if (bonus.recipientType === 'employee') {
				const amount = get(bonus, 'amountDue', 0);
				total += amount;
			}
		}
	}

	return total.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const calculateTotalBonusesDashboard = (bonuses, eligible) => {
	let total = 0;
	if (eligible) {
		for (const bonus of bonuses) {
			if (
				bonus.recipientType === 'employee' &&
				(bonus.bonusStatus === 'earned' ||
					bonus.bonusStatus === 'paid' ||
					bonus.bonusStatus === 'pending')
			) {
				const amount = get(bonus, 'amountDue', 0);
				total += amount;
			}
		}
		// Values for myReferrals page
	} else {
		for (const bonus of bonuses) {
			if (bonus.recipientType === 'employee') {
				const amount = get(bonus, 'amountDue', 0);
				total += amount;
			}
		}
	}

	return total.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPhoneNumber = (number) => {
	if (typeof number !== 'string') return;

	let filteredNumber = number.replaceAll(/\D/g, '');
	if (filteredNumber.length >= 11) {
		filteredNumber = '+' + filteredNumber;
	} else if (filteredNumber.length <= 10) {
		filteredNumber = '+1' + filteredNumber;
	}

	const phone = parsePhoneNumberFromString(filteredNumber);

	const numberFormat =
		get(phone, 'country', []) === 'US' ? 'NATIONAL' : 'INTERNATIONAL';

	let formattedPhoneNumber = null;
	if (phone) {
		try {
			formattedPhoneNumber = phone.format(numberFormat);
		} catch (error) {
			console.error(error);
		}
	} else {
		// Skip invalid phone numbers
	}

	return formattedPhoneNumber;
};

export const filterPhoneNumber = (number) => {
	if (typeof number !== 'string') return;

	let filteredNumber = number.replaceAll(/\D/g, '');
	if (filteredNumber.length >= 11) {
		filteredNumber = '+' + filteredNumber;
	} else if (filteredNumber.length <= 10) {
		filteredNumber = '+1' + filteredNumber;
	}

	return filteredNumber;
};

export const downloadFromS3Signed = async (key, bucket = 'erin-avatars') => {
	try {
		const configMode = getEnvironment() === 'dev' ? 'DEV' : 'PROD';
		const region = 'us-east-2';
		const credentials = {
			accessKeyId: decryptUsingAES256(
				process.env[`REACT_APP_${configMode}_S3ACCESSKEYID`]
			),
			secretAccessKey: decryptUsingAES256(
				process.env[`REACT_APP_${configMode}_S3SECRETACCESSKEY`]
			),
		};
		const s3 = new S3({ region, credentials });
		const parameters = {
			Bucket: bucket,
			Key: key,
		};
		const s3url = await getSignedUrl(s3, new GetObjectCommand(parameters), {
			expiresIn: 3 * 24 * 60 * 60,
		});
		return s3url;
	} catch (error) {
		console.log(error);
	}
};

export const downloadFromS3 = async (key, bucket = 'erin-images') => {
	let s3url = `https://${bucket}.s3.us-east-2.amazonaws.com/${key}`;
	s3url = s3url.split(' ').join('+');
	return s3url;
};

export const downloadFromS3Bucket = async (key, bucket) => {
	// START getting S3 URL
	try {
		const endpoint = 'downloaddata-prod-app';
		const result = await lambda({ endpoint, variables: { bucket } });
		const s3url = result.url;
		return s3url;
	} catch (error) {
		console.log(error);
	}
	// END getting S3 URL
};

export const uploadToS3Multipart = async (
	Body,
	Key,
	Bucket = 'erin-images'
) => {
	const configMode = getEnvironment() === 'dev' ? 'DEV' : 'PROD';
	const region = 'us-east-2';
	const credentials = {
		accessKeyId: decryptUsingAES256(
			process.env[`REACT_APP_${configMode}_S3ACCESSKEYID`]
		),
		secretAccessKey: decryptUsingAES256(
			process.env[`REACT_APP_${configMode}_S3SECRETACCESSKEY`]
		),
	};

	try {
		const parallelUploads3 = new Upload({
			client: new S3({ region, credentials }),
			params: {
				Bucket,
				Key,
				Body,
				ContentDisposition: 'inline',
			},
		});

		parallelUploads3.on('httpUploadProgress', (progress) => {
			console.log(progress);
		});

		await parallelUploads3.done();
	} catch (error) {
		throw new Error(error);
	}
};

export const uploadImageToS3FromTinyMCE = async (
	image,
	file,
	key,
	bucket = 'erin-avatars'
) => {
	return new Promise(async (resolve, reject) => {
		try {
			const configMode = getEnvironment() === 'dev' ? 'DEV' : 'PROD';
			const config = {
				region: 'us-east-2',
				credentials: {
					accessKeyId: decryptUsingAES256(
						process.env[`REACT_APP_${configMode}_S3ACCESSKEYID`]
					),
					secretAccessKey: decryptUsingAES256(
						process.env[`REACT_APP_${configMode}_S3SECRETACCESSKEY`]
					),
				},
			};
			const s3 = new S3({
				...config,
			});
			const buf = Buffer.from(
				image.replace(/^data:image\/\w+;base64,/, ''),
				'base64'
			);
			const parameters = {
				Bucket: bucket,
				Key: key,
				Body: buf,
				ContentEncoding: 'base64',
				ContentType: file.type,
			};

			const putObjectCommand = new PutObjectCommand(parameters);
			await s3.send(putObjectCommand);
			resolve({
				bucket,
				key: parameters.Key,
				region: 'us-east-2',
			});
		} catch (error) {
			reject(error);
		}
	});
};

export const ml = (query, user, languageData = [], module) => {
	try {
		if (languageData.length > 0) {
			const languageCode = get(user, 'languageCode');
			if (languageCode === 'US' || !languageCode) return query;
			let result = query;
			const matches = languageData.filter((data) => {
				const module_ = module ? get(data, 'module') === module : true;
				return (
					get(data, 'languageCode') === languageCode &&
					get(data, 'matchKey') === query &&
					module_
				);
			});
			const match = matches && matches.length > 0 ? matches[0] : null;
			if (match) {
				result = match?.text;
			} else {
				if (
					typeof languageCode === 'string' &&
					(languageCode.length === 2 ||
						(languageCode.length === 5 && languageCode.includes('-')))
				) {
					try {
						sendToMultiLingualTranslationManager(
							query,
							languageCode,
							module,
							languageData
						);
					} catch (error) {
						console.log(error);
					}

					return query;
				}

				return result;
			}

			return result;
		}

		return query;
	} catch (error) {
		console.log(error);
	}
};

const sendToMultiLingualTranslationManager = async (
	key,
	languageCode,
	module,
	languageData
) => {
	try {
		if (
			!MLRequests.find((request) => request.key === key) &&
			languageData.find((item) => item.languageCode === languageCode)
		) {
			const variables = {
				key,
				languageCode,
				module,
				path: getPath(),
			};
			MLRequests.push(variables);
			console.log('No Translation Result:', key, languageCode, module);
			const response = await lambda({
				endpoint: 'multi-lingual-translation-manager',
				variables,
			});
			if (response) {
				languageData.push(response);
				// Dynamic import to fix circular dependency
				await import('src/actions.js').then((actions) => {
					const { dashboardActions } = actions;
					dashboardActions.createSetMultiLingualData(languageData);
				});
			}

			return response?.text || query;
		}
	} catch (error) {
		console.log(error);
	}
};

const getPath = () => {
	try {
		let path = window.location.pathname;
		path = path.split('/')[1];
		return path;
	} catch {
		return null;
	}
};

export const isCurrentCampaign = (campaign, referralDate) => {
	if (!campaign || get(campaign, 'archived') === true) return false;
	const startDate = new Date(get(campaign, 'startDate')).getTime();
	const endDate = new Date(get(campaign, 'endDate')).getTime();
	const today = new Date(referralDate).getTime();
	return today - startDate >= 0 && today - endDate <= 0;
};

export const formatDate = (date, languageCode, dateFormat = null) => {
	if (typeof date === 'string') {
		try {
			const dateArray = date.split('T')[0].split('-');
			const day =
				dateArray[2].split('')[0] === '0'
					? dateArray[2].split('')[1]
					: dateArray[2];
			const month =
				dateArray[1].split('')[0] === '0'
					? dateArray[1].split('')[1]
					: dateArray[1];
			const year = dateArray[0];
			if (
					languageCode === 'FR' ||
					languageCode === 'DE' ||
					languageCode === 'ES' ||
					languageCode === 'RU' ||
					dateFormat === 'European'
			) {
				return `${day}/${month}/${year}`;
			}

			return `${month}/${day}/${year}`;
		} catch {
			return null;
		}
	} else {
		return null;
	}
};

export const translation = async (query, user) => {
	const languageCode = user?.languageCode;
	const response = await lambda(
		{
			endpoint: 'multi-lingual-translation-api',
			variables: {
				key: query,
				languageCode,
			},
		},
		'apiKey'
	);
	return response?.TranslatedText || query;
};

export const findItemByNameAndKeywords = (name, values = []) => {
	try {
		if (values !== undefined && values.length > 0) {
			values = values.map((value) => {
				if (get(value, 'keywords')) value.keywords = parse(value.keywords);
				return value;
			});
		}

		const result = values.find((value) => {
			let keywords = get(value, 'keywords', []);
			keywords ||= [];
			if (keywords.find((key) => key === name)) return true;
			if (get(value, 'name') === name) return true;
		});
		if (result) {
			return result;
		}

		return null;
	} catch {
		return null;
	}
};

export const auth = async (code, settings) => {
	const request = {
		code,
		requestType: 'saml',
	};
	const provider = get(settings, 'provider');
	if (provider) request.provider = provider;
	const auth = await lambda(
		{
			endpoint: 'auth-prod-api',
			variables: request,
		},
		'apiKey'
	);
	return parse(decryptUsingAES256(get(auth, 'token')));
};

export const logout = () => {
	window.location.assign('/logout');
};

export const lambda = async (body, auth = 'cognito') => {
	if (auth === 'cognito') {
		const federatedToken = Cache.getItem('federatedToken');
		const Authorization = get(federatedToken, 'access_token')
			? federatedToken.access_token
			: (await Auth.currentSession()).accessToken.jwtToken;
		const url =
			'https://a0youn41a1.execute-api.us-east-2.amazonaws.com/default/api';
		const response = await axios.post(url, body, {
			headers: {
				Authorization,
			},
		});
		return response?.data;
	}

	if (auth === 'apiKey') {
		const configMode = getEnvironment() === 'dev' ? 'DEV' : 'PROD';
		const Authorization =
			process.env[`REACT_APP_${configMode}_LAMBDADATACALLSKEY`];
		const url =
			'https://f096mzqyo7.execute-api.us-east-2.amazonaws.com/default/api';
		const response = await axios.post(url, body, {
			headers: {
				Authorization,
			},
		});
		return response?.data;
	}
};

export const isEmail = (emailAddress) => {
	const sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]';
	const sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]';
	const sAtom =
		'[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+';
	const sQuotedPair = '\\x5c[\\x00-\\x7f]';
	const sDomainLiteral = '\\x5b(' + sDtext + '|' + sQuotedPair + ')*\\x5d';
	const sQuotedString = '\\x22(' + sQtext + '|' + sQuotedPair + ')*\\x22';
	const sDomain_ref = sAtom;
	const sSubDomain = '(' + sDomain_ref + '|' + sDomainLiteral + ')';
	const sWord = '(' + sAtom + '|' + sQuotedString + ')';
	const sDomain = sSubDomain + '(\\x2e' + sSubDomain + ')*';
	const sLocalPart = sWord + '(\\x2e' + sWord + ')*';
	const sAddrSpec = sLocalPart + '\\x40' + sDomain; // Complete RFC822 email address spec
	const sValidEmail = '^' + sAddrSpec + '$'; // As whole string
	const reValidEmail = new RegExp(sValidEmail);
	return reValidEmail.test(emailAddress);
};

/** Allow passing referral object instead of status in order to get customStatus value. */
const parseStatus = (status) => {
	if (typeof status === 'object') {
		const customStatus = get(status, 'customStatus');
		if (customStatus && get(status, 'status') === 'interviewing') {
			// Only show custom status if the current status is interviewing
			return customStatus;
		}

		return get(status, 'status');
	}

	return status;
};

export const mapReferralStatus = (status, company) => {
	const key = parseStatus(status);
	const referralStatus = get(company, 'referralStatus');
	if (key === 'interviewing' && referralStatus) return referralStatus;
	const customStatuses = parse(get(company, 'referralCustomStatuses'));
	const customStages = parse(get(company, 'stages'));
	const statuses = { ...REFERRAL_STATUS, ...customStatuses };
	let result = statuses[key];
	const customStage = customStages
		? customStages.find((stage) => {
				if (stage[key]) return stage[key];
			})
		: null;
	if (!result && customStage) {
		result = customStage[key];
	} else if (!result && !customStage) {
		Object.entries(REFERRAL_STATUS).map((status) => {
			if (key === status[1]) result = statuses[status[0]];
		});
	}

	return result ?? key;
};

export const parseReferralStatus = (status, company) => {
	const statusValue = parseStatus(status);
	if (Object.keys(REFERRAL_STATUS).includes(statusValue)) {
		return statusValue;
	}

	// Return "interviewing" for any custom stage
	const stages = company.stages ? parse(company.stages) : [];
	for (const stage of stages) {
		if (Object.values(stage)[0] === statusValue) {
			return 'interviewing';
		}
	}

	// Return internal status that corresponds to custom status
	const customStatuses = {
		...parse(get(company, 'referralCustomStatuses')),
	};
	return (
		Object.keys(customStatuses).find(
			(key) => customStatuses[key] === statusValue
		) ?? statusValue
	);
};

export const conditionalStatusStyle = (key) => {
	if (key === 'hired') {
		return { color: 'var(--forest-green)', fontWeight: 600 };
	}

	if (key === 'notHired' || key === 'ineligible') {
		return { color: 'var(--sunset-orange)', fontWeight: 600 };
	}

	if (key === 'referred') {
		return { color: 'var(--deep-blush)', fontWeight: 600 };
	}

	if (key === 'interviewing') {
		return { color: 'var(--royal-blue)', fontWeight: 600 };
	}

	if (key === 'declined') {
		return { color: 'var(--old-mandarin)', fontWeight: 600 };
	}

	if (key === 'inactive' || key === 'noresponse') {
		return { color: 'var(--oslo-gray)', fontWeight: 600 };
	}

	if (key === 'accepted' || key === 'transferred') {
		return { color: 'var(--tan-hide)', fontWeight: 600 };
	}

	return { color: 'var(--royal-blue)', fontWeight: 600 };
};

export const sanitize = (string) => {
	const map = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		'/': '&#x2F;',
	};
	const reg = /[&<>"/]/gi;
	return string.replaceAll(reg, (match) => map[match]);
};

export const desanitize = (string) => {
	// WARNING do NOT use this for a string inside dangerouslySetInnerHTML
	// This is only to display characters in places React will render it as a string
	const map = {
		'&amp;': '&',
		'&lt;': '<',
		'&gt;': '>',
		'&quot;': '"',
		'&#x2F;': '/',
	};

	return Object.keys(map).reduce((acc, entity) => {
		const regex = new RegExp(entity, 'g');
		return acc.replace(regex, map[entity]);
	}, string);
};

export const parseJwt = (token) => {
	try {
		if (!token) {
			return;
		}

		const base64Url = token.split('.')[1]
			? token.split('.')[1]
			: token.split('=')[1];
		const base64 = base64Url
			.replaceAll('-', '+')
			.replaceAll('_', '/')
			.replaceAll('%3D', '=');

		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
		return parse(jsonPayload);
	} catch {
		return null;
	}
};

export const addDays = (date, days) => {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result.toISOString();
};

export const subtractDays = (number = 0) => {
	return dayjs().utc().subtract(number, 'days').toISOString();
};

export const getDaysBetweenDates = (from, to) => {
	if (!from || !to) return 0;

	const fromDate = new Date(from.slice(0, 10));
	const toDate = new Date(to.slice(0, 10));

	const diffTime = Math.abs(toDate - fromDate);
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	return diffDays;
};

/** Convert RGB or RGBA to HEX */
const rgba2hex = (rgba) =>
	`#${rgba
		.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)
		.slice(1)
		.map((n, i) =>
			(i === 3 ? Math.round(Number.parseFloat(n) * 255) : Number.parseFloat(n))
				.toString(16)
				.padStart(2, '0')
				.replace('NaN', '')
		)
		.join('')}`;

/** Gets default background color, typically rgba(0,0,0,0) */
const getDefaultBackgroundColor = () => {
	const div = document.createElement('div');
	document.head.append(div);
	const color = window.getComputedStyle(div).backgroundColor;
	div.remove();
	return color;
};

/** Gets computed background-color by recursively traversing the DOM until either:
 * A parent element with a background-color is found
 *
 * OR
 *
 * The default background color is returned due to no parent element having a background-color
 * */
const getInheritedBackgroundColor = (element, defaultBackgroundColor) => {
	const { backgroundColor } = window.getComputedStyle(element);

	if (backgroundColor !== defaultBackgroundColor) {
		return backgroundColor;
	}

	if (!element.parentElement) {
		return defaultBackgroundColor;
	}

	return getInheritedBackgroundColor(
		element.parentElement,
		defaultBackgroundColor
	);
};

/** Gets text color for elements with unknown background colors
 *
 * Defaults to transparent due to element not existing in dom on inital call,
 * which means that this funciton will only work correctly for pages with multiple renders.
 *
 * _Luckily_ that isn't a problem for our app 🙃
 */
export const getTextColorFromElementId = (id) => {
	try {
		const element = document.querySelector(`#${id}`);
		if (!element) return COLORS.transparent;

		const defaultBackgroundColor = getDefaultBackgroundColor();
		const computedBackgroundColor = getInheritedBackgroundColor(
			element,
			defaultBackgroundColor
		);
		const isHexColor = computedBackgroundColor.startsWith('#');
		return getTextColorFromBGColor({
			backgroundColor: isHexColor
				? computedBackgroundColor
				: rgba2hex(computedBackgroundColor),
			highContrast: false,
		});
	} catch (error) {
		console.error(error);
		return COLORS.osloGray;
	}
};

export const getTextColorFromBGColor = ({
	backgroundColor,
	threshhold = 200,
	highContrast = true,
}) => {
	// Returns hex string for text color based on background color input
	let rgbColor = backgroundColor;
	if (typeof backgroundColor === 'string') {
		const hexString = backgroundColor.replace(/^#/, '');
		const r = Number.parseInt(hexString.slice(0, 2), 16);
		const g = Number.parseInt(hexString.slice(2, 4), 16);
		const b = Number.parseInt(hexString.slice(4, 6), 16);
		rgbColor = { r, g, b };
	}

	const backgroundBrightness = Math.round(
		(rgbColor.r * 299 + rgbColor.g * 587 + rgbColor.b * 114) / 1000
	);

	if (backgroundBrightness > threshhold) {
		return highContrast ? COLORS.black : COLORS.osloGray;
	}

	return COLORS.white;
};

export const getSetErrorImageURL = async (key) => {
	const errorImageURL = localStorage.getItem('errorImageURL');

	if (errorImageURL) {
		return errorImageURL;
	}

	if (key) {
		const url = await downloadFromS3(key);
		if (url) {
			localStorage.setItem('errorImageURL', url);
			return url;
		}
	}
};

export const getSize = (size) => {
	if (get(size, 'width') <= 768) return 1;
	if (get(size, 'width') <= 940) return 1;
	if (get(size, 'width') <= 1200) return 2;
	if (get(size, 'width') <= 1600) return 3;
	return 4;
};

export const graphql = async (variables, auth = 'apiKey') => {
	try {
		const configMode = getEnvironment() === 'dev' ? 'DEV' : 'PROD';
		variables.configMode = configMode;
		const response = await lambda(
			{
				endpoint: `graphql`,
				variables,
			},
			auth
		);
		return response;
	} catch (error) {
		console.error(error);
	}
};

export const getISODateRangeUTC = (
	dates = [dayjs(), dayjs().add(1, 'day')]
) => {
	const [start, end] = dates;
	return [
		dayjs(start).utc().startOf('day').toISOString(),
		dayjs(end).utc().endOf('day').toISOString(),
	];
};

export const getUpdatedUserFromSAMLAttributes = ({
	token,
	accountClaim,
	currentUser,
	createUser,
}) => {
	const employeeId =
		accountClaim?.employeeId ||
		token?.['custom:employeeid'] ||
		token?.['custom:userid'] ||
		null;
	const jobClassId = get(token, 'custom:jobClassId');
	const jobClassName = get(token, 'custom:jobClassName');
	const jobFamilyGroupId = get(token, 'custom:jobFamilyGroupId');
	const jobFamilyGroupName = get(token, 'custom:jobFamilyGroupName');
	const jobFamilyId = get(token, 'custom:jobFamilyId');
	const jobFamilyName = get(token, 'custom:jobFamilyName');
	const jobProfileId = get(token, 'custom:jobProfileId');
	const jobProfileName = get(token, 'custom:jobProfileName');
	const managementLevel = get(token, 'custom:managementLevel');
	const customValues = parse(accountClaim?.customValues);
	let subCompanyName = accountClaim?.subCompany || customValues?.Business_Unit; // Business_Unit specific to RTX
	const subCompanies = currentUser?.company?.subCompanies || [];
	let subCompany = findItemByNameAndKeywords(subCompanyName, subCompanies);
	if (!subCompany && token?.['custom:subCompany']) {
		subCompanyName = token?.['custom:subCompany'];
		subCompany = findItemByNameAndKeywords(subCompanyName, subCompanies);
	}

	if (!subCompany && customValues?.Sector) {
		subCompanyName = customValues?.Sector;
		subCompany = findItemByNameAndKeywords(subCompanyName, subCompanies);
	}

	const subCompanyId = accountClaim?.subCompanyId
		? accountClaim.subCompanyId
		: subCompany
			? subCompany.id
			: null;

	let userGroupName =
		accountClaim?.group || customValues?.Primary_Work_Location_Country; // Primary_Work_Location_Country specific to RTX
	const userGroups = currentUser?.company?.userGroups || [];
	let userGroup = findItemByNameAndKeywords(userGroupName, userGroups);
	if (
		!userGroup &&
		(token?.['custom:userGroup'] || token?.['custom:country'])
	) {
		userGroupName = token?.['custom:userGroup'] || token?.['custom:country'];
		userGroup = findItemByNameAndKeywords(userGroupName, userGroups);
	}

	if (!userGroup && !currentUser?.userGroupId) {
		userGroupName = 'Default';
		userGroup = findItemByNameAndKeywords(userGroupName, userGroups);
	}

	const userGroupId = userGroup ? userGroup.id : null;

	let departmentName = accountClaim?.department;
	const departments = currentUser?.company?.departments || [];
	let department = findItemByNameAndKeywords(departmentName, departments);
	if (!department && customValues?.BU) {
		departmentName = customValues?.BU;
		department = findItemByNameAndKeywords(departmentName, departments);
	}

	if (!department && token?.['custom:department']) {
		departmentName = token?.['custom:department'];
		department = findItemByNameAndKeywords(departmentName, departments);
	}

	if (!department && !currentUser?.departmentId) {
		departmentName = 'Other';
		department = findItemByNameAndKeywords(departmentName, departments);
	}

	const departmentId = department ? department.id : null;

	if (accountClaim?.id) currentUser.accountClaimId = accountClaim.id;
	if (departmentId) currentUser.departmentId = departmentId;
	if (employeeId) currentUser.employeeId = employeeId;
	if (jobClassId) currentUser.jobClassId = jobClassId;
	if (jobClassName) currentUser.jobClassName = jobClassName;
	if (jobFamilyGroupId) currentUser.jobFamilyGroupId = jobFamilyGroupId;
	if (jobFamilyGroupName) currentUser.jobFamilyGroupName = jobFamilyGroupName;
	if (jobFamilyId) currentUser.jobFamilyId = jobFamilyId;
	if (jobFamilyName) currentUser.jobFamilyName = jobFamilyName;
	if (jobProfileId) currentUser.jobProfileId = jobProfileId;
	if (jobProfileName) currentUser.jobProfileName = jobProfileName;
	if (managementLevel) currentUser.managementLevel = managementLevel;
	if (subCompanyId) currentUser.subCompanyId = subCompanyId;
	if (userGroupId) currentUser.userGroupId = userGroupId;
	const identities = parse(token?.identities);
	const authMethod = lowerCase(identities?.[0]?.providerType || 'saml');
	let input = {
		authMethod,
		lastLogin: dayjs().toISOString(),
	};
	if (currentUser?.id) input.id = currentUser.id;
	if (accountClaim?.id) input.accountClaimId = accountClaim.id;
	if (departmentId) input.departmentId = departmentId;
	if (employeeId) input.employeeId = employeeId;
	if (jobClassId) input.jobClassId = jobClassId;
	if (jobClassName) input.jobClassName = jobClassName;
	if (jobFamilyGroupId) input.jobFamilyGroupId = jobFamilyGroupId;
	if (jobFamilyGroupName) input.jobFamilyGroupName = jobFamilyGroupName;
	if (jobFamilyId) input.jobFamilyId = jobFamilyId;
	if (jobFamilyName) input.jobFamilyName = jobFamilyName;
	if (jobProfileId) input.jobProfileId = jobProfileId;
	if (jobProfileName) input.jobProfileName = jobProfileName;
	if (managementLevel) input.managementLevel = managementLevel;
	if (subCompanyId) input.subCompanyId = subCompanyId;
	if (userGroupId) input.userGroupId = userGroupId;
	if (createUser) input = { ...createUser, ...input };
	return {
		updatedUser: currentUser,
		input,
	};
};
